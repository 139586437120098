import { UserPlaylistCollaboration } from '@bpm-web-app/download-api-sdk';
import { showToast, useHubSwitch } from '@bpm-web-app/utils';
import { useGetUserPlaylistCategories, useAcceptInviteToDrive, useDeclineInviteToDrive } from '@bpm-web-app/swr-hooks';
import { useCallback } from 'react';
import { UserDriveCollaboration } from '@bpm-web-app/create-api-sdk';
import { SquaredButton } from '../squared-button/squared-button';
import { useUserPlaylists } from '../three-dots-sheet/useUserPlaylists';
import styles from './collaboration.module.css';

interface CollaborationInviteProps {
    // Supreme
    invite?: UserPlaylistCollaboration;
    playlistId?: string;
    playlistTitle?: string;
    owner?: string;
    onComplete?: (newCollaborator?: UserPlaylistCollaboration) => void;

    // Create
    driveInvite?: UserDriveCollaboration;
    onCreateComplete?: (newCollaborator?: UserDriveCollaboration) => void;

    // Shared
    isCreate?: boolean;
}

export function CollaborationInvite({
    invite,
    playlistId,
    playlistTitle,
    onComplete,
    driveInvite,
    onCreateComplete,
    isCreate = false,
    owner
}: CollaborationInviteProps) {
    const { acceptInviteToPlaylist, declineInviteToPlaylist } = useUserPlaylists(Number(playlistId));
    const { isDownload } = useHubSwitch();
    const { mutate: mutateUserPlaylistCategoriesAll } = useGetUserPlaylistCategories(isDownload, true, isCreate);

    const acceptInviteToDrive = useAcceptInviteToDrive();
    const declineInviteToDrive = useDeclineInviteToDrive();

    const acceptCollaborationRequest = useCallback(async (accept: boolean) => {
        if (invite && onComplete) {
            if (accept) {
                await acceptInviteToPlaylist(invite.id).then((res) => {
                    if (res) {
                        showToast({ type: 'success', title: 'Playlist collaboration accepted.' });
                        onComplete(res.data);
                        mutateUserPlaylistCategoriesAll();
                        return res;
                    }
                    return [];
                }).catch((e) => {
                    onComplete();
                    return [];
                });
            } else {
                await declineInviteToPlaylist(invite.id).then((res) => {
                    if (res) {
                        showToast({ type: 'success', title: 'Playlist collaboration declined.' });
                        onComplete();
                        return res;
                    }
                    return [];
                }).catch((e) => {
                    onComplete();
                    return [];
                });
            }
        }
    }, [acceptInviteToPlaylist, declineInviteToPlaylist, invite, mutateUserPlaylistCategoriesAll, onComplete]);

    const acceptDriveCollaborationRequest = useCallback(async (accept: boolean) => {
        if (driveInvite && onCreateComplete) {
            if (accept) {
                await acceptInviteToDrive(driveInvite.id).then((res) => {
                    if (res) {
                        showToast({ type: 'success', title: 'Drive collaboration accepted.' });
                        onCreateComplete(res.data);
                        return res;
                    }
                    return [];
                }).catch((e) => {
                    onCreateComplete();
                    return [];
                });
            } else {
                await declineInviteToDrive(driveInvite.id).then((res) => {
                    if (res) {
                        showToast({ type: 'success', title: 'Playlist collaboration declined.' });
                        onCreateComplete();
                        return res;
                    }
                    return [];
                }).catch((e) => {
                    onCreateComplete();
                    return [];
                });
            }
        }
    }, [acceptInviteToDrive, declineInviteToDrive, driveInvite, onCreateComplete]);

    const handleAcceptInvitation = useCallback(() => {
        if (isCreate) {
            acceptDriveCollaborationRequest(true);
        } else {
            acceptCollaborationRequest(true);
        }
    }, [acceptCollaborationRequest, acceptDriveCollaborationRequest, isCreate]);

    const handleDeclineInvitation = useCallback(() => {
        if (isCreate) {
            acceptDriveCollaborationRequest(false);
        } else {
            acceptCollaborationRequest(false);
        }
    }, [acceptCollaborationRequest, acceptDriveCollaborationRequest, isCreate]);

    const sharedContent = isCreate ? 'drive' : 'playlist';

    const permissionLevel = (invite?.access_level || driveInvite?.access_level as unknown as string) === 'view' ? 'viewer' : (invite?.access_level || driveInvite?.access_level as unknown as string) === 'edit' ? 'editor' : 'manager';

    return (
        <div className={styles['collaboration-invite']}>
            <div>
                <h3 className={styles['collaboration-invite__header']}>{`You've been invited to collaborate on ‘${playlistTitle}’`}</h3>
            </div>
            <div>
                <h5 className={styles['collaboration-invite__subtitle']}>{`${owner} has invited you to collaborate on a ${sharedContent} as an ${permissionLevel}. Would you like to accept it?`}</h5>
            </div>
            <div className={styles['collaboration-invite__btn-container']}>
                <div className={styles['collaboration-invite__accept-btn']}>
                    <SquaredButton label="Accept" type="filled" onPress={handleAcceptInvitation} />
                </div>
                <div className={styles['collaboration-invite__decline-btn']}>
                    <SquaredButton label="Decline" type="outline" onPress={handleDeclineInvitation} />
                </div>
            </div>
        </div>
    );
}
