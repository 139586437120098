import classNames from 'classnames';
import { ReactElement } from 'react';
import styles from './toolbar.module.css';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface ToolbarProps {
    children: ReactElement | ReactElement[];
    scrollable?: boolean
    stickToHeader?: boolean
}

export function Toolbar({ children, scrollable, stickToHeader }: ToolbarProps) {
    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={classNames(styles['toolbar'], { [styles['toolbar--scrollable']]: scrollable, [styles['toolbar--stick-to-header']]: stickToHeader })}>{children}</div>
    );
}

export default Toolbar;
