import { HLSPlayer, isServerSide, PlayerState, State as PlayerStateEnum } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { FiltersContext } from '../filters/filters.context';
import { CreateMiniPlayer } from '../mini-player/create-mini-player';
import { NavContext } from '../nav/nav.context';
import { useCreatePlayer } from '../../../../utils/src/lib/create-player.context';

import styles from './create-player.module.css';

export * from '../../../../utils/src/lib/create-player.context';

export function CreatePlayer() {
    const { isOpen, volume, onTrackEnd, setElapsed, currentTrack } = useCreatePlayer();

    const { isNavOpen } = useContext(NavContext);
    const { isFiltersOpen } = useContext(FiltersContext);
    const [playerState, setPlayerState] = useState<PlayerStateEnum>(PlayerState.STATE);

    useEffect(() => {
        PlayerState.onPlayerStateChange(setPlayerState);
        PlayerState.onProgress(setElapsed);
        return () => {
            PlayerState.offPlayerStateChange(setPlayerState);
            PlayerState.offProgress(setElapsed);
        };
    }, [setElapsed]);

    useEffect(() => {
        if (playerState === PlayerStateEnum.Ended) onTrackEnd();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playerState]);

    useEffect(() => {
        // TODO(diogo-menezes) Should this be handled in the context
        if (!currentTrack) return;
        HLSPlayer.setVolume(volume / 100);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [volume]);

    if (isServerSide()) return null;

    return (
        <>
            {/* eslint-disable-next-line @typescript-eslint/dot-notation */}
            <div
                className={classNames(styles['create-player'], {
                    [styles['create-player--open']]: isOpen,
                    [styles['create-player--playing']]: playerState === PlayerStateEnum.Playing,
                    [styles['create-player--muted']]: !volume,
                    [styles['create-player--nav-open']]: isNavOpen,
                    [styles['create-player--filters-open']]: isFiltersOpen,
                })}
            >
                <CreateMiniPlayer />
            </div>
        </>
    );
}
