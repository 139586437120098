import { Ads } from '@bpm-web-app/api-client';
import useSWR from 'swr';

export function useAds(library: string, platform: string) {
    const { data, error, mutate } = useSWR(
        [
            'supreme-get-ad',
            {
                library,
                platform,
                persist: false
            },
        ],
        () => Ads.getCurrentAd(library, platform)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}
