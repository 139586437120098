import { useContests } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import SeeMoreLayout from '../see-more-layout/see-more-layout';

export function ContestsList() {
    const { contests } = useContests();

    const contestsData = useMemo(
        () =>
            contests?.current
                ?.map((contest) => ({
                    id: contest.id,
                    title: contest.title,
                    image_url: contest.artwork_url,
                    slug: contest.slug,
                    description: null,
                    label: null,
                }))
                .concat(
                    contests?.past?.map((contest) => ({
                        id: contest.id,
                        title: contest.title,
                        image_url: contest.artwork_url,
                        description: null,
                        slug: contest.slug,
                        label: 'expired',
                    }))
                ),
        [contests]
    );
    return <SeeMoreLayout contentType="contest" pageTitle="Contests" count={contestsData?.length || 0} itemBasePath={createAppRoutes.contest} data={contestsData ?? []} />;
}

export default ContestsList;
