import { useCallback } from 'react';
import { useGetSimilarSounds, useGetSound } from '@bpm-web-app/swr-hooks';
import { useUniqueArray, getMutatedSoundsNotPaginated } from '@bpm-web-app/utils';
import classNames from 'classnames';
import GhostElement from '../../shared/ghost-element/ghost-element';
import styles from './similar-sounds-detail.module.css';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';

const SIMILAR_LINES_WIDTH = [90, 70];

export interface SimilarSoundsDetailProps {
    soundId: string;
}

export function SimilarSoundsDetail({ soundId: id }: SimilarSoundsDetailProps) {
    const { data: sound, isLoading: isLoadingSound, error: mediaError } = useGetSound(id);
    const { data: similarSounds, isLoading: isLoadingSimilarSounds, mutate } = useGetSimilarSounds(id);
    const ghostLoadingItems = useUniqueArray(10);

    const mutateSound = useCallback(
        (soundId: string, progress: number) => {
            mutate(getMutatedSoundsNotPaginated(similarSounds, soundId, progress));
        },
        [mutate, similarSounds]
    );

    if (id === undefined || mediaError) return <PageNotFound />;

    if (isLoadingSound || isLoadingSimilarSounds) {
        return (
            <div className="ghost-loading__loading-container">
                <div className={styles['similar-sound-detail__title']}>Similar Sounds to {sound?.data?.name || ''}</div>

                <div className={styles['similar-sound-detail__loading']}>
                    {ghostLoadingItems.map((uuid) => (
                        <GhostElement isRow key={`similar-sound-detail-${uuid}`} itemClass={styles['similar-sound-detail__loading-item']} linesWidthArray={SIMILAR_LINES_WIDTH} squareWidth={56} />
                    ))}
                </div>
            </div>
        );
    }
    return (
        <>
            <Title platform="create" title="Similar Sounds" />
            <div className={classNames(styles['similar-sound-detail'])}>
                {sound?.data?.name && (
                    <div className={styles['similar-sound-detail__title']}>
                        <PrimaryPageTitle title={`Similar Sounds to ${sound?.data?.name}`} />
                    </div>
                )}
                <SecondaryPageTitle title={`Sounds (${similarSounds?.data?.length ?? 0})`} />
                <TrackListCreate mutateSound={mutateSound} list={similarSounds?.data} />
            </div>
        </>
    );
}

export default SimilarSoundsDetail;
