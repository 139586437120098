import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { Genre } from '@bpm-web-app/create-api-sdk';
import { createAppRoutes, rebuildReactTooltip } from '@bpm-web-app/utils';
import { AppLink } from '../../app-link/app-link';
import { CustomIcon } from '../../custom-icon/custom-icon';
import styles from './card-description-create.module.css';
import { CardTooltip } from '../card-tooltip/card-tooltip';

interface CardDescriptionCreateProps {
    soundsCount?: number;
    midiCount?: number;
    presetsCount?: number;
    genres?: Genre[];
    descriptionLabel?: string;
    descriptionId?: string;
    descriptionHref?: string;
}

export function CardDescriptionCreate({
    soundsCount,
    midiCount,
    presetsCount,
    descriptionLabel,
    descriptionId,
    descriptionHref,
    genres,
}: CardDescriptionCreateProps) {
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number, y: number } | undefined>();

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    const onMouseEnter = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        const tooltipHeight = 24; // you need to change this based on your tooltip
        const { left, top } = e.currentTarget.getBoundingClientRect();
        setTooltipPosition({ x: left, y: top + tooltipHeight });
    };

    return (
        <div className={styles['card-description-create']}>
            {/* Card Description Label */}
            {descriptionLabel ? (
                <div className={styles['card-description-create__description']}>
                    {/* Link (for label details) */}
                    {descriptionHref && descriptionId && descriptionLabel ? (
                        <AppLink href={descriptionHref} key={descriptionId}>
                            <div className={classNames('underline-link', styles['card-description-create__description--link'])}>{descriptionLabel}</div>
                        </AppLink>
                    ) : null}

                    {/* No Link */}
                    {!descriptionHref && !descriptionId && descriptionLabel ? (
                        <div className={styles['card-description-create__description--label']}>{descriptionLabel}</div>
                    ) : null}
                </div>
            ) : null}

            {/* Card Details */}
            {soundsCount !== undefined || midiCount || presetsCount || genres ? (
                <div className={styles['card-description-create__details']}>
                    {soundsCount !== undefined || midiCount || presetsCount ? (
                        <div
                            className={styles['card-description-create__count-container']}
                            onMouseEnter={onMouseEnter}
                            onMouseLeave={() => setTooltipPosition(undefined)}
                        >
                            {/* Icon */}
                            <CustomIcon size={12} type="sound-wave-icon" color={tooltipPosition ? 'white' : 'gray'} />
                            {/* Count */}
                            <div className={classNames(styles['card-description-create__count-container--count-label'], { [styles['card-description-create__count-container--hover']]: tooltipPosition })}>
                                {(soundsCount || 0) + (midiCount || 0) + (presetsCount || 0)}
                            </div>
                            {tooltipPosition ? <CardTooltip soundsCount={soundsCount} midiCount={midiCount} presetsCount={presetsCount} position={tooltipPosition} /> : null}
                        </div>
                    ) : null}

                    {/* Genres */}
                    {genres ? (
                        <div className={styles['card-description-create__genre-container']}>
                            {genres.map((genre) => genre?.name ? (
                                <AppLink href={createAppRoutes.genresSlug(genre.name)} key={genre.id}>
                                    <div className={styles['card-description-create__genre-label']}>{genre.name}</div>
                                </AppLink>
                            ) : null)}
                        </div>

                    ) : null}
                </div>) : null
            }
        </div>
    );
}
