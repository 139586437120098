import { useGetSoundPackagesByGenre, useTags } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useHubSwitch, useViewport, useCreateFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { ComponentProps, useContext, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import BreakpointView from '../../shared/ui/breakpoint-view/breakpoint-view';
import Filters from '../../filters/filters';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';

import styles from './genres-detail.module.css';
import Title from '../../title/title';
import { FiltersContext } from '../../filters/filters.context';
import { MusicView } from '../../music-view/music-view';
import { TagsView } from '../../tags-view/tags-view';
import { GhostComponent } from '../../shared';

export function CreateGenresDetail() {
    const router = useRouter();
    const { hub } = useHubSwitch();
    const { isMobile } = useViewport();
    const { setSubGenres } = useContext(FiltersContext);

    const [packIsLoading, setPackIsLoading] = useState<boolean>(true);
    const [soundIsLoading, setSoundIsLoading] = useState<boolean>(true);
    const [presetIsLoading, setPresetIsLoading] = useState<boolean>(true);
    const [curatedSetIsLoading, setCuratedSetIsLoading] = useState<boolean>(true);

    const [packCount, setPackCount] = useState<number>();
    const [soundCount, setSoundCount] = useState<number>(1);
    const [presetCount, setPresetCount] = useState<number>();
    const [curatedSetCount, setCuratedSetCount] = useState<number>();

    const { subgenre, slug } = router.query as { [key: string]: string | string[]; subgenre: string; slug: string };

    useEffect(() => {
        setSubGenres([subgenre]);
    }, [subgenre, setSubGenres]);

    const pageQuery = useMemo(() => {
        const queryData = {
            genre: [slug],
            subgenre: [subgenre],
        };

        if (subgenre) {
            delete queryData.genre;
        } else {
            delete queryData.subgenre;
        }

        return queryData;
    }, [slug, subgenre]);

    const query = useCreateFilterParams();

    const { data: genresData } = useGetSoundPackagesByGenre({
        genre: slug,
        limit: 0,
        skip: 0,
    });

    // Filters
    const { tags, setTags } = useContext(FiltersContext);
    const { tags: possibleTags } = useTags({ ...query, limit: undefined });
    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);

    const seeMorePath = useMemo(
        () =>
            subgenre
                ? // eslint-disable-next-line max-len
                createAppRoutes.genresSlugPacksWithSubgenre(genresData?.data?.genre?.name, subgenre, query as any)
                : createAppRoutes.genresSlugPacks(genresData?.data?.genre?.name, query as any),
        [genresData?.data?.genre?.name, query, subgenre]
    );

    const title = useMemo(() => (subgenre ? decodeURIComponent(subgenre) : genresData?.data?.genre?.name || ''), [genresData?.data?.genre?.name, subgenre]);

    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: packCount || 0,
                activeTabComponent: <MusicView title="Newest Packs" type="packs" createQuery={pageQuery} seeMorePath={seeMorePath} variant="mobile" onTotalCount={setPackCount} />

            },
            {
                id: 'sounds',
                title: 'Sounds',
                count: soundCount || 0,
                activeTabComponent: <MusicView title="Sounds" type="sounds" createQuery={{ ...pageQuery, limit: 10 }} variant="mobile" onTotalCount={setSoundCount} />,
            },
            {
                id: 'presets',
                title: 'Presets',
                count: presetCount || 0,
                activeTabComponent: <MusicView title="Synth Presets" type="presets" createQuery={{ ...pageQuery, limit: 10 }} variant="mobile" onTotalCount={setPresetCount} />,
            },
            {
                id: 'curated',
                title: 'Curated Packs',
                count: curatedSetCount || 0,
                activeTabComponent: <MusicView title="Curated Packs" type="curated" createQuery={pageQuery} variant="mobile" onTotalCount={setCuratedSetCount} />,
            },
        ],
        [curatedSetCount, packCount, pageQuery, presetCount, seeMorePath, soundCount]
    );

    const isEverythingLoaded = useMemo(() => {
        if (
            !packIsLoading &&
            !soundIsLoading &&
            !presetIsLoading &&
            !curatedSetIsLoading
        ) return true;
        return false;
    }, [curatedSetIsLoading, packIsLoading, presetIsLoading, soundIsLoading]);

    return (
        <>
            <Title platform={hub} title={genresData && genresData.data && genresData.data.genre ? genresData.data.genre.name : 'Loading Genre...'} />
            <div className={classNames(styles['genres-detail'], 'spacing--page-padding-mobile')}>
                <div className={classNames(styles['genres-detail__header'], isEverythingLoaded ? '' : !subgenre ? 'spacing--bottom' : '')}>
                    <PrimaryPageTitle title={title || slug || 'Loading...'} noPadding={isMobile} />
                    {isEverythingLoaded ? <Filters platform="create" showOnMobile hideCreateGenres /> : null}
                </div>
                {subgenre === undefined || subgenre.length === 0 ? isEverythingLoaded ? (
                    <div className={classNames('spacing--top', isMobile ? '' : 'spacing__window--horizontal')}><TagsView
                        large
                        selected={tags}
                        tags={possibleTagsString}
                        onToggleTag={(tag, on) => {
                            if (on) {
                                setTags([...tags, tag]);
                            } else {
                                setTags(tags.filter((t) => t !== tag));
                            }
                        }}
                        orderBySelected={false} />
                    </div>
                ) : !subgenre ? <div className={classNames(isMobile && !isEverythingLoaded ? styles['genres-detail__mobile-padding'] : null)}><GhostComponent type="tags" largeTags noPadding={isMobile} /></div> : null : null}

                <BreakpointView
                    mobileChildren={
                        <div className={styles['genres-detail__horizontal-tabs-container']}>
                            <HorizontalTabs tabs={mobileTabs} />
                        </div>
                    }
                    desktopChildren={
                        <>
                            <MusicView title="Newest Packs" type="packs" createQuery={pageQuery} seeMorePath={seeMorePath} variant="component" onTotalCount={setPackCount} isLoading={!isEverythingLoaded} setIsLoading={setPackIsLoading} />
                            <MusicView title="Sounds" type="sounds" createQuery={{ ...pageQuery, limit: 10 }} variant="component" onTotalCount={setSoundCount} isLoading={!isEverythingLoaded} setIsLoading={setSoundIsLoading} />
                            <MusicView title="Synth Presets" type="presets" createQuery={{ ...pageQuery, limit: 10 }} variant="component" onTotalCount={setPresetCount} isLoading={!isEverythingLoaded} setIsLoading={setPresetIsLoading} />
                            <MusicView title="Curated Packs" type="curated" createQuery={pageQuery} variant="component" onTotalCount={setCuratedSetCount} isLoading={!isEverythingLoaded} setIsLoading={setCuratedSetIsLoading} />
                        </>
                    }
                />
            </div>
        </>
    );
}

export default CreateGenresDetail;
