import { useContext, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { changeLibraryColors } from '@bpm-web-app/utils';
import { Library, LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import styles from './library-tabs.module.css';

export function LibraryTabs() {
    const { library, setLibrary, isSwitcherVisible } = useContext(LibraryTabsContext);
    const tabs = useMemo(
        () =>
            [
                {
                    id: Library.Main,
                    label: 'Open Format',
                },
                {
                    id: Library.Latin,
                    label: 'Latino',
                },

                {
                    id: Library.Artist,
                    label: 'White Label',
                },
            ].filter(Boolean),
        []
    );

    useEffect(() => {
        changeLibraryColors({ library });
    }, [library]);

    if (!isSwitcherVisible) {
        return null;
    }

    return (
        <div role="tablist" className={classNames(styles['library-tabs'], 'spacing__window--left')}>
            {tabs.map((tab) => (
                <button
                    key={tab.id}
                    className={classNames(styles['library-tabs__tab'], {
                        [styles['library-tabs__tab--active']]: library === tab.id,
                    })}
                    type="button"
                    role="tab"
                    onClick={() => setLibrary(tab.id)}
                    aria-selected={library === tab.id}
                >
                    {tab.label}
                </button>
            ))}
        </div>
    );
}

export { useLibraryTabsOptions } from './tabs';

export default LibraryTabs;
