import { useGetFavoritedSoundPackages } from '@bpm-web-app/swr-hooks/src/hooks/create/sound-package';
import { createAppRoutes } from '@bpm-web-app/utils';
import SeeMoreLayout from '../see-more-layout/see-more-layout';

export function FavoritePacks() {
    const { data: favoritesSoundPacks } = useGetFavoritedSoundPackages();

    return (
        <SeeMoreLayout
            contentType="pack"
            pageTitle="Packs"
            count={favoritesSoundPacks?.pagination?.total || 0}
            itemBasePath={createAppRoutes.packs}
            data={favoritesSoundPacks?.data}
        />
    );
}
export default FavoritePacks;
