// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @typescript-eslint/dot-notation */
import { useGetGiveaway, useGetGiveawayEntry } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, getGiveawayCookie, getSignupRedirectLink, useApiErrorHandler, useHubSwitch, useUserSettings, useViewport } from '@bpm-web-app/utils';
import dayjs from 'dayjs';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { Fragment, useMemo, useState } from 'react';
import { GiveawayEntry } from '@bpm-web-app/supreme-api-sdk';
import { Giveaway } from '@bpm-web-app/api-client';
import styles from './giveaway-detail.module.css';
import SocialShare from '../../shared/social-share/social-share';
import Contests from '../../current-contests/current-contests';
import Title from '../../title/title';
import { CustomIcon } from '../../shared/custom-icon/custom-icon';
import { SquaredButton } from '../../shared/squared-button/squared-button';
import { CustomTextField } from '../../shared/custom-text-field/custom-text-field';
import { ActionModal } from '../../shared/action-modal/action-modal';
import { BreakpointView, CardCarousel } from '../../shared';
import { PrizeCard } from '../../shared/card/prize-card/prize-card';
import { EnterGiveaway } from '../../enter-giveaway/enter-giveaway';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GiveawayDetailProps { }

export function GiveawayDetail(props: GiveawayDetailProps) {
    const router = useRouter();
    const { slug } = router.query;
    const { data, isLoading, error } = useGetGiveaway(slug as string);

    const { data: dataExistingEntry, isLoading: isLoadingExistingEntry, mutate } = useGetGiveawayEntry(data?.id || '');
    const { hub } = useHubSwitch();
    const { isMobile } = useViewport();
    const timeFormat = isMobile ? 'MMMM D, YYYY, hh:mma' : 'MM-DD-YY, hh:mma';
    const [zipCode, setZipCode] = useState<string | undefined>();
    const [displayZipCodeError, setDisplayZipCodeError] = useState<boolean | undefined>();
    const [isOpenGiveawayModal, setIsOpenGiveawayModal] = useState<boolean | undefined>();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [entry, setEntry] = useState<GiveawayEntry | undefined>(dataExistingEntry?.data);
    const errorHandler = useApiErrorHandler();
    const { isAnonymous } = useUserSettings();

    const isExpired = useMemo(() => dayjs(data?.entry_deadline).isBefore(new Date()), [data]);

    const handleSetZipCode = (text: string) => {
        setZipCode(text);
        setDisplayZipCodeError(false);
    };

    const enterGiveaway = async (ref?: string) => {
        if (data?.id && zipCode) {
            try {
                const currentEntry = await Giveaway.enterGiveaway(data?.id, { zip: zipCode, referral: ref });
                return currentEntry;
            } catch (e) {
                errorHandler({ error: e });
                return null;
            }
        }
        return null;
    };

    const onSubmit = async () => {
        if (zipCode) {
            setIsSubmitting(true);
            try {
                const entryResponse = await enterGiveaway(getGiveawayCookie());
                if (entryResponse) {
                    setEntry(entryResponse.data);
                    setIsOpenGiveawayModal(true);
                    mutate();
                }
            } catch (e) {
                errorHandler({ error: e });
            }
            setIsSubmitting(false);
        }
    };

    const onEnterGiveaway = () => {
        if (isAnonymous) {
            router.replace(`${process.env['NEXT_PUBLIC_POOL_URL']}/giveaway-signup?close=back&redirect=${encodeURIComponent(window.location.href)}`);
            return;
        }
        const isValidZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zipCode || '');
        if (zipCode && zipCode !== '' && isValidZip) {
            setDisplayZipCodeError(false);
            onSubmit();
        } else {
            setDisplayZipCodeError(true);
        }
    };

    const getAdditionalEntries = () => {
        setEntry(dataExistingEntry?.data);
        setIsOpenGiveawayModal(true);
    };

    const onCloseModal = () => {
        mutate();
        setIsOpenGiveawayModal(false);
    };

    /* TODO: implement (ghost?) loading */
    if (isLoading || isLoadingExistingEntry) return null;

    if ((!isLoading || !isLoadingExistingEntry) && error) return null;

    return (
        data && (
            <div className="spacing__window--top">
                <Title platform={hub} title={data.title} />
                <div className={styles['giveaway-detail']}>
                    {data.banner_image_url && (
                        <div className={classNames(styles['giveaway-detail__img-wrapper'], 'spacing__window--horizontal')}>
                            <picture className={styles['giveaway-detail__img']}>
                                <BreakpointView
                                    desktopChildren={

                                        <img
                                            src={appendQueryParams(data.banner_image_url, { key: 'dw', value: 1200 })}
                                            srcSet={`${appendQueryParams(data.banner_image_url, { key: 'dw', value: 2400 })} 2x`}
                                            alt={data.title}
                                        />
                                    }
                                    mobileChildren={
                                        <img
                                            src={appendQueryParams(data.mobile_banner_image_url, { key: 'dw', value: 1200 })}
                                            srcSet={`${appendQueryParams(data.mobile_banner_image_url, { key: 'dw', value: 2400 })} 2x`}
                                            alt={data.title}
                                        />

                                    } />
                            </picture>
                        </div>
                    )}
                    <div className={classNames(styles['giveaway-detail__intro'], 'spacing__window--horizontal')}>
                        <div className={styles['giveaway-detail__summary']}>
                            <h1 className={styles['giveaway-detail__title']}>{data.title}</h1>
                            <ul className={styles['giveaway-detail__details']}>
                                <li className={styles['giveaway-detail__detail']}>
                                    Submission Start Date
                                    <strong>{dayjs(data.start_date).format(timeFormat)}</strong>
                                </li>
                                <li className={styles['giveaway-detail__detail']}>
                                    Submission Deadline
                                    <strong>{dayjs(data.entry_deadline).format(timeFormat)}</strong>
                                </li>
                                <li className={styles['giveaway-detail__detail']}>
                                    Winner Announced
                                    <strong>{dayjs(data.announcement).format(timeFormat)}</strong>
                                </li>
                            </ul>
                            {entry ? (
                                <>
                                    <div className={styles['giveaway-detail__summary-ctas']}>
                                        <SquaredButton type="filled" label="Get Additional Entries" disabled={isExpired} fullWidth={isMobile} onPress={getAdditionalEntries} />
                                    </div>
                                    {dataExistingEntry && <div className={styles['giveaway-detail__disclaimer-text']}>{`You’ve earned ${dataExistingEntry?.data.score} Entries`}</div>}

                                </>
                            ) : (
                                <>
                                    <div className={classNames(styles['giveaway-detail__error-text'], { [styles['giveaway-detail__error-text--visible']]: displayZipCodeError })}>Please enter a valid US zip code.</div>
                                    <div className={styles['giveaway-detail__summary-ctas']}>
                                        <CustomTextField placeholder="Enter Zip Code" text={zipCode} setText={handleSetZipCode} hasError={displayZipCodeError || false} onEnterPress={onEnterGiveaway} />
                                        <SquaredButton type="filled" label="Enter Giveaway" disabled={isExpired} fullWidth={isMobile} onPress={onEnterGiveaway} />
                                    </div>
                                    <div className={styles['giveaway-detail__disclaimer-text']}>This giveaway is open to US residents only. Only one entry per person is permitted.</div>
                                </>
                            )}
                        </div>
                        <div className={styles['giveaway-detail__content']}>
                            <div className={styles['giveaway-detail__description']} dangerouslySetInnerHTML={{ __html: data.description }} />
                            <a href={data.terms_pdf_url} className={styles['giveaway-detail__link']}>
                                See Terms & Conditions
                            </a>
                            <div className={styles['giveaway-detail__share']}>
                                <SocialShare platforms={['facebook', 'twitter', 'linkedin', 'link', 'email']} url={dataExistingEntry?.data ? `${process.env['NEXT_PUBLIC_SUPREME_PLATFORM_URL']}d/g/${dataExistingEntry?.data.share_token}` : window.location.href} title="Share" icon={<CustomIcon type="share-arrow-icon" color="gray" />} />
                            </div>
                        </div>
                    </div>
                    {data ? (
                        <CardCarousel title="Prizes">
                            {data.GiveawayPrizes.map((prize) => (
                                <Fragment key={`prize-card--${prize.id}`}>
                                    <PrizeCard prize={prize} />
                                </Fragment>
                            ))}
                        </CardCarousel>
                    ) : null}
                    <Contests />
                </div>
                <ActionModal
                    contentClassname={styles['giveaway-detail__action-modal']}
                    headerTitle={<div className={styles['giveaway-detail__modal-title']}>Thanks for entering, now get additional entries</div>}
                    onClose={onCloseModal}
                    variant="dark"
                    hideCancel
                    isOpen={isOpenGiveawayModal}
                    actionLoading={isSubmitting}
                >
                    <EnterGiveaway giveawayId={slug as string | ''} giveawayEntry={entry} />
                </ActionModal>
            </div>
        )
    );
}

export default GiveawayDetail;
