import { useCallback } from 'react';
import { useGetFavoritedSounds } from '@bpm-web-app/swr-hooks';
import { getMutatedSoundsNotPaginated } from '@bpm-web-app/utils';
import SecondaryPageTitle from '../shared/secondary-page-title/secondary-page-title';
import TrackListCreate from '../shared/track-list/create/track-list-create';
import styles from './favorite-sounds.module.css';

export function FavoriteSounds() {
    const { data: soundsData, mutate } = useGetFavoritedSounds();

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSoundsNotPaginated(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    return (
        <div className={styles['favorites-sounds']}>
            <SecondaryPageTitle title="Sounds" counter={soundsData?.pagination?.total} />
            <TrackListCreate mutateSound={mutateSound} list={soundsData?.data} />
        </div>
    );
}
export default FavoriteSounds;
