import useSWR from 'swr';
import { News } from '@bpm-web-app/api-client';

export function useNews(query: Parameters<typeof News.getNewsForProvider>[0]) {
    const { data, error } = useSWR(
        [
            'supreme-get-news',
            {
                persist: true,
                ...query,
            },
        ],
        () => News.getNewsForProvider(query)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useNewsTags(providerId: string) {
    const { data, error } = useSWR(['supreme-get-news-tags', providerId], () => News.getNewsTagsForProvider(providerId));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
