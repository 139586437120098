import { FormEvent, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import styles from './comment.module.css';
import useGiveaway from '../shared/three-dots-sheet/useGiveaway';
import { useSession } from '../shared/session-provider/session-provider';
import useContest from '../shared/three-dots-sheet/useContest';

export interface CommentProps {
    giveawayId?: string;
    contestId?: string;
    id: string;
    user: {
        id: number;
        username: string;
        full_name: string;
        profile_image_thumbnail_url?: string;
    };
    created_at: string;
    comment: string;
}

export function Comment({ giveawayId, contestId, id, user, created_at, comment }: CommentProps) {
    const { editGiveawayComent } = useGiveaway(giveawayId, giveawayId === undefined);
    const { editContestComent } = useContest(contestId, contestId === undefined);

    const { user: loggedUser } = useSession();

    const [isEditing, setIsEditing] = useState(false);
    const onSubmit = (e: FormEvent) => {
        e.preventDefault();
        setIsEditing(false);
    };

    const canEdit = useMemo(() => user.id === loggedUser?.id, [loggedUser?.id, user.id]);

    const [editedCommentText, setEditedCommentText] = useState(comment);

    const updateComment = useCallback(() => {
        setIsEditing(false);
        if (contestId === undefined) {
            editGiveawayComent(id, editedCommentText);
            return;
        }
        editContestComent(id, editedCommentText);
    }, [contestId, editContestComent, editGiveawayComent, editedCommentText, id]);

    const cancelEditing = useCallback(() => {
        setIsEditing(false);
        setEditedCommentText(comment);
    }, [comment]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={styles['comment']}>
            <div className={styles['comment__avatar-wrapper']}>
                {user.profile_image_thumbnail_url && <img className={styles['comment__avatar']} src={user.profile_image_thumbnail_url} alt={user.full_name} />}
            </div>
            {!isEditing && canEdit ? (
                <button type="button" onClick={() => setIsEditing(true)} className={styles['comment__edit']}>
                    <i className="icon icon--controls-edit" />
                    Edit
                </button>
            ) : null}
            <div className={styles['comment__author']}>
                <div className={styles['comment__author-name']}>{user.full_name}</div>
                <div className={styles['comment__author-date']}>{dayjs(created_at).format('MMMM D, YYYY')}</div>
            </div>
            <div className={styles['comment__content']}>
                {isEditing ? (
                    <form onSubmit={onSubmit} className={styles['comment__content-form']}>
                        <textarea className={styles['comment__content-input']} onChange={(e) => setEditedCommentText(e.target.value)} value={editedCommentText} />
                        <button className={classNames(styles['comment__content-submit'], styles['comment__content-submit--primary'])} type="submit" onClick={updateComment}>
                            Save
                        </button>
                        <button className={classNames(styles['comment__content-submit'], styles['comment__content-submit--secondary'])} onClick={cancelEditing} type="button">
                            Cancel
                        </button>
                    </form>
                ) : (
                    <div className={styles['comment__content-comment']}>{comment}</div>
                )}
            </div>
        </div>
    );
}

export default Comment;
