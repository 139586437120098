import { SubscriptionDetailsv4, SubscriptionPackage } from '@bpm-web-app/supreme-api-sdk';
import { useApplyPromotionSubscription, useCancelSubscription, useGetMeSubscription, useGetRetentionIncentivesSubscription, useGetSubscriptionPackage, usePauseSubscription } from '@bpm-web-app/swr-hooks';
import { getCurrentPlatformLink, showExitSurvey, showToast, useApiErrorHandler } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useCallback, useState } from 'react';
import { Button } from '../components/button/button';
import styles from './cancel-plan.module.css';
import { ConfirmCancelModal } from './confirm-cancel-modal/confirm-cancel-modal';
import { DiscountModal } from './discount-modal/discount-modal';
import { useCountdown } from './hooks/useCountdown';
import { OptionCard, OptionCardLabel, OptionCardListItem } from './option-card/option-card';
import { PauseModal } from './pause-modal/pause-modal';

interface CancelPlanOptionsProps {
    platform: SubscriptionPackage.PlatformEnum
    packageId: string
}

const getDiscountedPrice = (price: number) => {
    const discountedPrice = Math.round((price * 100) * 0.6) / 100;
    return discountedPrice;
};

export function CancelPlanOptions({ platform, packageId }: CancelPlanOptionsProps) {
    const router = useRouter();
    const [isPauseModalOpen, setPauseModalOpen] = useState(false);
    const [isDiscountModalOpen, setDiscountModalOpen] = useState(false);
    const [isConfirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
    const errorHandler = useApiErrorHandler(platform === SubscriptionPackage.PlatformEnum.Create);
    const { data: subscriptionData, isLoading: subscriptionLoading } = useGetMeSubscription(platform as unknown as string);
    const { data: retentionIncentivesData, isLoading: retentionIncentivesLoading, } = useGetRetentionIncentivesSubscription(platform as unknown as SubscriptionPackage.PlatformEnum);

    const { data: currentPackageData, } = useGetSubscriptionPackage(+packageId);

    const { pauseSubscription, isLoading: isPauseLoading } = usePauseSubscription({
        onSuccess: () => {
            setPauseModalOpen(false);
            showToast({ type: 'success', title: 'The plan was successfully paused.' });
            router.replace(getCurrentPlatformLink('/account/overview'));
        },
        onError: (pauseError: any) => {
            setPauseModalOpen(false);
            errorHandler({ error: pauseError });
        },
    });

    const { applyPromotionSubscription, isLoading: isApplyPromotionLoading } = useApplyPromotionSubscription({
        onSuccess: () => {
            setDiscountModalOpen(false);
            showToast({ type: 'success', title: 'The promotion was successfully applied.' });
            router.replace(getCurrentPlatformLink('/account/overview'));
        },
        onError: (applyError: any) => {
            setDiscountModalOpen(false);
            errorHandler({ error: applyError });
        }
    });

    const cancelSubscription = useCancelSubscription();
    const [isCancelling, setIsCancelling] = useState(false);

    const subscriptionPaused = subscriptionData?.data.membership.is_paused || subscriptionData?.data.membership.paused_until !== null;
    const subscriptionCancelled =
        subscriptionData?.data.membership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled && !subscriptionData?.data.membership?.subscription.next_cycle_package_change;

    const onPressPauseSubscription = useCallback(() => {
        setPauseModalOpen(true);
    }, []);

    const onConfirmCancel = useCallback(async () => {
        setIsCancelling(true);
        try {
            await cancelSubscription(platform);
            setConfirmCancelModalOpen(false);
            setDiscountModalOpen(false);
            router.replace(getCurrentPlatformLink('/account/overview'));
            showExitSurvey(platform === SubscriptionPackage.PlatformEnum.Supreme ? 'supreme' : 'create');
        } catch (cancelError: any) {
            errorHandler({ error: cancelError });
        }
        setIsCancelling(false);
    }, [platform, cancelSubscription, errorHandler, router]);

    const onPressCancelSubscription = useCallback(async () => {
        if (retentionIncentivesData?.data.promotion) {
            setDiscountModalOpen(true);
        } else {
            setConfirmCancelModalOpen(true);
        }
    }, [setConfirmCancelModalOpen, retentionIncentivesData?.data.promotion]);

    const onAcceptDiscountOffer = useCallback(async () => {
        await applyPromotionSubscription(platform);
    }, [applyPromotionSubscription, platform]);

    const onConfirmPause = useCallback(async (duration: string) => {
        await pauseSubscription(platform, { months: Number(duration) });
    }, [pauseSubscription, platform]);

    const timeLeft = useCountdown({ minutes: 5 });

    const isAnnualPackage = currentPackageData?.data.period === 12;

    if (subscriptionLoading || retentionIncentivesLoading || !currentPackageData) {
        return null;
    }

    return (
        <>
            <div className={styles['cancel-plan__content']}>
                <OptionCard
                    variant="light"
                    heading="Pause Subscription"
                    count={1}
                    content={
                        <>
                            <OptionCardListItem variant="positive" label="Pause your subscription without being charged, unpause at any time." />
                            <OptionCardListItem variant="positive" label="Your pause will kick in once your current billing cycle ends." />
                            <OptionCardListItem variant="positive" label="You will be notified 7 days prior to your pause ending." />
                        </>
                    }
                    button={
                        <Button
                            disabled={subscriptionPaused || subscriptionCancelled || isAnnualPackage}
                            className={styles['cancel-plan__button']}
                            type="button"
                            as="button"
                            variant="primary"
                            onClick={onPressPauseSubscription}>
                            {subscriptionPaused ? 'Your Subscription is paused' : isAnnualPackage ? 'Unavailable' : 'Pause Subscription '}
                        </Button>
                    }
                />
                <OptionCard
                    variant="dark"
                    heading="Cancel Subscription"
                    count={2}
                    content={
                        <div className={styles['cancel-plan__cancel-option-content']}>
                            <OptionCardLabel>When your billing cycle ends:</OptionCardLabel>
                            <div>
                                <OptionCardListItem variant="negative" label="You’ll no longer be able to download tracks from our libraries." />
                                <OptionCardListItem variant="negative" label="Collaboration will end on any collaborative playlists you own." />
                                <OptionCardListItem variant="negative" label="You’ll lose access to paid features like exclusive track versions, exclusives edits, playlists, and our industry-leading remix discovery tools." />
                            </div>
                        </div>
                    }
                    button={
                        <Button disabled={subscriptionCancelled} className={classNames(styles['cancel-plan__button'], styles['cancel-plan__button--destructive'])} type="button" as="button" variant="destructive" onClick={onPressCancelSubscription}>
                            {subscriptionCancelled ? 'Your Subscription is cancelled' : 'Cancel Subscription'}
                        </Button>
                    }
                />

            </div>
            <PauseModal
                isLoading={isPauseLoading}
                isOpen={isPauseModalOpen}
                onClose={() => setPauseModalOpen(false)}
                onConfirm={onConfirmPause}
                billingCycleEnd={subscriptionData?.data.membership.subscription?.next_billing}
            />
            <DiscountModal
                isAcceptLoading={isApplyPromotionLoading}
                isCancelLoading={isCancelling}
                isOpen={isDiscountModalOpen}
                onClose={() => setDiscountModalOpen(false)}
                onAcceptOffer={onAcceptDiscountOffer}
                onConfirmCancel={onConfirmCancel}
                price={getDiscountedPrice(Number(currentPackageData?.data.amount))}
                timeLeft={timeLeft}
            />

            <ConfirmCancelModal
                isCreate={platform === SubscriptionPackage.PlatformEnum.Create}
                isCancelLoading={isCancelling}
                onConfirm={onConfirmCancel}
                onClose={() => {
                    setConfirmCancelModalOpen(false);
                }}
                isOpen={isConfirmCancelModalOpen}
            />
        </>

    );
}
