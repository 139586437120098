import { useNews } from '@bpm-web-app/swr-hooks';
import { useEffect, useMemo } from 'react';
import { CardCarousel } from '../card-carousel/card-carousel';
import { GhostComponent } from '../ghost-component/ghost-component';
import { NewsCard } from '../card/news-card.tsx/news-card';

export interface NewsCarouselProps {
    newsProviderId: string;
    isCreate: boolean;
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function NewsCarousel({ newsProviderId, isCreate = false, isDataLoaded, setIsDataLoaded }: NewsCarouselProps) {
    const { data, isLoading } = useNews({ providerId: newsProviderId });
    const news = data?.data.slice(0, 10);

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false && news) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, news, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    if (shouldDisplayGhostLoading) {
        return (<GhostComponent type="cards" title="BPM News" elementsCount={8} lineHeight={8} contentType="news" linesWidthArray={[100]} cardSize="large" />);
    }

    if (!newsProviderId) return <div />;

    return (
        <CardCarousel title="BPM News" seeMorePath="/news">
            {news ? (
                news.map((card) => (
                    <NewsCard
                        key={card.id}
                        news={card}
                        newsList={news}
                        isCreate={isCreate} />
                ))
            ) : null}
        </CardCarousel>
    );
}

export default NewsCarousel;
