import { Contest } from '@bpm-web-app/api-client';
import { useInfiniteContestComments, useInfiniteContestSubmissions } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, showToast } from '@bpm-web-app/utils';

export const useContest = (contestId: string, disabled: boolean) => {
    const { data: comments, mutate: mutateComments } = useInfiniteContestComments({ id: contestId, limit: 10 }, disabled);

    const { data: submissions, mutate: mutateSubmissions } = useInfiniteContestSubmissions({ id: contestId, limit: 10 }, disabled);

    const errorHandler = useApiErrorHandler();

    const contestReportDownloadStems = async () => {
        try {
            await Contest.contestReportDownloadStems(contestId);
            showToast({ type: 'success', title: 'Success' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const editContestSubmission = async (submissionId: string, title: string, description: string) => {
        try {
            const submission = await Contest.editContestSubmission(submissionId, { title, description });

            const newSubmissions = submissions.map((submissionPage) => {
                const page = { ...submissionPage };
                page.data = page.data.map((submissionObj) => (submissionObj.id === submission.data.id ? submission.data : submissionObj));
                return page;
            });

            mutateSubmissions(newSubmissions);
            showToast({ type: 'success', title: 'Success' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const likeContestSubmission = async (submissionId: string) => {
        try {
            await Contest.likeContestSubmission(submissionId);

            const newSubmissions = submissions.map((submissionPage) => {
                const page = { ...submissionPage };
                page.data = page.data.map((submissionObj) => (submissionObj.id === submissionId ? { ...submissionObj, liked: true } : submissionObj));
                return page;
            });

            mutateSubmissions(newSubmissions);
            showToast({ type: 'success', title: 'Success' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const unlikeContestSubmission = async (submissionId: string) => {
        try {
            await Contest.unlikeContestSubmission(submissionId);

            const newSubmissions = submissions.map((submissionPage) => {
                const page = { ...submissionPage };
                page.data.map((submissionObj) => (submissionObj.id === submissionId ? { ...submissionObj, liked: false } : submissionObj));
                return page;
            });

            mutateSubmissions(newSubmissions);
            showToast({ type: 'success', title: 'Success' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const postContestComent = async (commentText: string, setComment: (value: string) => void) => {
        try {
            const comment = await Contest.postContestComent(contestId, { comment: commentText });
            showToast({ type: 'success', title: 'Success' });
            setComment('');

            const newComments = comments.map((commentPage, index) => {
                if (index === 0) {
                    const page = { ...commentPage };
                    page.data = [comment.data, ...commentPage.data];
                    return page;
                }
                return commentPage;
            });

            mutateComments(newComments);
        } catch (error) {
            errorHandler({ error });
        }
    };

    const editContestComent = async (commentId: string, commentText: string) => {
        try {
            const comment = await Contest.editContestComent(commentId, { comment: commentText });

            const newComments = comments.map((commentPage) => {
                const page = { ...commentPage };
                page.data = page.data.map((commentObj) => (commentObj.id === comment.data.id ? comment.data : commentObj));
                return page;
            });

            mutateComments(newComments);
            showToast({ type: 'success', title: 'Success' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    return {
        postContestComent,
        editContestComent,
        contestReportDownloadStems,
        editContestSubmission,
        likeContestSubmission,
        unlikeContestSubmission,
    };
};

export default useContest;
