import useSWR from 'swr';
import { DownloadAlbum, Media } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export type StreamSearchSuggestResponse = Awaited<ReturnType<typeof Media.searchSuggestMediaArtist>>;
export type DownloadSearchSuggestResponse = Awaited<ReturnType<typeof DownloadAlbum.getSuggestedAlbumsForTerm>>;
export type GetSearchSuggestedResponse = StreamSearchSuggestResponse | DownloadSearchSuggestResponse | null;

export function useSearchSuggest(query: Parameters<typeof Media.searchSuggestMediaArtist>[0], disabled?: boolean) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<GetSearchSuggestedResponse>(
        [
            'search-suggest',
            {
                persist: false,
                ...query,
                isDownload,
            },
        ],
        () => (disabled ? null : isDownload ? DownloadAlbum.getSuggestedAlbumsForTerm(query) : Media.searchSuggestMediaArtist(query))
    );
    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
