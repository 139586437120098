import { Subscription, User } from '@bpm-web-app/api-client';
import { ChangeSubscriptionRequest, ChangeSubscriptionRequestStripe, GetMeWithSubscription, } from '@bpm-web-app/supreme-api-sdk';
import { useCurrentUserAddress, useGetPaymentInstrument } from '@bpm-web-app/swr-hooks';
import { getCurrentPlatformLink, getSignupRedirectLinkAccountPlans, showToast, useApiErrorHandler, useUserSettings } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { PaymentData } from '../types';
import { StripeForm } from './stripe-form';

const getPackageId =
    (subscriptionData: any | undefined,) => {
        return subscriptionData?.data?.membership?.subscription?.package_id;
    };

interface PaymentMethodProps {
    userSubscription?: GetMeWithSubscription;
    platform: 'supreme' | 'stream' | 'create';
    afterSubmit?: (id: string) => void
}

export function PaymentMethodsForm({ platform, afterSubmit, userSubscription }: PaymentMethodProps) {
    const isCreate = platform === 'create';

    const { isLoading: isLoadingPaymentDownload, mutate: mutateUserPayment } = useGetPaymentInstrument(platform, true);
    const { data: userAddress, isLoading: isLoadingUserAddress, mutate: mutateUserSubscriptionAddress } = useCurrentUserAddress();

    const errorHandler = useApiErrorHandler(isCreate);
    const { isAnonymous } = useUserSettings();

    const router = useRouter();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getSignupRedirectLinkAccountPlans());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const handleErrorOnSubmit = useCallback(async (error: any) => {
        errorHandler({ error });
        if (isCreate) {
            mutateUserPayment(null, { revalidate: true });
            return;
        }
        mutateUserPayment(null, { revalidate: true });
    }, [errorHandler, isCreate, mutateUserPayment]);

    const handleSubmit = useCallback(async (formData: PaymentData) => {
        const body: ChangeSubscriptionRequest = {
            payment_data: null as any,
        };

        if (formData.payment?.stripe?.payment_method_id) {
            body.payment_data = {
                method: ChangeSubscriptionRequestStripe.MethodEnum.Stripe,
                payment_method_id: formData.payment.stripe.payment_method_id,
            };
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const response = await Subscription.changeSubscription(getPackageId(userSubscription)!, body);

            if ((response?.data as any)?.type === 'paypal') {
                router.push((response?.data as any)?.url);
            } else {
                mutateUserSubscriptionAddress();
                mutateUserPayment();
            }
            showToast({ type: 'success', message: 'Payment method updated successfully.' });
            router.push(getCurrentPlatformLink('/account/overview'));
        } catch (error: any) {
            await handleErrorOnSubmit(error);
        }
    }, [mutateUserPayment, mutateUserSubscriptionAddress, router, userSubscription, handleErrorOnSubmit]);

    if (isLoadingPaymentDownload || isLoadingUserAddress) {
        return null;
    }

    return (
        <StripeForm
            isCreate={isCreate}
            afterSubmit={afterSubmit}
            billingAddress={userAddress?.data}
            setPaymentData={(data) => {
                return handleSubmit({
                    payment: {
                        stripe: {
                            payment_method_id: data.id
                        }
                    }
                });
            }}
            updateBillingAddress={async (address) => {
                await User.setMyCurrentAddress(address);
            }}
        />
    );
}
