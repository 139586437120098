import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useTrending } from '@bpm-web-app/swr-hooks';
import { useHubSwitch, QueueItem, downloadAlbumWithMediaToQueueItem, streamMediaWithAlbumToQueueItem, DEFAULT_PERIOD, DEFAULT_BROWSE_TRENDING_LIMIT } from '@bpm-web-app/utils';
import { useMemo, useCallback, useEffect } from 'react';
import { Trending } from '@bpm-web-app/components';
import { usePlayer } from '../player-context';
import { ITrendingItem } from '../trending/trending-item';
import { MusicViewProps } from './music-view-props';
import { GhostComponent, NoResultsBlock, SecondaryPageTitle } from '../shared';

export function TrendingMusicView(trendingMusicProps: MusicViewProps) {
    const { query, title, isLoading, setIsLoading } = trendingMusicProps;
    const { isDownload } = useHubSwitch();

    const { data: trendingData, isLoading: isLoadingTrendingData } = useTrending(isDownload ? 'download' : 'stream', {
        period: DEFAULT_PERIOD,
        limit: DEFAULT_BROWSE_TRENDING_LIMIT,
        category: query?.category as string || undefined,
        library: query?.library,
        genre: query?.genre as string || undefined,
    });

    const trending = useMemo(
        () =>
            trendingData?.data.length
                ? trendingData.data.map((item) => {
                    return {
                        artist: item.artist,
                        cover_url: isDownload ? (item as DownloadAlbum).cover_url : (item as MediaWithAlbum).album?.cover_url,
                        trending_status: (item as DownloadAlbum | MediaWithAlbum).trending_status_weekly,
                        id: item.id,
                        title: item.name,
                        artists: (item as DownloadAlbum | MediaWithAlbum).artists,
                        media_id: isDownload ? (item as DownloadAlbum).media[0].id : (item as MediaWithAlbum).id,
                        tracking_id: isDownload ? (item as DownloadAlbum).id : (item as MediaWithAlbum).album?.id
                    } as ITrendingItem;
                }) : [],
        [trendingData?.data, isDownload]
    );

    const { setQueue } = usePlayer();

    const handlePlayTrack = useCallback(
        (index: number) => {
            let queueItems: QueueItem[];
            if (isDownload && trendingData?.data) {
                queueItems = (trendingData.data as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album));
            } else {
                queueItems = (trendingData.data as MediaWithAlbum[]).map((media) => streamMediaWithAlbumToQueueItem(media));
            }

            setQueue(queueItems, index);
        },
        [trendingData?.data, isDownload, setQueue]
    );

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingTrendingData);
        }
    }, [isLoadingTrendingData, setIsLoading]);

    const isLoadingAnything = useMemo(() => {
        return isLoading || isLoadingTrendingData;
    }, [isLoading, isLoadingTrendingData]);

    if (isLoadingAnything) {
        return (<GhostComponent type="trending" title={title} />);
    }

    return (
        <>
            <SecondaryPageTitle title={title as string} />
            <div className="spacing--top" />
            {trending.length > 0
                ? (
                    <Trending
                        trending={trending}
                        isLoading={isLoadingTrendingData}
                        onPlayTrack={handlePlayTrack}
                        platform="supreme"
                        noTitle
                    />
                )
                : <NoResultsBlock hasMargin>{`No Trending ${title} Available`}</NoResultsBlock>}
        </>

    );
}
