import { useSearchArtist } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { useSupremeFilterParams } from '@bpm-web-app/utils';
import { useEffect, useMemo } from 'react';
import styles from './search-artist-carousel.module.css';
import CardCarousel from '../../shared/card-carousel/card-carousel';
import ArtistCardImage from '../../shared/artist-card-image/artist-card-image';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { GhostComponent } from '../../shared';

interface SearchArtistCarouselProps {
    onCount: (count: number) => void;
    hasNoResults?: boolean;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchArtistCarousel({ onCount, hasNoResults, isLoadingData, setIsLoadingData }: SearchArtistCarouselProps) {
    const router = useRouter();
    const query = useSupremeFilterParams(false);
    const { data: searchArtist, isLoading } = useSearchArtist(query);

    const seeMorePath = useMemo(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        () => `/search?searchTerm=${router.query?.['searchTerm']}&type=artists`,
        [router.query]
    );

    useEffect(() => {
        onCount(searchArtist?.length || 0);
    }, [onCount, searchArtist?.length]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoading);
    }, [isLoading, setIsLoadingData]);

    if (hasNoResults && !isLoadingData) {
        return null;
    }

    return (
        <div className={styles['search-artists-carousel']}>
            {isLoadingData && <GhostComponent type="artist-cards" title="Artists" elementsCount={8} cardSize="small" linesWidthArray={[70, 30]} />}
            {!isLoadingData &&
                (searchArtist && searchArtist.length > 0 ? (
                    <CardCarousel title="Artists" titleCounter={`${searchArtist?.length}`} seeMorePath={seeMorePath}>
                        {searchArtist.map((artist) => (
                            <ArtistCardImage
                                key={artist.id}
                                artist={artist}
                            />
                        ))}
                    </CardCarousel>
                ) : (
                    <div className={styles['search-artists-carousel__no-results-section']}>
                        <SecondaryPageTitle title="Artists" counter="0" />
                        <NoResultsBlock hasPadding hasMargin>No Artists Available</NoResultsBlock>
                    </div>
                ))}
        </div>
    );
}

export default SearchArtistCarousel;
