import { CreateSearchPaginatedQuery } from '@bpm-web-app/api-client';
import { SortByQueryOptions } from '@bpm-web-app/create-api-sdk';
import { useInfiniteSearchPresets } from '@bpm-web-app/swr-hooks';
import { useViewport, getMutatedPresets, useCreateFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { AppLink } from '../../shared/app-link/app-link';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';

import styles from './search-presets.module.css';
import TrackListCreatePresets from '../../shared/track-list/create-presets/track-list-create-presets';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';

const SEARCH_PAGE_SOUNDS_LIMIT = 5;

export interface SearchPresetsProps {
    hideShowMore?: boolean;
    mainGenre?: string;
    onPresetsCount: (count: number) => void;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchPresets({ hideShowMore = false, mainGenre, onPresetsCount, isLoadingData, setIsLoadingData }: SearchPresetsProps) {
    const router = useRouter();
    const { isMobile } = useViewport();

    const { sortBy } = router.query;

    const overrides = useMemo(() => {
        const o: Partial<CreateSearchPaginatedQuery> = sortBy ? {
            sort_by: `${sortBy}` as unknown as SortByQueryOptions
        } : {};
        if (hideShowMore) {
            o.limit = SEARCH_PAGE_SOUNDS_LIMIT;
        }
        if (mainGenre) {
            o.genre = mainGenre;
        }
        return o;
    }, [hideShowMore, mainGenre, sortBy]);

    const query = useCreateFilterParams(overrides);

    const { data = [], isLoadingInitialData, isLoadingMore, setSize, isLastPage, mutate } = useInfiniteSearchPresets(query);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedPresets(data, id, progress));
        },
        [mutate, data]
    );

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => {
                return prevSize + 1;
            });
        }
    }, [isLastPage, isLoadingMore, setSize]);

    const songs = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flatMediaList = songs ? [].concat(...(songs as any)) : [];

    /* reset the infinite scroll back to first page
  if navigated between a non-index and index page. */
    useEffect(() => {
        setSize(1);
    }, [hideShowMore, setSize]);

    useEffect(() => {
        if (data?.[0]?.pagination?.total !== undefined) {
            onPresetsCount(data?.[0]?.pagination?.total);
        }
    }, [data, onPresetsCount]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoadingInitialData);
    }, [isLoadingInitialData, setIsLoadingData]);

    if (isLoadingData || isLoadingInitialData) {
        return (
            <GhostComponent type="track-list" title="Synth Presets" isCreate />
        );
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['search-presets']}>
            {(!hideShowMore || !isMobile || (hideShowMore && isMobile && flatMediaList?.length === 0)) && (
                <div className={classNames(styles['search-presets__intro-section'], 'spacing--gap', 'spacing--bottom')}>
                    <SecondaryPageTitle title="Synth Presets" counter={data?.[0]?.pagination?.total} />
                    {flatMediaList?.length === 0 && <NoResultsBlock hasPadding hasMargin>No Presets Available</NoResultsBlock>}
                </div>
            )}
            {flatMediaList?.length > 0 ? (
                <div className="spacing__window--horizontal">
                    <TrackListCreatePresets
                        automatedLoadMore
                        mutateSound={mutateSound}
                        list={flatMediaList}
                        onLoadMore={hideShowMore ? handleLoadMore : undefined}
                        isLoadingMore={isLoadingMore}
                        hideHeading={isMobile} />
                    {!hideShowMore && (
                        <AppLink href={`${router.asPath}&type=presets`}>
                            <div className={styles['search-presets__see-more']}><SeeMore expand={false} variant="text" /></div>
                        </AppLink>
                    )}
                </div>
            ) : null}
        </div>
    );
}

export default SearchPresets;
