import { Analytics, appendQueryParams, createAppRoutes, curatedPackToCreatePlayable, fileDownload, showToast, useApiErrorHandler, useDownloadPreview } from '@bpm-web-app/utils';
import { CuratedSet } from '@bpm-web-app/create-api-sdk';
import { useCreateLike, useDownloadCuratedSet, useDownloadCuratedSetPreview, useGetCredits } from '@bpm-web-app/swr-hooks';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useCreatePlayer } from '../../../create-player/create-player';
import { CardCreate } from '../card-create';
import { CardDescriptionCreate } from '../card-description-create/card-description-create';

export interface CuratedSetCardCreateProps {
    item: CuratedSet;
    items: CuratedSet[];
}

export function CuratedSetCardCreate({
    item,
    items,
}: CuratedSetCardCreateProps) {
    const { playDemo } = useCreatePlayer();
    const { isLiked, likeDislike } = useCreateLike('curated');
    const downloadCuratedSet = useDownloadCuratedSet();
    const downloadCuratedSetPreview = useDownloadCuratedSetPreview();

    const router = useRouter();
    const { mutate: refreshCredits } = useGetCredits();
    const errorHandler = useApiErrorHandler();

    const {
        closeDownloadModal,
        setShowDownloadPreviewModal,
    } = useDownloadPreview();

    const handleDownloadUrl = useCallback(
        (url: string) => {
            fileDownload(url);
            refreshCredits();
        },
        [refreshCredits]
    );

    const handleDownloadPack = useCallback(async () => {
        closeDownloadModal();
        try {
            const response = await downloadCuratedSet(item.id, router.asPath);
            if (response && response.data && response.data.url) handleDownloadUrl(response.data.url);
            if (item.id) {
                Analytics.trackClick('download_curated', item.id, { location: 'curated-set-card' });
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [closeDownloadModal, downloadCuratedSet, errorHandler, handleDownloadUrl, item.id, router.asPath]);

    const onDownload = useCallback(async () => {
        await showToast({
            promise: handleDownloadPack(),
            message: 'Download in progress.',
            successText: 'Download successful.',
            preventErrorToast: true
        });
    }, [handleDownloadPack]);

    const handleDownloadPreview = useCallback(async () => {
        try {
            const response = await downloadCuratedSetPreview(item.id);
            const creditsRequired = response?.data?.required?.total;
            let subtitle = '';

            if (creditsRequired && creditsRequired > 0) {
                if (item.sound_count && item.sound_count === 1) subtitle = 'this file';
                else if (item.sound_count && item.sound_count > 1) subtitle = 'these files';
                setShowDownloadPreviewModal({
                    subtitle,
                    amount: creditsRequired,
                    onCancel: () => closeDownloadModal,
                    onConfirm: () => onDownload
                });
            } else {
                onDownload();
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [downloadCuratedSetPreview, item.id, item.sound_count, setShowDownloadPreviewModal, closeDownloadModal, onDownload, errorHandler]);

    const packDescription = useMemo(() => {
        return item ? (
            <CardDescriptionCreate
                soundsCount={item.sound_count}
                midiCount={item.midi_count}
                presetsCount={item.preset_count}
                genres={item.Genre ? [item.Genre] : undefined}
            />
        ) : null;
    }, [item]);

    return (
        <CardCreate
            contentType="curated-set"
            cardSize="small"
            key={item.id}
            id={`${item.id}`}
            title={item.name}
            approved={item.approved}
            imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 160 })}
            imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 320 })}
            description={packDescription}
            onFavorite={likeDislike}
            isFavorite={isLiked(item.id)}
            onCardPlay={item.demo_file_url ? () => playDemo(curatedPackToCreatePlayable(item)) : undefined}
            link={createAppRoutes.curatedSet(item.id)}
            items={items}
            onDownload={handleDownloadPreview}
        />
    );
}
