import Image from 'next/image';
import classNames from 'classnames';
import { Button } from '../../components/button/button';
import styles from './discount-modal.module.css';
import { Modal, ModalLabel } from '../modal/modal';
import { LoadingEllipsis } from '../../../shared';
import modalImage from './assets/modal-image.png';
import modalStyles from '../modal/modal.module.css';

interface DiscountModalProps {
    isOpen: boolean;
    onClose: () => void;
    onAcceptOffer: () => void;
    onConfirmCancel: () => void;
    isAcceptLoading: boolean;
    isCancelLoading: boolean;
    price: number
    timeLeft: number
}

export function DiscountModal({
    isOpen,
    onClose,
    onAcceptOffer,
    onConfirmCancel,
    isAcceptLoading,
    isCancelLoading,
    price,
    timeLeft
}: DiscountModalProps) {
    const minutesLeft = Math.floor(timeLeft / 60);
    const secondsLeft = timeLeft % 60;

    return (
        <Modal wide isOpen={isOpen} onClose={onClose}>
            <div className={styles['discount-modal__header-background']}>
                <Image src={modalImage} placeholder="blur" />
            </div>
            <div className={styles['discount-modal__content']}>
                <h2 className={styles['discount-modal__heading']}>Before You Go...</h2>

                <div className={styles['discount-modal__limited-offer']}>
                    <div className={styles['discount-modal__limited-offer-top-content']}>
                        <div className={styles['discount-modal__limited-offer-tag']}>
                            Limited Offer
                        </div>
                        <div className={styles['discount-modal__limited-offer-counter']}>
                            Ends in {minutesLeft}:{secondsLeft < 10 ? `0${secondsLeft}` : secondsLeft}
                        </div>
                    </div>

                    <div>
                        <h3 className={styles['discount-modal__limited-offer-heading']}>
                            40% Off!
                        </h3>
                        <ModalLabel>
                            Your new subscription price will be <span className={styles['discount-modal__price']}>${price}</span> per month.
                        </ModalLabel>
                    </div>

                </div>

                <ModalLabel>
                    To say thank you for being a loyal subscriber, we&apos;d like to offer you
                    a limited time offer to maintain your subscription. Your discount will kick in on your next billing cycle and will apply even if you decide to change to a different plan.
                </ModalLabel>

                <div className={styles['discount-modal__buttons']}>
                    <Button
                        disabled={isAcceptLoading || isCancelLoading}
                        className={modalStyles['modal__button']}
                        type="button"
                        variant="primary"
                        onClick={() => onAcceptOffer()}>
                        {isAcceptLoading ? <LoadingEllipsis /> : 'Accept Offer'}
                    </Button>
                    <Button
                        disabled={isAcceptLoading || isCancelLoading}
                        className={classNames(modalStyles['modal__button'], modalStyles['modal__button--destructive'])}
                        type="button"
                        as="button"
                        variant="destructive"
                        onClick={onConfirmCancel}>
                        {isCancelLoading ? <LoadingEllipsis /> : 'No thanks, Confirm Cancellation'}
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
