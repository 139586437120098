import { SortByQueryOptions, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { useInfiniteSearchPack } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, createAppRoutes, useCreateFilterParams, useViewport } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import CardGrid from '../../shared/card-grid/card-grid';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import CreateListGridItem from '../../shared/list-grid-item/create-list-grid-item';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';

import styles from './search-packs.module.css';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';
import { PackCardCreate } from '../../shared/card/pack-card-create/pack-card-create';

const SONGS_LINES_WIDTH = [80, 40];

export interface SearchPacksProps {
    indexPage?: boolean;
    onPackCount: (count: number) => void;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchPacks({ indexPage, onPackCount, isLoadingData, setIsLoadingData }: SearchPacksProps) {
    const router = useRouter();
    const { isMobile } = useViewport();
    const { sortBy } = router.query;
    const overrides = useMemo(() => {
        if (sortBy) {
            return { sort_by: `${sortBy}` as unknown as SortByQueryOptions, limit: 10 };
        }
        return { limit: 10 };
    }, [sortBy]);
    const query = useCreateFilterParams(overrides);

    const { data = [], isLoadingInitialData, isLoadingMore, setSize, isLastPage } = useInfiniteSearchPack(query);

    /* reset the infinite scroll back to first page
  if navigated between a non-index and index page. */
    useEffect(() => {
        setSize(1);
    }, [indexPage, setSize]);

    useEffect(() => {
        if (data[0]?.pagination?.total !== undefined) {
            onPackCount(data[0]?.pagination?.total);
        }
    }, [data, onPackCount]);

    const loadMorePacks = useCallback(() => {
        if (isLoadingMore) return;
        setSize((prevSize) => prevSize + 1);
    }, [isLoadingMore, setSize]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoadingInitialData);
    }, [isLoadingInitialData, setIsLoadingData]);

    const seeMorePath = useMemo(() => {
        const seeMore = `${createAppRoutes.search}?type=packs`;

        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (!router.query['searchTerm']) {
            return seeMore;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation
        return `${seeMore}&searchTerm=${router.query['searchTerm']}`;
    }, [router.query]);

    if (isLoadingData || isLoadingInitialData) {
        return (
            <GhostComponent type="cards" key="search-packs-ghost" elementsCount={10} cardSize="small" title="Packs" linesWidthArray={SONGS_LINES_WIDTH} lineHeight={17} />
        );
    }

    const songs = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flatMediaList = songs ? [].concat(...(songs as any)) : [];

    return (
        <div className={classNames(styles['search-packs'])}>
            {/* eslint-disable-next-line no-nested-ternary */}
            {indexPage ? (
                isMobile && flatMediaList.length > 0 ? null : (
                    <div className={classNames(styles['search-packs__intro-section'], 'spacing--top')}>
                        <div className="spacing--top" />
                        <SecondaryPageTitle title="Packs" counter={data[0]?.pagination?.total?.toString()} />
                        <div className="spacing--top" />
                        {flatMediaList?.length === 0 && <NoResultsBlock hasPadding>No Packs Available</NoResultsBlock>}
                    </div>
                )
            ) : (
                flatMediaList?.length === 0 && (
                    <div className={classNames(styles['search-packs__intro-section'], 'spacing--gap')}>
                        <SecondaryPageTitle title="Packs" counter={data[0]?.pagination?.total?.toString()} />
                        <NoResultsBlock hasPadding hasMargin>No Packs Available</NoResultsBlock>
                    </div>
                )
            )}
            {flatMediaList?.length > 0 ? (
                <div>
                    {indexPage && isMobile && (
                        <div className={styles['search-packs__list-grid-container']}>
                            <ListGrid>
                                {flatMediaList.map((pack) => (
                                    <Fragment key={pack.id}>
                                        <CreateListGridItem
                                            key={pack.id}
                                            id={pack.id}
                                            title={pack.name}
                                            imageUrl={appendQueryParams(pack.artwork_url, { key: 'dw', value: 56 })}
                                            imageUrl2x={appendQueryParams(pack.artwork_url, { key: 'dw', value: 112 })}
                                            subtitle={pack.Genre.name}
                                            link={createAppRoutes.packsSlug(pack.slug)}
                                            contentType="pack"
                                            secondPlayableDemo={pack.demo_file_url_2}
                                        />
                                    </Fragment>
                                ))}
                            </ListGrid>
                        </div>
                    )}
                    {indexPage && !isMobile && (
                        <div className="spacing__window--horizontal">
                            <CardGrid cardType="card-small">
                                {flatMediaList.map((pack: SoundPackage) => (
                                    <Fragment key={`search-packs-card-${pack.id}`}>
                                        <PackCardCreate item={pack} items={flatMediaList} />
                                    </Fragment>
                                ))}
                            </CardGrid>
                        </div>
                    )}

                    {!indexPage && flatMediaList.length > 0 && (
                        <CreateCardCarousel
                            contentType="pack"
                            items={flatMediaList}
                            cardSize="small"
                            carouselTitle="Packs"
                            carouselTitleCounter={data[0]?.pagination?.total?.toString()}
                            carouselMorePath={seeMorePath}
                            itemBasePath={createAppRoutes.packs}
                        />
                    )}
                </div>
            ) : null}
            {indexPage && !isLastPage && !isLoadingMore ? (
                <div className={styles['search-packs__see-more']}><SeeMore expand={false} variant="text" onClick={loadMorePacks} /></div>
            ) : null}
        </div>
    );
}

export default SearchPacks;
