import { useGetSectionByName } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import SeeMoreLayout from '../../see-more-layout/see-more-layout';

export const NewFeaturedPacks = () => {
    const { data: sectionData } = useGetSectionByName('Featured Packs', 'new');

    return (
        <SeeMoreLayout
            contentType="pack"
            pageTitle="New Featured Packs"
            count={sectionData?.items.length || 0}
            itemBasePath={createAppRoutes.packs}
            data={sectionData?.items}
        />
    );
};
