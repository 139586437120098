import { Subscription } from '@bpm-web-app/api-client';
import { PaymentInstrumentStripe, UserAddress } from '@bpm-web-app/supreme-api-sdk';
import { StripeCreatePromise, StripeOptionsCreate, StripeOptionsSupreme, StripeSupremePromise } from '@bpm-web-app/utils';
import { AddressElement, Elements } from '@stripe/react-stripe-js';
import { ContactOption, StripeElementsOptions } from '@stripe/stripe-js';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import Card, { CardRef } from '../../../account/account-add-payment/card';
import { LoadingSpinner } from '../../../shared/loading-spinner/loading-spinner';
import { Button } from '../../components/button/button';
import styles from './stripe-form.module.css';

export interface StripeFormProps {
    isCreate: boolean;
    billingAddress?: UserAddress;
    updateBillingAddress: (address: UserAddress) => Promise<void>
    setPaymentData: (item: PaymentInstrumentStripe) => Promise<void> | void;
    submitButtonLabel?: string
    submitButtonClassName?: string
    isLoading?: boolean
    formId?: string
    afterSubmit?: (id: string) => void
}

export function StripeForm({
    isCreate,
    updateBillingAddress,
    billingAddress,
    setPaymentData,
    isLoading,
    submitButtonClassName,
    submitButtonLabel = 'Save Changes',
    formId,
    afterSubmit,
}: StripeFormProps) {
    const [paymentElementComplete, setPaymentElementComplete] = useState<boolean>(false);
    const [addressInitialized, setAddressInitialized] = useState<boolean>(true);
    const [currentBillingAddress, setCurrentBillingAddress] = useState<ContactOption>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [clientSecret, setClientSecret] = useState<string>();
    const [isStripeReady, setIsStripeReady] = useState<boolean>(false);
    const cardRef = useRef<CardRef>(null);

    useEffect(() => {
        Subscription.getSetupIntent(isCreate ? 'create' : 'supreme').then((data) => {
            setClientSecret(data.data.intent);
            return null;
        }).catch(() => {

        });
    }, [isCreate]);

    useEffect(() => {
        if (billingAddress) {
            setCurrentBillingAddress({
                name: `${billingAddress?.first_name || ''} ${billingAddress?.last_name || ''}`.trim(),
                address: {
                    city: billingAddress?.city || '',
                    country: billingAddress?.country || '',
                    line1: billingAddress?.line1 || '',
                    line2: billingAddress?.line2 || '',
                    postal_code: billingAddress?.zip || '',
                    state: billingAddress?.state || ''
                }
            });
            setAddressInitialized(true);
        }
    }, [billingAddress]);

    const handleFormSubmit = useCallback(async (event: React.FormEvent) => {
        event.preventDefault();
        setIsSubmitting(true);
        try {
            await updateBillingAddress({
                first_name: currentBillingAddress?.name.split(' ')[0] || '',
                last_name: currentBillingAddress?.name.split(' ').splice(1).join(' ') || '',
                city: currentBillingAddress?.address.city,
                state: currentBillingAddress?.address.state,
                country: currentBillingAddress?.address.country,
                zip: currentBillingAddress?.address.postal_code,
                line1: currentBillingAddress?.address.line1,
                line2: currentBillingAddress?.address.line2
            });
            const result = await cardRef.current?.submit();
            if (result && result.payment_method) {
                const { data: paymentMethod } = await Subscription.getPaymentMethodBySetupIntent(isCreate ? 'create' : 'supreme', result.id);
                const res = setPaymentData(paymentMethod);
                try {
                    await res;
                    // eslint-disable-next-line no-empty
                } catch (error) {
                }

                if (typeof afterSubmit === 'function') {
                    afterSubmit(paymentMethod.id);
                }
            }
            // eslint-disable-next-line no-empty
        } catch (error) {

        }

        setIsSubmitting(false);
    }, [currentBillingAddress, afterSubmit, setPaymentData, setIsSubmitting, updateBillingAddress, isCreate]);

    if (!clientSecret || !addressInitialized) {
        return (
            <div className={styles['stripe-form__loading']}>
                <LoadingSpinner isLoading />
            </div>
        );
    }

    return (
        <Elements
            stripe={isCreate ? StripeCreatePromise : StripeSupremePromise}
            options={
                { ...(isCreate ? StripeOptionsCreate : StripeOptionsSupreme), clientSecret } as StripeElementsOptions
            }
        >
            <form id={formId} onSubmit={handleFormSubmit} className={classNames(styles['stripe-form__form'])}>
                <Card
                    ref={cardRef}
                    onReady={() => setIsStripeReady(true)}
                    onChange={(e) => {
                        setPaymentElementComplete(e.complete);
                    }} />

                <div className={styles['stripe-form__spacer']} />
                {addressInitialized && (
                    <AddressElement
                        options={{
                            contacts: [],
                            mode: 'billing',
                            defaultValues: currentBillingAddress,
                            // autocomplete: { mode: 'google_maps_api', apiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY ?? '' }
                        }}
                        onChange={(event) => {
                            setCurrentBillingAddress(event.complete ? event.value as ContactOption : undefined);
                        }} />
                )}
                {isStripeReady && (
                    <Button
                        disabled={currentBillingAddress === undefined || !paymentElementComplete || isSubmitting}
                        as="button"
                        type="submit"
                        variant="primary"
                        isLoading={isLoading}
                        className={classNames(styles['stripe-form__submit-button'], submitButtonClassName)}
                        form={formId}
                    >
                        {submitButtonLabel}
                    </Button>
                )}
            </form>
        </Elements>
    );
}
