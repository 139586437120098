import { CreateSearchPaginatedQuery } from '@bpm-web-app/api-client';
import { SortByQueryOptions } from '@bpm-web-app/create-api-sdk';
import { useInfiniteSearchSound } from '@bpm-web-app/swr-hooks';
import { useViewport, getMutatedSounds, useCreateFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo } from 'react';
import { AppLink } from '../../shared/app-link/app-link';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import TrackListCreate from '../../shared/track-list/create/track-list-create';

import styles from './search-sounds.module.css';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';

const SEARCH_PAGE_SOUNDS_LIMIT = 10;

export interface SearchSoundsProps {
    hideShowMore?: boolean;
    mainGenre?: string;
    onSoundsCount: (count: number) => void;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchSounds({ hideShowMore = false, mainGenre, onSoundsCount, isLoadingData, setIsLoadingData }: SearchSoundsProps) {
    const router = useRouter();
    const { isMobile } = useViewport();

    const { sortBy } = router.query;

    const overrides = useMemo(() => {
        const o: Partial<CreateSearchPaginatedQuery> = sortBy ? {
            sort_by: `${sortBy}` as unknown as SortByQueryOptions
        } : {};
        if (hideShowMore) {
            o.limit = SEARCH_PAGE_SOUNDS_LIMIT;
        }
        if (mainGenre) {
            o.genre = mainGenre;
        }
        return o;
    }, [hideShowMore, mainGenre, sortBy]);

    const query = useCreateFilterParams(overrides);

    const { data = [], isLoadingInitialData, isLoadingMore, setSize, isLastPage, mutate } = useInfiniteSearchSound(query);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(data, id, progress));
        },
        [mutate, data]
    );

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    /* reset the infinite scroll back to first page
  if navigated between a non-index and index page. */
    useEffect(() => {
        setSize(1);
    }, [hideShowMore, setSize]);

    useEffect(() => {
        if (data?.[0]?.pagination?.total !== undefined) {
            onSoundsCount(data?.[0]?.pagination?.total);
        }
    }, [data, onSoundsCount]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoadingInitialData);
    }, [isLoadingInitialData, setIsLoadingData]);

    if (isLoadingData || isLoadingInitialData) {
        return (
            <GhostComponent type="track-list" key="search-sounds-ghost" title="Sounds" isCreate isCreatePreset={false} />
        );
    }

    const songs = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flatMediaList = songs ? [].concat(...(songs as any)) : [];

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div className={styles['search-sounds']}>
            {(!hideShowMore || !isMobile || (hideShowMore && isMobile && flatMediaList?.length === 0)) && (
                <div className={styles['search-sounds__intro-section']}>
                    <SecondaryPageTitle title="Sounds" counter={data?.[0]?.pagination?.total} />
                </div>
            )}
            <div className="spacing--top" />
            <div className="spacing__window--horizontal">
                <TrackListCreate
                    mutateSound={mutateSound}
                    list={flatMediaList}
                    onLoadMore={hideShowMore ? handleLoadMore : undefined}
                    isLoadingMore={isLoadingMore}
                    hideHeading={isMobile} />
                {flatMediaList?.length > 0 && !hideShowMore && (
                    <AppLink href={`${router.asPath}&type=sounds`}>
                        <div className={styles['search-sounds__see-more']}><SeeMore expand={false} variant="text" /></div>
                    </AppLink>
                )}
            </div>
        </div>
    );
}

export default SearchSounds;
