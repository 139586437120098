import { useGetAllGiveaways } from '@bpm-web-app/swr-hooks';
import { Fragment } from 'react';
import { CardCarousel } from '../shared';
import { GiveawayCard } from '../shared/card/giveaway-card/giveaway-card';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ContestsProps { }

export function Contests(props: ContestsProps) {
    const { data, isLoading, error } = useGetAllGiveaways();

    if (isLoading || error) return <div />;

    return (
        <>
            {data && data.data.current ? (
                <CardCarousel title="Current Contests">
                    {data.data.current.map((giveaway) => (
                        <Fragment key={`current-giveaway-card--${giveaway.id}`}>
                            <GiveawayCard giveaway={giveaway} />
                        </Fragment>
                    ))}
                </CardCarousel>
            ) : null}

            {data && data.data.past ? (
                <CardCarousel title="Past Contests">
                    {data.data.past.map((giveaway) => (
                        <Fragment key={`past-giveaway-card--${giveaway.id}`}>
                            <GiveawayCard giveaway={giveaway} isPast />
                        </Fragment>
                    ))}
                </CardCarousel>
            ) : null}

        </>
    );
}

export default Contests;
