import { getFavoriteAlbums, useFavoriteMedia, getFollowedPlaylists, getFollowedCuratedSets, useFollowedArtists } from '@bpm-web-app/swr-hooks';
import { useHubSwitch, useViewport } from '@bpm-web-app/utils';
import { Album as StreamAlbum } from '@bpm-web-app/stream-api-sdk';
import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useContext } from 'react';
import FavoritesDesktop from './favorites-desktop/favorites-desktop';
import FavoritesMobile from './favorites-mobile/favorites-mobile';
import { LibraryTabsContext } from '../../../../../utils/src/lib/library-tabs.context';
import { GhostComponent, PrimaryPageTitle } from '../../shared';

export function Favorites() {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const { isDownload, isCreate } = useHubSwitch();

    const { data: favAlbums, isLoading: isLoadingAlbums, mutate: mutateAlbums } = getFavoriteAlbums(libraryProperty, !isCreate);
    const { data: favMedia, isLoading: isLoadingMedia } = useFavoriteMedia(libraryProperty);
    const { data: favPlaylists, isLoading: isLoadingPlaylists } = getFollowedPlaylists(libraryProperty);
    const { data: favCuratedSets, isLoading: isLoadingCuratedSets } = getFollowedCuratedSets(libraryProperty);
    const { data: favArtists, isLoading: isLoadingArtists } = useFollowedArtists(libraryProperty);
    const { isMobile } = useViewport();

    if (isLoadingAlbums || isLoadingMedia || isLoadingPlaylists || isLoadingCuratedSets || isLoadingArtists) {
        if (isMobile) {
            return (
                <>
                    <div className="spacing--top" />
                    <GhostComponent type="secondary-title" />
                    <GhostComponent type="track-list" elementsCount={10} />
                </>
            );
        }

        return (
            <>
                <PrimaryPageTitle title="Favorites" />
                <div className="spacing--top-double" />
                <GhostComponent type="secondary-title" />
                <GhostComponent type="artist-cards" elementsCount={10} linesWidthArray={[90, 60]} />
                <GhostComponent type="secondary-title" />
                <GhostComponent type="track-list" elementsCount={10} />
                {[1, 2, 3].map(() => {
                    return (
                        <>
                            <GhostComponent type="secondary-title" />
                            <GhostComponent type="cards" elementsCount={10} linesWidthArray={[90, 60]} />
                        </>
                    );
                })}
            </>
        );
    }

    return isMobile ? (
        <FavoritesMobile
            favAlbums={!isDownload ? (favAlbums?.data as StreamAlbum[]) : null}
            favMedia={!isDownload ? favMedia?.data : (favAlbums?.data as DownloadAlbum[])}
            favPlaylists={favPlaylists?.data}
            favCuratedSets={favCuratedSets?.data}
            favArtists={favArtists?.data}
        />
    ) : (
        <FavoritesDesktop
            favAlbums={!isDownload ? (favAlbums?.data as StreamAlbum[]) : null}
            favMedia={!isDownload ? favMedia?.data : (favAlbums?.data as DownloadAlbum[])}
            favPlaylists={favPlaylists?.data}
            favCuratedSets={favCuratedSets?.data}
            favArtists={favArtists?.data}
            onDownloadAlbumsRevalidate={(downloadMedia) => {
                if (downloadMedia) {
                    // eslint-disable-next-line no-param-reassign
                    downloadMedia.download_count += 1;
                    mutateAlbums(favAlbums, false);
                }
            }} />
    );
}

export default Favorites;
