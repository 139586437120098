import { GenericPaginatedQuery, Giveaway } from '@bpm-web-app/api-client';
import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';

interface SearchPaginatedQuery extends GenericPaginatedQuery {
    id: string;
}

export function useGetGiveaway(slug: string) {
    const { data, error } = useSWR(['supreme-get-giveaway', slug], () => Giveaway.getGiveaway(slug));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetGiveawayEntry(id: string, disabled?: boolean) {
    const { data, error, mutate } = useSWR(
        ['supreme-get-giveaway-entry', id],
        () => Giveaway.getGiveawayEntry(id),
        {
            isPaused: () => !!disabled,
            onError() {

            }
        });

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate
    };
}

export function useGetGiveawayComments(id: string, limit: number, skip: number) {
    const { data, error, mutate } = useSWR(['supreme-get-giveaway-comments', id, limit, skip], () => Giveaway.getGiveawayComents(id, limit, skip));

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}

export function useInfiniteGiveawayComments(query: SearchPaginatedQuery, disabled: boolean) {
    const { limit } = query;
    const pageSize = limit || 50;
    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
        (index) => `giveaway-comments-${query.id}-${index}`,
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            const skip = +pageIndex * pageSize;
            return Giveaway.getGiveawayComents(query.id, pageSize, skip);
        },
        {
            revalidateFirstPage: true,
            isPaused: () => !!disabled,
        }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}

export function useGetAllGiveaways(disabled = false) {
    const { data, error } = useSWR(['supreme-get-all-giveaways', { disabled }], () => Giveaway.getAllGiveaways(), { isPaused: () => disabled });

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
