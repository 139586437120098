import { useGetFeaturedSoundPackages } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useCreateFilterParams } from '@bpm-web-app/utils';
import { SeeMoreLayout } from '../../see-more-layout/see-more-layout';

export const FeaturedPacks = () => {
    const query = useCreateFilterParams();
    const { data: featuredSoundsData } = useGetFeaturedSoundPackages(query);
    return (
        <SeeMoreLayout
            contentType="pack"
            pageTitle="Featured Packs"
            count={featuredSoundsData?.pagination?.total || 0}
            itemBasePath={createAppRoutes.packs}
            data={featuredSoundsData?.data}
        />
    );
};
