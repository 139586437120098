import { Giveaway } from '@bpm-web-app/supreme-api-sdk';
import { appendQueryParams } from '@bpm-web-app/utils';
import dayjs from 'dayjs';
import Card from '../card';

interface GiveawayCardProps {
    giveaway?: Giveaway;
    isPast?: boolean;
}

export function GiveawayCard({ giveaway, isPast }: GiveawayCardProps) {
    if (isPast && giveaway) {
        return (
            <Card
                cardSize="large"
                id={giveaway.id}
                title={giveaway.title}
                label="EXPIRED"
                description={`Expired ${dayjs(giveaway.entry_deadline).tz('America/Los_Angeles').format('MMMM DD, YYYY')}`}
                imageUrl={appendQueryParams(giveaway.artwork_url, { key: 'dw', value: 264 })}
                imageUrl2x={appendQueryParams(giveaway.artwork_url, { key: 'dw', value: 528 })}
                isPlayable={false}
                link={`/giveaway/${giveaway.slug}`}
                isDescriptionSingleLine
            />
        );
    }
    return giveaway ? (
        <Card
            cardSize="large"
            id={giveaway.id}
            title={giveaway.title}
            description={`Deadline ${dayjs(giveaway.entry_deadline).tz('America/Los_Angeles').format('MMMM DD, YYYY hh:mm')} PST`}
            imageUrl={appendQueryParams(giveaway.artwork_url, { key: 'dw', value: 264 })}
            imageUrl2x={appendQueryParams(giveaway.artwork_url, { key: 'dw', value: 528 })}
            isPlayable={false}
            link={`/giveaway/${giveaway.slug}`}
            isDescriptionSingleLine
        />

    ) : null;
}
