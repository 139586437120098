import { useInfiniteFreeSoundPackages } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import Filters from '../../filters/filters';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './free-sound-packs-all.module.css';
import { LoadMore } from '../../shared/load-more/load-more';
import SeeMoreLayout from '../../see-more-layout/see-more-layout';

export const FreeSoundPacksAll = () => {
    const query = useCreateFilterParams();

    const { data: freePacksData, isLoadingMore, setSize, isLastPage } = useInfiniteFreeSoundPackages(query);

    const flatFreePacks = useMemo(() => freePacksData?.flatMap((data) => data.data), [freePacksData]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    return (
        <div className={styles['free-sound-packs']}>
            <div className={styles['free-sound-packs__header']}>
                <PrimaryPageTitle title="Free Packs" />
                <Filters platform="create" showOnMobile />
            </div>
            <SeeMoreLayout
                contentType="pack"
                itemBasePath={createAppRoutes.packs}
                data={flatFreePacks}
            />
            <LoadMore onLoadMore={handleLoadMore} />
        </div>
    );
};
