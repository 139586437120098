import {
    useCreateLike,
    useDownloadMultiSounds,
    useDownloadMultiSoundsPreview,
    useDownloadSoundPackage,
    useDownloadSoundPackagePreview,
    useGetCredits,
    useGetSoundPackage,
    useInfiniteSearchPresets,
    useInfiniteSearchSound,
} from '@bpm-web-app/swr-hooks';
import {
    appendQueryParams,
    convertToPluralIfNeeded,
    DEFAULT_SOUNDS_LIMIT,
    State,
    useDebounce,
    usePlayerState,
    soundPackageToCreatePlayable,
    fileDownload,
    useApiErrorHandler,
    createAppRoutes,
    isMobileNavigator,
    getMutatedSounds,
    getMutatedPresets,
    showToast,
    useCreateFilterParams,
    useViewport,
    Analytics,
    rebuildReactTooltip
} from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ClickableGenres, Title, useCreatePlayer } from '@bpm-web-app/components';
import { CreateThreeDotsSheetContext, ExpandableText, MissingImgPlaceholder, NoResultsBlock, BreakpointView, AppLink, QualityControlContext, QualityControlInput, GhostComponent } from '@bpm-web-app/components/shared';
import dayjs from 'dayjs';
import { MediaDetailBanner } from '../../shared/ui/media-detail-banner/media-detail-banner';
import styles from './pack-detail.module.css';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';
import { TrackListCreate } from '../../shared/track-list/create/track-list-create';
import Filters from '../../filters/filters';
import { SearchInput } from '../../shared/search-input/search-input';
import { ActionModal } from '../../shared/action-modal/action-modal';
import { TrackListCreatePresets } from '../../shared/track-list/create-presets/track-list-create-presets';
import { PlayButton } from '../../shared/play-button/play-button';
import { SquaredButton } from '../../shared/squared-button/squared-button';
import BPMIcons from '../../shared/bpm-icons/bpm-icons';
import { DownloadButton } from '../../shared/download-button/download-button';
import { ShareURL } from '../../shared/share-url/share-url';
import { ThreeDotsButton } from '../../shared/three-dots-button/three-dots-button';
import { AddToFavoriteButton } from '../../shared/add-to-favorite-button/add-to-favorite-button';

export const PackDetail = () => {
    const router = useRouter();
    const { isMobile } = useViewport();

    const { slug } = router.query;

    const [searchQuery, setSearchQuery] = useState<string>('');
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    const { data: soundPackage } = useGetSoundPackage(slug as string);

    const { currentTrack, playDemo, togglePlayPause } = useCreatePlayer();
    const playerState = usePlayerState();
    const { mutate: refreshCredits } = useGetCredits();
    const downloadMultiSounds = useDownloadMultiSounds();
    const downloadMultiSoundsPreview = useDownloadMultiSoundsPreview();
    const downloadPack = useDownloadSoundPackage();
    const downloadPackPreview = useDownloadSoundPackagePreview();
    const errorHandler = useApiErrorHandler();
    const [creditAmount, setCreditAmount] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);
    const [actionModalOpen, setActionModalOpen] = useState<boolean>(false);
    const [downloadSubtitle, setDownloadSubtitle] = useState<string>('this file');
    const { setSelectedSounds } = useContext(QualityControlContext);

    const query = useCreateFilterParams({ search: debouncedSearchQuery, sound_package_id: `${slug}` });

    const packData = soundPackage?.data.pack;

    const { data: soundsData, isLoadingMore, isLoadingInitialData, setSize, isLastPage, mutate } = useInfiniteSearchSound(query);

    const presetQuery = useMemo(() => {
        return {
            ...query,
            limit: packData?.sound_count === 0 ? DEFAULT_SOUNDS_LIMIT : 3
        };
    }, [query, packData?.sound_count]);

    // eslint-disable-next-line max-len
    const { data: presetData, isLoadingInitialData: isLoadingInitialDataPresets, isLoadingMore: isLoadingMorePresets, setSize: setSizePreset, isLastPage: isLastPagePreset, mutate: mutatePresetFn } = useInfiniteSearchPresets(presetQuery);

    const presetList = presetData?.flatMap(({ data }) => data);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    const mutatePreset = useCallback(
        (id: string, progress: number) => {
            mutatePresetFn(getMutatedPresets(presetData, id, progress));
        },
        [mutatePresetFn, presetData]
    );

    const [selectedItemsIds, setSelectedItemsIds] = useState<string[]>([]);
    const handleSelectItem = useCallback((selectedSoundId: string) => {
        setSelectedItemsIds((prevState) => {
            if (prevState.includes(selectedSoundId)) {
                setSelectedSounds(prevState.filter((soundId) => selectedSoundId !== soundId));
                return prevState.filter((soundId) => selectedSoundId !== soundId);
            }
            setSelectedSounds([...prevState, selectedSoundId]);
            return [...prevState, selectedSoundId];
        });
    }, [setSelectedSounds]);

    const soundsList = soundsData?.flatMap(({ data }) => data);

    const handleToggleAllSound = useCallback(
        (all: boolean) => {
            setSelectedItemsIds(() => (all ? (soundsList || []).map((sound) => sound.id) : []));
            setSelectedSounds(all ? (soundsList || []).map((sound) => sound.id) : []);
        },
        [setSelectedSounds, soundsList]
    );

    const handleToggleAllPreset = useCallback(
        (all: boolean) => {
            setSelectedItemsIds(() => (all ? (presetList || []).map((sound) => sound.id) : []));
        },
        [presetList]
    );

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    const handleLoadMorePresets = useCallback(() => {
        if (!isLoadingMorePresets && !isLastPagePreset) {
            setSizePreset((prevSize) => prevSize + 1);
        }
    }, [isLastPagePreset, isLoadingMorePresets, setSizePreset]);

    const { isLiked, likeDislike } = useCreateLike('soundPackage');

    const handleLikePack = useCallback(() => {
        likeDislike(packData?.id as string);
        rebuildReactTooltip();
    }, [likeDislike, packData?.id]);

    useEffect(() => {
        rebuildReactTooltip();
    }, []);

    /* reset selected sounds on any query changes. */
    useEffect(() => {
        return () => {
            // Reset selected Ids on unmount
            setSelectedItemsIds([]);
        };
    }, [query]);

    const { openThreeDotsModalSheet } = useContext(CreateThreeDotsSheetContext);
    const { isQualityControlActive, shouldMutateData, setShouldMutateData } = useContext(QualityControlContext);

    useEffect(() => {
        if (shouldMutateData) {
            mutate();
            setShouldMutateData(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldMutateData]);

    const openThreeDots = useCallback(
        async (element) => {
            if (packData?.id) {
                const { top, left } = (element.target as HTMLButtonElement).getBoundingClientRect();
                openThreeDotsModalSheet({
                    newCreateActionType: 'pack',
                    actionId: packData.id,
                    secondaryActionId: packData.slug,
                    left,
                    top: top + window.scrollY,
                    actionsToHide: packData?.demo_file_url ? [] : ['play-demo'],
                    showExtraPlayDemoOption: !!packData?.demo_file_url_2,
                    shareUrl: createAppRoutes.packsSlug(packData.slug),
                });
            }
        },
        [openThreeDotsModalSheet, packData]
    );

    const handleDownloadUrl = useCallback(
        (url: string) => {
            fileDownload(url);
            refreshCredits();
        },
        [refreshCredits]
    );

    const resetDownloadModalStatus = () => {
        setCreditAmount(null);
        setIsDownloading(false);
    };

    const handleDownloadPack = useCallback(async () => {
        try {
            setIsDownloading(true);
            const response = await showToast({
                promise: downloadPack(packData.id, router.asPath),
                message: 'Download in progress.',
                successText: 'Download successful.',
                preventErrorToast: true
            });
            if (response && response.data && response.data.url) handleDownloadUrl(response.data.url);
            if (packData?.id !== undefined) {
                Analytics.trackClick('download_pack', packData?.id, { location: 'pack-detail' });
            }
            setSelectedItemsIds([]);
        } catch (err) {
            errorHandler({ error: err });
        } finally {
            resetDownloadModalStatus();
            setIsDownloading(false);
        }
    }, [packData, downloadPack, errorHandler, handleDownloadUrl, router]);

    const handleDownloadPackPreview = useCallback(async () => {
        try {
            const response = await downloadPackPreview(packData.id);
            const creditsRequired = response?.data?.required?.total;

            setCreditAmount(creditsRequired);
            if (creditsRequired > 0) {
                setDownloadSubtitle('this sound package');
                setActionModalOpen(true);
            } else {
                handleDownloadPack();
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [downloadPackPreview, packData?.id, handleDownloadPack, errorHandler]);

    const handleDownloadSelectedItems = useCallback(async () => {
        try {
            setIsDownloading(true);
            const downloadMediaUrlData = await showToast({
                promise: downloadMultiSounds(selectedItemsIds, router.asPath),
                message: 'Download in progress.',
                successText: 'Download successful.',
                preventErrorToast: true
            });
            if (downloadMediaUrlData) {
                const payload = await downloadMediaUrlData?.json();
                if (payload?.data?.url) {
                    handleDownloadUrl(payload.data.url);
                    setSelectedItemsIds([]);
                }
            }
            Analytics.trackClick('download_multi', '', { location: 'pack-detail' });
        } catch (err) {
            errorHandler({ error: err });
        } finally {
            resetDownloadModalStatus();
            setIsDownloading(false);
        }
    }, [downloadMultiSounds, errorHandler, handleDownloadUrl, selectedItemsIds, router]);

    const handleDownloadSelectedItemsPreview = useCallback(async () => {
        try {
            const response = await downloadMultiSoundsPreview(selectedItemsIds, router.asPath);
            const creditsRequired = response?.data?.required?.total;
            setCreditAmount(creditsRequired);
            if (creditsRequired > 0) {
                if (selectedItemsIds?.length === 1) setDownloadSubtitle('this file');
                else if (selectedItemsIds?.length > 1) setDownloadSubtitle('these files');
                setActionModalOpen(true);
            } else {
                handleDownloadSelectedItems();
            }
        } catch (err) {
            errorHandler({ error: err });
        }
    }, [downloadMultiSoundsPreview, errorHandler, handleDownloadSelectedItems, selectedItemsIds, router]);

    const handlePreviewAndDownloadSelectedOrAll = useCallback(
        async (isPreview: boolean) => {
            if (!packData && !soundsList?.length) return;
            if (!selectedItemsIds?.length || soundsData?.[0]?.pagination?.total === selectedItemsIds.length) {
                if (isPreview) {
                    await handleDownloadPackPreview();
                } else {
                    await handleDownloadPack();
                }
            } else if (isPreview) {
                await handleDownloadSelectedItemsPreview();
            } else {
                await handleDownloadSelectedItems();
            }
        },
        [packData, soundsList?.length, selectedItemsIds?.length, soundsData, handleDownloadPackPreview, handleDownloadPack, handleDownloadSelectedItemsPreview, handleDownloadSelectedItems]
    );

    const handlePlay = useCallback(() => {
        if (currentTrack?.id === packData?.id) {
            togglePlayPause();
        } else {
            playDemo(soundPackageToCreatePlayable(packData));
        }
    }, [currentTrack, packData, playDemo, togglePlayPause]);

    const isPlayable = useMemo(() => packData?.demo_file_url || packData?.demo_file_url_2, [packData]);

    const isEverythingLoaded = useMemo(() => {
        if (isLoadingInitialData === false && isLoadingInitialDataPresets === false) return true;
        return false;
    }, [isLoadingInitialData, isLoadingInitialDataPresets]);

    const ghostLoading = () => {
        return (
            <>
                <Title platform="create" title="Loading Pack..." />
                <div className="spacing__window--horizontal">
                    <div className="spacing__window--top" />
                    <GhostComponent type="pack-detail" />
                </div>
                <SecondaryPageTitle title="Sounds" />
                <div className={styles['pack-detail__ghost-banner-spacer']} />
                <GhostComponent type="track-list" isCreate />
            </>
        );
    };

    if (!isEverythingLoaded) return ghostLoading();

    return (
        <>
            <Title platform="create" title={packData?.name} />
            <div className={classNames(styles['pack-detail'], 'spacing__window', { [styles['pack-detail--qc']]: packData?.approved === false && dayjs(packData.created_at).isBefore(new Date()) })}>
                <MediaDetailBanner align={isMobile ? 'end' : 'start'}>
                    {{
                        image: (
                            <div className={styles['pack-detail__img-container']}>
                                {packData?.artwork_url ? (
                                    <picture>
                                        <source
                                            srcSet={`${appendQueryParams(packData.artwork_url, { key: 'dw', value: 296 })}, ${appendQueryParams(packData.artwork_url, { key: 'dw', value: 592 })} 2x`}
                                            media="(min-width: 1024px)"
                                        />
                                        <img
                                            src={appendQueryParams(packData.artwork_url, { key: 'dw', value: 296 })}
                                            srcSet={`${appendQueryParams(packData.artwork_url, { key: 'dw', value: 592 })} 2x`}
                                            alt={packData?.name}
                                            draggable={false}
                                        />
                                    </picture>
                                ) : (
                                    <MissingImgPlaceholder />
                                )}
                            </div>
                        ),
                        text: (
                            <>
                                <div className={styles['pack-detail__title-container']}>
                                    {!isQualityControlActive ?
                                        <h2>{packData?.name}</h2>
                                        : packData ? <QualityControlInput associatedId={packData?.id} isCreate inputType="title" text={packData?.name} /> : null}
                                    <Filters platform="create" showOnMobile />

                                </div>

                                {!isQualityControlActive && packData ? (
                                    <div className={styles['pack-detail__info']}>
                                        {packData?.sound_count > 0 && <span>{`${convertToPluralIfNeeded(packData?.sound_count ?? 0, 'Sound')}`}</span>}
                                        {packData?.preset_count > 0 && <span>{`${convertToPluralIfNeeded(packData?.preset_count ?? 0, 'Preset')}`}</span>}
                                        {packData?.Genre && <ClickableGenres genres={packData?.Genre} />}
                                    </div>
                                ) : packData ? (
                                    <div>
                                        <div>
                                            {packData?.Genre ? <QualityControlInput associatedId={packData?.id} isCreate inputType="genre" text={packData?.Genre.name} /> : null}
                                            {packData?.SubGenres ? <QualityControlInput associatedId={packData?.id} isCreate inputType="subgenres" text={packData?.SubGenres.map((e) => e.name).join(', ')} subgenresData={packData.SubGenres} /> : null}
                                        </div>
                                        <div className={styles['pack-detail__info']}>
                                            {packData?.sound_count > 0 && <span>{`${convertToPluralIfNeeded(packData?.sound_count ?? 0, 'Sound')}`}</span>}
                                            {packData?.preset_count > 0 && <span>{`${convertToPluralIfNeeded(packData?.preset_count ?? 0, 'Preset')}`}</span>}
                                        </div>
                                    </div>
                                ) : null}
                                {!isQualityControlActive ? (
                                    <ExpandableText collapsedLinesCount={3}>
                                        <div className={styles['pack-detail__description']}>{packData?.description}</div>
                                    </ExpandableText>
                                ) : packData ? (
                                    <QualityControlInput associatedId={packData?.id} isCreate inputType="description" text={packData?.description} />
                                ) : null}
                            </>
                        ),
                        actions: (
                            <>
                                {isPlayable && (
                                    <BreakpointView
                                        desktopChildren={
                                            (
                                                <PlayButton
                                                    type="demo"
                                                    onPress={(e) =>
                                                        packData?.demo_file_url_2
                                                            ? openThreeDotsModalSheet({
                                                                newCreateActionType: 'mini-player-demos',
                                                                actionId: packData?.id,
                                                                left: e.currentTarget.getBoundingClientRect().left + 20,
                                                                top: e.currentTarget.getBoundingClientRect().top + window.scrollY - 80,
                                                                shareUrl: createAppRoutes.packsSlug(packData.slug),
                                                            })
                                                            : handlePlay()
                                                    }
                                                    isPlaying={currentTrack?.id === packData?.id && playerState === State.Playing}
                                                />
                                            )
                                        }
                                        mobileChildren={null} />

                                )}
                                {isMobileNavigator ? null : (
                                    <BreakpointView
                                        mobileChildren={null}
                                        desktopChildren={
                                            <DownloadButton isLoading={isDownloading} type={isPlayable ? 'outline' : 'dynamic'} onPress={() => handlePreviewAndDownloadSelectedOrAll(true)} />
                                        }
                                    />
                                )}
                                <AddToFavoriteButton isCreate isFavorite={isLiked(packData?.id || '')} onClick={handleLikePack} hasTooltip />
                                <ShareURL currentUrl />
                                <ThreeDotsButton hasTooltip onClick={openThreeDots} />
                            </>
                        ),
                    }}
                </MediaDetailBanner>

                <BreakpointView
                    desktopChildren={undefined}
                    mobileChildren={
                        (
                            <div className={classNames(styles['pack-detail__actions'], 'spacing--bottom')}>
                                <PlayButton
                                    type="demo"
                                    onPress={(e) =>
                                        packData?.demo_file_url_2
                                            ? openThreeDotsModalSheet({
                                                newCreateActionType: 'mini-player-demos',
                                                actionId: packData?.id,
                                                left: e.currentTarget.getBoundingClientRect().left + 20,
                                                top: e.currentTarget.getBoundingClientRect().top + window.scrollY - 80,
                                                shareUrl: createAppRoutes.packsSlug(packData.slug),
                                            })
                                            : handlePlay()
                                    }
                                    isPlaying={currentTrack?.id === packData?.id && playerState === State.Playing}
                                    fullWidth
                                />

                                <SquaredButton
                                    type="outline"
                                    svgColoring="stroke"
                                    label={isLiked(packData?.id || '') ? 'Remove from Saved' : 'Save'}
                                    onPress={handleLikePack}
                                    leftIcon={isLiked(packData?.id || '') ? <BPMIcons.FavoriteHeartFilledIcon /> : <BPMIcons.FavoriteHeartIcon />}
                                    fullWidth />
                            </div>
                        )
                    } />
                <div className={styles['pack-detail__track-list-controls']}>
                    <div className={styles['pack-detail__search-container']}>
                        <SearchInput
                            placeholder="Search Sounds"
                            value={searchQuery}
                            onChange={(e) => {
                                setSearchQuery(e.target.value);
                            }}
                            onClear={() => setSearchQuery('')}
                        />
                    </div>
                </div>

                {presetList && presetList.length > 0 && (
                    <>
                        <SecondaryPageTitle title="Synth Presets" counter={presetData?.[0]?.pagination?.total} noPadding />
                        <div className={styles['pack-detail__track-list']}>
                            <TrackListCreatePresets
                                mutateSound={mutatePreset}
                                list={presetList}
                                onSelectItem={handleSelectItem}
                                selectedItemsIds={selectedItemsIds}
                                onSelectAll={handleToggleAllPreset}
                                onLoadMore={handleLoadMorePresets}
                                isLoadingMore={isLoadingMorePresets}
                                hasMore={!isLastPagePreset}
                                hidePackName
                                automatedLoadMore={packData?.sound_count === 0}
                            />
                        </div>
                    </>
                )}
                {soundsList && (
                    <>
                        <SecondaryPageTitle title="Sounds" counter={soundsData?.[0]?.pagination?.total} noPadding />
                        <div className={styles['pack-detail__track-list']}>
                            {soundsList?.length ? (
                                <TrackListCreate
                                    mutateSound={mutateSound}
                                    list={soundsList}
                                    onSelectItem={handleSelectItem}
                                    selectedItemsIds={selectedItemsIds}
                                    onSelectAll={handleToggleAllSound}
                                    onLoadMore={handleLoadMore}
                                    isLoadingMore={isLoadingMore}
                                    hidePackName
                                />
                            ) : (
                                <NoResultsBlock>No Sounds Available</NoResultsBlock>
                            )}
                        </div>
                    </>)}

            </div>
            <ActionModal
                headerTitle="Heads Up"
                title="You are about to use a few credits."
                subtitle={`Do you want to download ${downloadSubtitle} for ${creditAmount} Credits?`}
                confirmButtonText="Continue"
                onConfirm={() => {
                    handlePreviewAndDownloadSelectedOrAll(false);
                    setActionModalOpen(false);
                }}
                onClose={() => {
                    resetDownloadModalStatus();
                    setActionModalOpen(false);
                }}
                actionLoading={isDownloading}
                variant="light"
                isOpen={actionModalOpen}
            />
        </> // note Download can cost 0 credits
    );
};
