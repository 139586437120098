import { ArtistsMusicView } from './artists-music-view';
import { CuratedSetsMusicView } from './curated-music-view';
import { MusicViewProps } from './music-view-props';
import { PacksMusicView } from './packs-music-view';
import { PlaylistMusicView } from './playlist-music-view';
import { PresetMusicView } from './preset-music-view';
import { SoundsMusicView } from './sounds-music-view';
import { TracksMusicView } from './tracks-music-view';
import { TrendingMusicView } from './trending-music-view';

export function MusicView(musicViewProps: MusicViewProps) {
    const { type, variant, removePadding = false } = musicViewProps;
    const loadMusicView = () => {
        switch (type) {
            // SUPREME platform
            case 'tracks':
                return <div className={!removePadding && variant === 'fullscreen' ? 'spacing__window--top' : ''}><TracksMusicView {...musicViewProps} /></div>;
            case 'playlists':
                return <div className={!removePadding && variant === 'fullscreen' ? 'spacing__window' : ''}><PlaylistMusicView {...musicViewProps} /></div>;
            case 'trending':
                return <TrendingMusicView {...musicViewProps} />;
            case 'artists':
                return <div className="spacing--vertical"><ArtistsMusicView {...musicViewProps} /></div>;
            // CREATE platform
            case 'packs':
                return <div className={!removePadding && variant === 'fullscreen' ? 'spacing__window' : ''}><PacksMusicView {...musicViewProps} /></div>;
            case 'sounds':
                return <div className={!removePadding && variant === 'fullscreen' ? 'spacing__window' : ''}><SoundsMusicView {...musicViewProps} /></div>;
            case 'presets':
                return <div className={!removePadding && variant === 'fullscreen' ? 'spacing__window' : ''}><PresetMusicView {...musicViewProps} /></div>;
            case 'curated':
                return <div className={!removePadding && variant === 'fullscreen' ? 'spacing__window' : ''}><CuratedSetsMusicView {...musicViewProps} /></div>;

            default:
                return <TracksMusicView {...musicViewProps} />;
        }
    };

    return (
        <>
            {loadMusicView()}
        </>
    );
}
