import { Section } from '@bpm-web-app/api-client';
import useSWR from 'swr';

export function useGetSections(location: string) {
    const { data, error } = useSWR(
        [
            'create-sections',
            {
                persist: true,
                location,
            },
        ],
        () => Section.getSections(location)
    );

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}

export function useGetSectionByName(sectionName: string, location: string) {
    const { data, error } = useSWR(
        [
            'create-section-by-name',
            {
                persist: true,
                sectionName,
                location,
            },
        ],
        () => Section.getSectionByName(location, sectionName)
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}
