import { Genre, SortByQueryOptions } from '@bpm-web-app/create-api-sdk';

import { useInfiniteSearchCuratedSet } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, createAppRoutes, useCreateFilterParams, useViewport } from '@bpm-web-app/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import CardGrid from '../../shared/card-grid/card-grid';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import CreateListGridItem from '../../shared/list-grid-item/create-list-grid-item';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { SecondaryPageTitle } from '../../shared/secondary-page-title/secondary-page-title';

import styles from './search-create-curated-sets.module.css';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';
import { PackCardCreate } from '../../shared/card/pack-card-create/pack-card-create';

const CURATED_SETS_LINES_WIDTH = [90];

export interface SearchCreateCuratedSetsProps {
    indexPage?: boolean;
    onCuratedSetCount: (count: number) => void;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchCreateCuratedSets({ indexPage, onCuratedSetCount, isLoadingData, setIsLoadingData }: SearchCreateCuratedSetsProps) {
    const router = useRouter();

    const { isMobile } = useViewport();

    const { sortBy } = router.query;
    const overrides = useMemo(() => {
        if (sortBy) {
            return { sort_by: `${sortBy}` as unknown as SortByQueryOptions, limit: 10 };
        }
        return { limit: 10 };
    }, [sortBy]);
    const query = useCreateFilterParams(overrides);

    const { data = [], isLoadingInitialData, isLoadingMore, setSize, isLastPage } = useInfiniteSearchCuratedSet(query);

    const getDescriptionMarkup = (cardGenre: Genre) => (
        <div className={styles['search-create-curated-sets__genre']}>
            <Link href={createAppRoutes.genresSlug(cardGenre.name)}>
                <span>{cardGenre.name}</span>
            </Link>
        </div>
    );

    /* reset the infinite scroll back to first page
  if navigated between a non-index and index page. */
    useEffect(() => {
        setSize(1);
    }, [indexPage, setSize]);

    useEffect(() => {
        if (data[0]?.pagination?.total !== undefined) {
            onCuratedSetCount(data[0]?.pagination?.total);
        }
    }, [data, onCuratedSetCount]);

    const loadMore = useCallback(() => {
        if (isLoadingMore) return;
        setSize((prevSize) => prevSize + 1);
    }, [isLoadingMore, setSize]);

    const seeMorePath = useMemo(() => {
        const seeMore = `${createAppRoutes.search}?type=curated-sets`;

        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (!router.query['searchTerm']) {
            return seeMore;
        }

        // eslint-disable-next-line @typescript-eslint/dot-notation
        return `${seeMore}&searchTerm=${router.query['searchTerm']}`;
    }, [router.query]);

    const songs = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flatMediaList = songs ? [].concat(...(songs as any)) : [];

    const getIntroSection = useCallback(
        () => (
            <div className={styles['search-create-curated-sets__intro-section']}>
                <SecondaryPageTitle title="Curated Packs" counter={data[0]?.pagination?.total?.toString()} />
                {flatMediaList?.length === 0 && <div className="spacing--vertical"><NoResultsBlock hasPadding hasMargin>No Curated Packs Available</NoResultsBlock></div>}
            </div>
        ),
        [data, flatMediaList?.length]
    );

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoadingInitialData);
    }, [isLoadingInitialData, setIsLoadingData]);

    if (isLoadingData || isLoadingInitialData) {
        return (
            <GhostComponent type="cards" cardSize="small" title="Curated Sets" linesWidthArray={CURATED_SETS_LINES_WIDTH} lineHeight={10} elementsCount={10} />
        );
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <>
            {(flatMediaList?.length === 0 || (indexPage && !isMobile && flatMediaList?.length)) && getIntroSection()}
            {flatMediaList?.length > 0 ? (
                <div>
                    {indexPage && isMobile && (
                        <div className="spacing--horizontal">
                            <ListGrid>
                                {flatMediaList.map((curatedSet) => (
                                    <Fragment key={curatedSet.id}>
                                        <CreateListGridItem
                                            id={curatedSet.id}
                                            title={curatedSet.name}
                                            subtitle={curatedSet.genre}
                                            imageUrl={appendQueryParams(curatedSet.artwork_url, { key: 'dw', value: 160 })}
                                            imageUrl2x={appendQueryParams(curatedSet.artwork_url, { key: 'dw', value: 320 })}
                                            link={createAppRoutes.curatedSet(curatedSet.slug)}
                                            contentType="curated-set"
                                            secondPlayableDemo={curatedSet.demo_file_url_2}
                                        />
                                    </Fragment>
                                ))}
                            </ListGrid>
                        </div>
                    )}
                    {indexPage && !isMobile && (
                        <div className="spacing__window">
                            <CardGrid cardType="card-small">
                                {flatMediaList.map((curatedSet) => (
                                    <PackCardCreate item={curatedSet} items={flatMediaList} />
                                ))}
                            </CardGrid>
                        </div>
                    )}

                    {!indexPage && flatMediaList.length > 0 && (
                        <CreateCardCarousel
                            contentType="curated-set"
                            items={flatMediaList}
                            cardSize="small"
                            carouselTitle="Curated Packs"
                            carouselTitleCounter={data[0]?.pagination?.total?.toString()}
                            carouselMorePath={seeMorePath}
                        />
                    )}
                </div>
            ) : null}
            {indexPage && !isLastPage && !isLoadingMore ? (
                <div className={styles['search-create-curated-sets__see-more']}><SeeMore expand={false} variant="text" onClick={loadMore} /></div>
            ) : null}
        </>
    );
}

export default SearchCreateCuratedSets;
