import { useGetMeSubscriptionUnCached } from '@bpm-web-app/swr-hooks';
import { DeviceAuthorizationType, SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Device as DeviceHandler } from 'libs/api-client/src/lib/handlers/device';
import { DeviceManager, getCurrentPlatformLink, showToast } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { ActionModal } from '../action-modal/action-modal';
import { CardBar } from '../card-bar/card-bar';
import { useSession } from '../session-provider/session-provider';
import styles from './device-verification.module.css';

export function DeviceVerification({ platform }: { platform: 'supreme' | 'create' }) {
    const [deviceRegistered, setDeviceRegistered] = useState(false);

    useEffect(() => {
        // eslint-disable-next-line promise/catch-or-return
        DeviceManager.isReady.catch(() => null).then(() => {
            setDeviceRegistered(true);
            return null;
        });
    }, []);

    const { data, mutate } = useGetMeSubscriptionUnCached(platform, !deviceRegistered);
    const { logout } = useSession();
    const router = useRouter();

    const [showRequestVerify, setShowRequestVerify] = useState(false);
    const [showRequestVerifyLoading, setShowRequestVerifyLoading] = useState(false);
    const [showVerify, setShowVerify] = useState(false);
    const [showVerifyLoading, setShowVerifyLoading] = useState(false);
    const [showSetDeviceName, setShowDeviceName] = useState(false);

    // eslint-disable-next-line @typescript-eslint/dot-notation
    const didMountWithRenameParam = useRef(false);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        if (router.query['device_rename']) {
            didMountWithRenameParam.current = true;
        }
    }, [router.query]);

    const [limitReached, setLimitReached] = useState<'limit' | 'upgradeable' | undefined>(undefined);

    const activationCodeRef = useRef<HTMLInputElement>(null);
    const deviceNameRef = useRef<HTMLInputElement>(null);

    const ignoreAuthorization = useMemo(() => {
        return router.pathname.includes('account');
    }, [router]);

    useEffect(() => {
        if (ignoreAuthorization) {
            return;
        }
        if (showVerify || showRequestVerify || limitReached !== undefined) {
            return;
        }

        switch (data?.data?.device_authorization?.type) {
            case DeviceAuthorizationType.Verify:
                setShowRequestVerify(true);
                break;
            case DeviceAuthorizationType.LimitReached:
                setLimitReached('limit');
                break;
            case DeviceAuthorizationType.LimitReachedUpgradable:
                setLimitReached('upgradeable');
                break;
            case DeviceAuthorizationType.Ignore:
            case DeviceAuthorizationType.Authorized:
                if (didMountWithRenameParam.current) {
                    setShowDeviceName(true);
                    didMountWithRenameParam.current = false;
                }
                break;
            default:
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, didMountWithRenameParam.current, ignoreAuthorization]);

    const requestVerificationCode = () => {
        setShowRequestVerifyLoading(true);
        DeviceHandler.requestAuthCode(platform).then(() => {
            mutate();
            setShowVerify(true);
            setShowRequestVerify(false);
            setShowRequestVerifyLoading(false);
            return null;
        }).catch(() => {
            setShowRequestVerifyLoading(false);
            showToast({ type: 'error', message: 'Unavailable. Please try again later.' });
        });
    };

    const limitReachedtext = useMemo(() => {
        if (data?.data.membership.subscription?.membership_type === SubscriptionDetailsv4.MembershipTypeEnum.Standard) {
            return 'As a standard user, you can have two desktop devices saved to your account at a time. Please remove one of the devices below to start browsing.';
        }
        return 'As a premium user, you can have three desktop devices saved to your account at a time. Please remove one of the devices below to start browsing.';
    }, [data]);

    useEffect(() => {
        if (ignoreAuthorization === false) {
            mutate();
        }
    }, [ignoreAuthorization, mutate]);

    return (
        <>
            <ActionModal
                headerTitle="Device Limit Reached"
                title="Did you log in from a new device?"
                subtitle="It looks like you’re logging in from a browser or device that’s not verified in your account. If you would like to use this device, please verify it."
                confirmButtonText="Verify Device"
                cancelButtonText="Log Out"
                actionLoading={showRequestVerifyLoading}
                onConfirm={() => {
                    requestVerificationCode();
                }}
                onClose={() => {
                    logout();
                }}
                variant="light"
                isOpen={showRequestVerify}
            />
            <ActionModal
                headerTitle="Device Limit Reached"
                title="Did you log in from a new device?"
                subtitle="It looks like you’re logging in from a browser or device that’s not saved in your account.
                As a Standard user, you may save only two desktop devices at a time. If you would like to save this device,
                please authorize it or upgrade to a Premium subscription to save a third desktop device."
                confirmButtonText="Send Code"
                cancelButtonText="Upgrade to Premium"
                actionLoading={showRequestVerifyLoading}
                onConfirm={() => {
                    requestVerificationCode();
                    setLimitReached(undefined);
                }}
                onClose={(cancelled) => {
                    if (cancelled) {
                        router.push(getCurrentPlatformLink('/account/plan'));
                        setLimitReached(undefined);
                    } else {
                        logout();
                    }
                }}
                variant="light"
                isOpen={limitReached === 'upgradeable'}
            />
            {/* <ActionModal
                headerTitle="Register This Device"
                title="Device Registered!"
                subtitle="Thank you, this device has been registered to your account."
                confirmButtonText="Start Browsing"
                onConfirm={() => {
                    setShowSuccess(false)
                }}
                onClose={() => {
                    setShowSuccess(false)
                }}
                variant="dark"
                isOpen={showSuccess}
                hideCancel
            /> */}

            <ActionModal
                headerTitle="Device Limit Reached"
                title="Please Remove A Device"
                subtitle={limitReachedtext}
                hideCancel
                onClose={() => {
                    logout();
                }}
                variant="dark"
                childrenNoReplace
                isOpen={limitReached === 'limit'}
            >
                {data?.data?.device_authorization?.devices?.map((device) => {
                    return (
                        <CardBar
                            key={device.id}
                            textLeft={{
                                text: device.device_data_device_name || 'Unknown'
                            }}
                            textRight={{
                                text: 'Remove',
                                onClick: () => {
                                    DeviceHandler.deleteDeviceById(device.id, platform).then(() => {
                                        setLimitReached(undefined);
                                        showToast({ type: 'success', title: 'Device Removed Successfully' });
                                        mutate();
                                        return null;
                                    }).catch((err) => {
                                        showToast({ type: 'error', message: 'Unavailable. Please try again later.' });
                                    });
                                }
                            }}
                        />
                    );
                })}
                {data?.data.membership.subscription?.membership_type === SubscriptionDetailsv4.MembershipTypeEnum.Standard ? (
                    <div className={styles['device-verification__use-more']}>
                        Want to use more devices?&nbsp;
                        <button
                            type="button"
                            onClick={() => {
                                setLimitReached(undefined);
                                router.push(getCurrentPlatformLink('/account/plan'));
                            }}
                            className={styles['device-verification__use-more-link']}>Upgrade to Premium
                        </button>
                    </div>
                ) : null}
            </ActionModal>

            <ActionModal
                headerTitle="Verify Device"
                title="Name this device?"
                subtitle="This device has been successfully verified. Would you like to give this device a name?"
                confirmButtonText="Submit"
                hideCancel
                onConfirm={async () => {
                    if (!deviceNameRef.current?.value) {
                        setShowDeviceName(false);
                        return;
                    }
                    showToast({ type: 'success', title: 'Device verified' });
                    const fingerprint = await DeviceManager.getFingerprint();
                    DeviceHandler.updateDeviceName(fingerprint, deviceNameRef.current?.value).then(() => {
                        setShowDeviceName(false);
                        return null;
                    }).catch((err) => {
                        showToast({ type: 'error', message: 'Unavailable. Please try again later.' });
                    });
                }}
                onClose={() => {
                    setShowDeviceName(false);
                }}
                variant="dark"
                childrenNoReplace
                isOpen={showSetDeviceName}
                renderInput={() => {
                    return <input
                        ref={deviceNameRef}
                        type="text"
                        style={{ marginBottom: '24px' }}
                        placeholder="Device Name (Optional)"
                        className="generic__input" />;
                }}
            />
            <ActionModal
                headerTitle="Check Your Email"
                title="Verify This Device"
                subtitle={(
                    <span>We sent an activation code to {data?.data.user.email}. Please enter it below to verify this device.<br />
                        Don&apos;t have access to that email? <a className="underline-link" href="https://support.bpmsupreme.com/hc/en-us" target="__blank">Contact Support.</a>
                    </span>
                )}
                confirmButtonText="Submit"
                hideCancel
                actionLoading={showVerifyLoading}
                onConfirm={() => {
                    const activationCode = activationCodeRef.current?.value;
                    const deviceName = deviceNameRef.current?.value;
                    if (!activationCode || activationCode.length < 3) {
                        showToast({ type: 'error', message: 'Please enter the full activation code' });
                    } else {
                        setShowVerifyLoading(true);
                        DeviceHandler.verifyAuthCode(platform, activationCode, deviceName).then(() => {
                            setShowVerifyLoading(false);
                            setShowVerify(false);
                            mutate();
                            showToast({ type: 'success', title: 'Device verified' });
                            return null;
                        }).catch((err) => {
                            showToast({ type: 'error', message: 'The entered activation code is not correct' });
                            setShowVerifyLoading(false);
                        });
                    }
                }}
                onClose={() => {
                    logout();
                }}
                variant="dark"
                childrenNoReplace
                isOpen={showVerify}
                renderInput={() => {
                    return (
                        <>
                            <input ref={activationCodeRef} type="text" style={{ marginBottom: '14px' }} placeholder="Activation Code" className="generic__input" />
                            <input
                                ref={deviceNameRef}
                                type="text"
                                style={{ marginBottom: '24px' }}
                                placeholder="Device Name (Optional)"
                                className="generic__input" />
                        </>
                    );
                }}
            />
        </>
    );
}
