import classNames from 'classnames';
import { convertToPluralIfNeeded, downloadAlbumWithMediaToQueueItem, State, streamMediaWithAlbumToQueueItem, useHubSwitch, usePlayerState } from '@bpm-web-app/utils';
import { CuratedSet as DownloadCuratedSet, CuratedSetWithAlbum } from '@bpm-web-app/download-api-sdk';
import { useCallback } from 'react';
import { CuratedSets } from '@bpm-web-app/api-client';
import { CuratedSetWithMedia } from '@bpm-web-app/stream-api-sdk';
import styles from './predictive-search.module.css';
import { AppLink } from '../shared';
import { RowComponent } from '../row-component/row-component';
import { usePlayer } from '../player-context';

export interface CuratedSetProps {
    sets?: DownloadCuratedSet[];
    count?: number
}

export default function CuratedSet({ sets, count }: CuratedSetProps) {
    const { setQueue, originalListDetails, onPause, silenceWorkaround } = usePlayer();
    const { isDownload } = useHubSwitch();
    const playerState = usePlayerState();

    const isPlaying = useCallback((id: number) => {
        if (playerState !== State.Playing) {
            return false;
        }
        return originalListDetails?.identifier === `${id}` && originalListDetails?.resource === 'curated-set';
    }, [playerState, originalListDetails]);

    const handlePlaySong = useCallback(async (set: DownloadCuratedSet) => {
        try {
            silenceWorkaround();
            const { data } = await CuratedSets.getCuratedSet(isDownload, set.id);
            if (!data) return;

            const queueMedia = isDownload
                ? (data as CuratedSetWithAlbum).albums?.map((curatedSetAlbum) => downloadAlbumWithMediaToQueueItem(curatedSetAlbum))
                : (data as CuratedSetWithMedia).media?.map((curatedSetMedia) => streamMediaWithAlbumToQueueItem(curatedSetMedia));
            if (queueMedia) { setQueue(queueMedia, 0, { identifier: `${set.id}`, resource: 'curated-set' }); }
            // eslint-disable-next-line no-empty
        } catch (error) {

        }
    }, [isDownload, setQueue, silenceWorkaround]);

    return (
        <>
            <div className={styles['predictive-search__row-container']}>
                <h4 className={styles['predictive-search__title']}>{convertToPluralIfNeeded(count || 0, 'Curated Sets', 'Curated Sets', true)}</h4>
            </div>
            <div>
                {sets?.length === 0 ? (
                    <div className={styles['predictive-search__list']}><h5 className={classNames(styles['predictive-search__content-title'], styles['predictive-search__content-title-empty'])}>No items match your search</h5></div>
                ) : (
                    sets?.map((item) => (
                        <AppLink href={`/curated-set/${item.id}`} key={item.id}>
                            <RowComponent
                                onCoverClick={() => isPlaying(item.id) ? onPause() : (handlePlaySong(item))}
                                isPlaying={isPlaying(item.id)}
                                type="playlist"
                                id={item.id}
                                cover_url={item.image_url}
                                title={item.title}
                                artist={item.artist}
                                artists={item.artists}
                                hasHover
                            />
                        </AppLink>
                    ))
                )}
            </div>
        </>
    );
}
