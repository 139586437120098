import { FC, ReactNode, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

import { getSignupRedirectLink, useUserSettings } from '@bpm-web-app/utils';
import { NavContext } from '../nav/nav.context';
import Title from '../title/title';
import styles from './account.module.css';

export type AccountPageProps = {
    title: string;
};

type Props = AccountPageProps & {
    children: ReactNode
    wide?: boolean
    fluid?: boolean
};

export const Account: FC<Props> = ({ children, title, wide, fluid }) => {
    const { isAnonymous } = useUserSettings();
    const router = useRouter();
    const { showNav, hideNav } = useContext(NavContext);

    useEffect(() => {
        hideNav();

        return () => {
            showNav();
        };
    }, []);

    useEffect(() => {
        if (isAnonymous && !router.pathname.endsWith('/available-plans') && !router.pathname.endsWith('/plan')) {
            router.replace(getSignupRedirectLink(router.asPath));
        }
    }, [isAnonymous, router]);

    return (
        <div
            id="account-container"
            className={classNames(styles['account__layout'], {
                [styles['account__layout--wide']]: wide,
                [styles['account__layout--fluid']]: fluid
            })}
        >
            <Title platform="download" title={title} />
            {children}
        </div>
    );
};
