import { useGetCredits, useGetMeSubscription } from '@bpm-web-app/swr-hooks';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getCurrentPlatformLink, useUserSettings } from '@bpm-web-app/utils';
import dayjs from 'dayjs';
import { ActionModal } from '../action-modal/action-modal';

export function SubscriptionInfoModal({ showPriceIncreaseIfAvailable, isCreate }: { showPriceIncreaseIfAvailable?: boolean, isCreate?: boolean }) {
    const { data } = useGetMeSubscription(isCreate ? 'create' : 'supreme');
    const { data: credits } = useGetCredits(!isCreate);
    const { isAnonymous } = useUserSettings();
    const router = useRouter();

    const [showModal, setShowModal] = useState<'price-increase' | 'failed-payment' | 'create-lq-stream' | 'create-anonymous-lq-stream' | undefined>(undefined);
    const [navs, setNavs] = useState(0);

    useEffect(() => {
        if (showModal) {
            setNavs((n) => n + 1);
        }
    }, [showModal, router.pathname]);

    useEffect(() => {
        if (data?.data && data.data.membership.has_membership && showPriceIncreaseIfAvailable) {
            const { subscription } = data.data.membership;
            if (subscription?.show_price_increase_modal && !localStorage.getItem('has_shown_show_price_increase_modal')) {
                localStorage.setItem('has_shown_show_price_increase_modal', '1');
                setShowModal('price-increase');
            }
        } else if (data?.data && data?.data.membership.failed_payment_package_id && !router.pathname.includes('account')) {
            const lastShown = localStorage.getItem('last_shown_show_failed_payment_modal');
            if (!lastShown || dayjs(lastShown).isBefore(dayjs().subtract(24, 'hour'))) {
                localStorage.setItem('last_shown_show_failed_payment_modal', new Date().toJSON());
                setShowModal('failed-payment');
            }
        } else if (isCreate && data?.data.membership.has_membership && credits?.regular === 0) {
            const lastShown = localStorage.getItem('last_shown_create_lq_stream_modal');
            if (!lastShown || dayjs(lastShown).isBefore(dayjs().subtract(7, 'day'))) {
                localStorage.setItem('last_shown_create_lq_stream_modal', new Date().toJSON());
                setShowModal('create-lq-stream');
            }
        } else if (isCreate && data?.data.membership.has_membership && (credits?.regular || 0) > 0 && showModal === 'create-lq-stream') {
            setShowModal(undefined);
        } else if (!isAnonymous && isCreate && showModal === 'create-anonymous-lq-stream') {
            setShowModal(undefined);
        }
        /** else if (isAnonymous && isCreate) {
            const lastShown = localStorage.getItem('last_shown_anonymous_create_lq_stream_modal');
            if (!lastShown || dayjs(lastShown).isBefore(dayjs().subtract(24, 'hour'))) {
                localStorage.setItem('last_shown_anonymous_create_lq_stream_modal', new Date().toJSON());
                setShowModal('create-anonymous-lq-stream');
            }
        }  */
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showPriceIncreaseIfAvailable, data, credits, isAnonymous]);

    const didNavigate = navs > 2;

    return (
        <>
            <ActionModal
                title="Subscription Price Update"
                headerTitle="Important Notice"
                subtitle="Beginning on your next subscription renewal your subscription price will be moderately increased to support our commitment to providing top-quality music and enhancing your experience with innovative features."
                confirmButtonText="Confirm"
                cancelButtonText="Learn More"
                onConfirm={() => {
                    setShowModal(undefined);
                }}
                onClose={(cancelled) => {
                    if (cancelled) {
                        window.open('https://support.bpmsupreme.com/hc/en-us/articles/17192227013015--Subscription-Price-Update-and-New-Pricing-Tiers)', '__blank');
                    }
                    setShowModal(undefined);
                }}
                variant="light"
                isOpen={showModal === 'price-increase' && didNavigate}
            />
            <ActionModal
                title="Let’s Update Your Payment Info"
                headerTitle="Important Notice"
                subtitle={(
                    <div>
                        Your last payment didn’t go through. Get back to downloading DJ-ready music by updating your payment method below or switching to a free plan. You can also <a className="normal-link" href="https://support.bpmsupreme.com/hc/en-us/requests/new" target="__blank">contact our support team</a> for assistance. We’re here to help!
                    </div>
                )}
                confirmButtonText="Update Payment Method"
                cancelButtonText="Ignore"
                onConfirm={() => {
                    router.push(getCurrentPlatformLink(`account/plan/continue/${data?.data.membership.failed_payment_package_id}`));
                    setShowModal(undefined);
                }}
                onClose={() => {
                    setShowModal(undefined);
                }}
                variant="light"
                isOpen={showModal === 'failed-payment'}
            />
            <ActionModal
                title="Low-Quality Previews Enabled"
                headerTitle="Limited Preview Mode"
                subtitle={'We\'ve noticed you\'re out of credits, which has enabled low-quality sound previews. Elevate your experience and get back to HQ previews by adding more credits to your account.'}
                confirmButtonText="Add Credits"
                cancelButtonText="Dismiss"
                onConfirm={() => {
                    router.push(getCurrentPlatformLink('account/plan'));
                    setShowModal(undefined);
                }}
                onClose={() => {
                    setShowModal(undefined);
                }}
                variant="light"
                isOpen={showModal === 'create-lq-stream' && didNavigate}
            />
        </>
    );
}
