import { useRouter } from 'next/router';
import { getPlatformLinkUsingRouter } from '@bpm-web-app/utils';
import BPMIcons from '../bpm-icons/bpm-icons';
import styles from './message-banner.module.css';

interface MessageBannerProps {
    type: 'error' | 'alert' | 'success' | 'info';
    title: string;
    actionText?: string;
    viewPlanRoute?: boolean;
    onClick?: () => void;
}

export function MessageBanner({ type = 'info', title, actionText, viewPlanRoute, onClick }: MessageBannerProps) {
    const router = useRouter();

    const renderIcon = () => {
        switch (type) {
            case 'info':
                return <BPMIcons.InformationCircleIcon />;
            default:
                return null;
        }
    };

    const onViewPlans = () => {
        // eslint-disable-next-line @typescript-eslint/dot-notation
        router.push(getPlatformLinkUsingRouter(`/account/plan?library=${router.query['library']?.toString() || 'music'}`));
    };

    return (
        <div className={styles['message-banner']}>
            <div className={styles['message-banner__left-container']}>
                <div className={styles['message-banner__left-container--icon']}>
                    {renderIcon()}
                </div>
                <span className={styles['message-banner__left-container--title']}>{title}</span>
            </div>
            {viewPlanRoute ? (
                <button type="button" onClick={onViewPlans} className={styles['message-banner__cta']}>
                    {actionText || 'View Plans'}
                </button>
            ) : null}

            {onClick && !viewPlanRoute ? (
                <button type="button" onClick={onClick} className={styles['message-banner__cta']}>
                    {actionText || 'View'}
                </button>
            ) : null}
        </div>
    );
}
