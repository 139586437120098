import { useState } from 'react';
import { LocalSearchSort } from '../../nav-my-playlist-categories/utils';
import SortOptionsSheet, { SortOption } from '../../sort-options-sheet/sort-options-sheet';
import BPMIcons from '../bpm-icons/bpm-icons';
import PrimaryPageTitle from '../primary-page-title/primary-page-title';
import { SearchInput } from '../search-input/search-input';
import { ShareURL } from '../share-url/share-url';
import { SortButton } from '../sort-button/sort-button';
import styles from './page-header.module.css';

interface PageHeaderProps {
    title: string;
    handleAdd?: () => void;
    searchPlaceholder?: string;
    searchTerm?: string;
    setSearchTerm?: (text: string) => void;
    onSort?: (type: LocalSearchSort) => void;
    selectedSortType?: LocalSearchSort;
    sortOptions?: SortOption<LocalSearchSort>[];
    sharePage?: boolean;
}

export const LOCAL_SORT_OPTIONS: SortOption<LocalSearchSort>[] = [
    { actionType: 'most_recent', label: 'Most Recent' },
    { actionType: 'title_asc', label: 'Title (a-z)' },
    { actionType: 'title_desc', label: 'Title (z-a)' },
];

export function PageHeader({ title, handleAdd, searchPlaceholder, searchTerm, setSearchTerm, onSort, selectedSortType, sortOptions = LOCAL_SORT_OPTIONS, sharePage = false }: PageHeaderProps) {
    const [sortPosition, setSortPosition] = useState({ left: 0, top: 0 });
    const [sortVisible, setSortVisible] = useState(false);

    return (
        <div className={styles['page-header']}>
            <div className={styles['page-header--main-container']}>
                <PrimaryPageTitle title={title} noPadding />
                <div className={styles['page-header--buttons']}>
                    {handleAdd ?
                        (
                            <button type="button" onClick={handleAdd}>
                                <BPMIcons.PlusIcon />
                            </button>
                        ) : null}
                    {sharePage ? (<ShareURL currentUrl />) : null}
                    {onSort ?
                        (
                            <SortButton
                                setSortPosition={setSortPosition}
                                setSortVisible={setSortVisible} />
                        ) : null}
                </div>
            </div>
            {searchTerm !== undefined && setSearchTerm ?
                (
                    <div className={styles['page-header--search']}>
                        <SearchInput
                            placeholder={searchPlaceholder || 'Search'}
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                            onClear={() => setSearchTerm('')}
                        />
                    </div>
                ) : null}
            <SortOptionsSheet
                leftPosition={sortPosition.left}
                topPosition={sortPosition.top}
                selectedAction={selectedSortType}
                onAction={(actionType) => {
                    setSortVisible(false);
                    onSort(actionType);
                }}
                visible={sortVisible}
                setVisible={setSortVisible}
                options={sortOptions}
            />
        </div>
    );
}
