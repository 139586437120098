import { Media } from '@bpm-web-app/api-client';
import { ArtistWithAlbumCount, Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { ArtistWithMediaCount, MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { convertToPluralIfNeeded, downloadAlbumWithMediaToQueueItem, State, streamMediaWithAlbumToQueueItem, useHubSwitch, usePlayerState } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { LibraryTabsContext } from 'libs/utils/src/lib/library-tabs.context';
import { useCallback, useContext } from 'react';
import { usePlayer } from '../player-context';
import { AppLink } from '../shared';
import styles from './predictive-search.module.css';
import { RowComponent } from '../row-component/row-component';

export interface ArtistsProps {
    artists?: ArtistWithMediaCount[] | ArtistWithAlbumCount[];
    count?: number
}

export default function Artists({ artists, count }: ArtistsProps) {
    const { setQueue, originalListDetails, onPause, silenceWorkaround } = usePlayer();
    const { isDownload } = useHubSwitch();
    const playerState = usePlayerState();
    const { libraryProperty } = useContext(LibraryTabsContext);

    const isPlaying = useCallback((artist: ArtistWithMediaCount | ArtistWithAlbumCount) => {
        if (playerState !== State.Playing) {
            return false;
        }
        return originalListDetails?.identifier === artist.slug && originalListDetails?.resource === 'artist';
    }, [playerState, originalListDetails]);

    const handlePlaySong = useCallback(async (artist: ArtistWithMediaCount | ArtistWithAlbumCount) => {
        try {
            silenceWorkaround();
            const { data } = isDownload ? await Media.searchAlbum(true, {
                artist: artist.slug,
                library: libraryProperty,
                limit: 20
            }) : await Media.searchMedia({
                artist: artist.slug,
                library: libraryProperty,
                limit: 20
            });
            if (!data) return;
            const queueMedia = isDownload
                ? (data as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album))
                : (data as MediaWithAlbum[]).map((media) => streamMediaWithAlbumToQueueItem(media));

            setQueue(queueMedia, 0, { identifier: artist.slug, resource: 'artist' });
            // eslint-disable-next-line no-empty
        } catch (error: any) {
        }
    }, [isDownload, libraryProperty, setQueue, silenceWorkaround]);

    return (
        <>
            <div className={styles['predictive-search__row-container']}>
                <h4 className={styles['predictive-search__title']}>{convertToPluralIfNeeded(count || 0, 'Artists', 'Artists', true)}</h4>
            </div>
            <div>
                {artists?.length === 0 ? (
                    <div className={styles['predictive-search__list']}>
                        <h5 className={classNames(styles['predictive-search__content-title'], styles['predictive-search__content-title-empty'])}>No items match your search</h5>
                    </div>
                ) : (
                    artists?.map((item) => (
                        <AppLink href={`/artist/${item.slug}`} key={item.id}>
                            <RowComponent
                                type="artist"
                                id={item.id}
                                onCoverClick={() => {
                                    if (isPlaying(item)) {
                                        onPause();
                                    } else {
                                        handlePlaySong(item);
                                    }
                                }}
                                cover_url={item.artwork_url}
                                title={item.name}
                                mediaCount={
                                    isDownload
                                        ? (item as ArtistWithAlbumCount).album_count
                                        : (item as ArtistWithMediaCount).media_count
                                }
                                hasHover
                            />
                        </AppLink>
                    ))
                )}
            </div>
        </>
    );
}
