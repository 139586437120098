import { CreateCardCarousel } from '@bpm-web-app/components';
import { PrimaryPageTitle, Banner, GhostComponent } from '@bpm-web-app/components/shared';
import { useGetSections } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import { useMemo, useState } from 'react';

export function CuratedPacks() {
    const { data: sectionsData, isLoading } = useGetSections('curated');

    const [isLoadedBanners, setIsLoadedBanners] = useState(false);

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoading &&
            isLoadedBanners
        ) return true;
        return false;
    }, [isLoadedBanners, isLoading]);

    const ghostLoading = () => {
        return (
            <>
                <div className="spacing--top" />
                <div className="spacing--top" />
                {[1, 2, 3].map(() => {
                    return (
                        <>
                            <GhostComponent type="secondary-title" />
                            <GhostComponent type="cards" elementsCount={10} cardSize="small" linesWidthArray={[80]} />
                        </>
                    );
                })}

            </>
        );
    };

    return (
        <div>
            <PrimaryPageTitle title="Curated Packs" />
            <Banner variant="hidden" platform="create" location="curated" isDataLoaded={isEverythingLoaded} setIsDataLoaded={setIsLoadedBanners} />
            {isEverythingLoaded ? (
                sectionsData?.data?.map((section) => (
                    <CreateCardCarousel
                        contentType="curated-set"
                        key={section.id}
                        items={section.items}
                        cardSize="small"
                        carouselTitle={section.name}
                        carouselMorePath={createAppRoutes.section(section.name, 'curated', 'curated')}
                    />
                ))
            ) : ghostLoading()}
        </div>
    );
}
