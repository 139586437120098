import { useSearchPlaylistSets } from '@bpm-web-app/swr-hooks';
import { useSupremeFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { SecondaryPageTitle, CardCarousel, NoResultsBlock, CardGrid, GhostComponent } from '../shared';
import { MusicViewProps } from './music-view-props';
import { PlaylistCard } from '../shared/card/playlist-card/playlist-card';

export function PlaylistMusicView(playlistMusicProps: MusicViewProps) {
    const router = useRouter();

    const { title, seeMorePath, query, type, variant = 'component', isLoading, setIsLoading } = playlistMusicProps;
    const playlistsQuery = useSupremeFilterParams(false, query);
    const { data: playlists, isLoading: isLoadingPlaylist } = useSearchPlaylistSets(playlistsQuery);

    const handleSeeMorePath = useMemo(
        () => seeMorePath || {
            pathname: router.pathname,
            query: {
                ...router.query,
                type,
            },
        },
        [seeMorePath, router.pathname, router.query, type]
    );

    const renderCards = () => {
        return (
            playlists?.data.map((playlist) => (
                <PlaylistCard
                    key={playlist.id}
                    playlist={playlist}
                    playlists={playlists?.data}
                />
            ))
        );
    };

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingPlaylist);
        }
    }, [isLoadingPlaylist, setIsLoading]);

    const isLoadingAnything = useMemo(() => {
        return isLoading || isLoadingPlaylist;
    }, [isLoading, isLoadingPlaylist]);

    if (isLoadingAnything) {
        return (<GhostComponent type="cards" cardSize="small" grid={variant !== 'component'} elementsCount={10} title="Playlists" />);
    }

    return (
        <>
            {(!isLoadingPlaylist && playlists?.data.length !== 0) && title && variant === 'fullscreen' ? (<div className="spacing--bottom"><SecondaryPageTitle title={title} noPadding /></div>) : null}

            {playlists && playlists.data.length !== 0 ? (
                variant === 'component'
                    ? <CardCarousel title={title} seeMorePath={variant === 'component' ? handleSeeMorePath : undefined}>{renderCards()}</CardCarousel>
                    : <CardGrid cardType="card-small">{renderCards()}</CardGrid>
            ) : null}
            {playlists && playlists.data.length === 0 ? (
                <div className={classNames('spacing--top', 'spacing--bottom')}>
                    <div className="spacing--bottom"><SecondaryPageTitle title={title || 'Playlists'} /></div>
                    <NoResultsBlock hasMargin>{title ? `No ${title} Available` : 'Not Available'}</NoResultsBlock>
                </div>
            ) : null}

        </>
    );
}
