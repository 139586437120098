import { useContext, useEffect } from 'react';
import classNames from 'classnames';
import { useSession } from '../session-provider/session-provider';
import { QualityControlContext } from './quality-control.context';
import styles from './quality-control.module.css';

export function QualityControlToggle() {
    const { user } = useSession();
    const { isQualityControlActive, qualityControlIsAvailable, setIsQualityControlActive, setQualityControlIsAvailable } = useContext(QualityControlContext);

    useEffect(() => {
        if (user?.is_admin && user.admin_features && user.admin_features.features && (user.admin_features.features.indexOf('qc') || user.admin_features.features.indexOf('all'))) {
            setQualityControlIsAvailable(true);
        } else {
            setQualityControlIsAvailable(false);
        }
    }, [setQualityControlIsAvailable, user?.admin_features, user?.is_admin]);

    if (!qualityControlIsAvailable) return null;

    return (
        <button type="button" onClick={() => setIsQualityControlActive(!isQualityControlActive)} className={styles['quality-control']}>
            <div className={styles['quality-control__slider-container']}>
                <div className={classNames(styles['quality-control__slider'], isQualityControlActive ? styles['quality-control__slider--active'] : '')} />
            </div>
            <span className={classNames(styles['quality-control__text'], isQualityControlActive ? styles['quality-control__text--active'] : '')}>
                Edit Mode
            </span>
            <span className={classNames(styles['quality-control__text'], !isQualityControlActive ? styles['quality-control__text--active'] : '')}>
                View Mode
            </span>
        </button>
    );
}
