import { useTagsFromCategory } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useMemo, useState } from 'react';
import CategoriesGrid, { GridElement } from '../../categories-grid/categories-grid';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import styles from './instruments.module.css';

type Instrument = {
    [key: string]: GridElement[];
};

export function Instruments() {
    const { tags, isLoading } = useTagsFromCategory('instruments');
    const [searchQuery, setSearchQuery] = useState<string>('');

    const categories = useMemo(() => {
        const newCategories: Instrument = {};

        if (tags) {
            tags.forEach((tag) => {
                if (tag.TagGroup) {
                    if (searchQuery.length === 0 || tag.name.toLowerCase().includes(searchQuery.toLowerCase())) {
                        const nextTag = {
                            id: tag.id,
                            name: tag.name,
                            href: createAppRoutes.instrumentsSlugWithTag(tag.TagGroup.name, tag.name),
                        };

                        if (newCategories[tag.TagGroup.name]) {
                            newCategories[tag.TagGroup.name].push(nextTag);
                        } else {
                            newCategories[tag.TagGroup.name] = [nextTag];
                        }
                    }
                }
            });
        }
        return newCategories;
    }, [searchQuery, tags]);

    if (isLoading || !tags) return null;

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <section className="spacing__window">
            <div className={styles['instruments__header']}>
                <PrimaryPageTitle title="Instruments" noPadding />
                {/* TODO: consider replacing this with <SearchInput> component */}
                <div
                    className={classNames(styles['instruments__search'], {
                        [styles['instruments__search--has-value']]: !!searchQuery,
                    })}
                >
                    <input type="text" onChange={(e) => setSearchQuery(e.target.value)} placeholder="Search Instruments" value={searchQuery} className={styles['instruments__search-input']} />
                    {!!searchQuery && (
                        <button type="button" onClick={() => setSearchQuery('')} className={styles['instruments__search-clear']}>
                            <i className="icon icon--controls-clear" />
                        </button>
                    )}
                </div>
            </div>
            {Object.keys(categories).map((category) => (
                <CategoriesGrid key={category} showMore title={category} mainHref={createAppRoutes.instrumentsSlug(category)} elements={categories[category]} />
            ))}
        </section>
    );
}

export default Instruments;
