import { appendQueryParams } from '@bpm-web-app/utils';
import styles from './prize-card.module.css';

export interface PrizeCardProps {
    image: string;
    title: string;
    description: string;
    id: string;
    link: string;
}

export function PrizeCard({ image, title, description, link }: PrizeCardProps) {
    return (
        <a href={link} target="_blank" rel="nofollow noreferrer" className={styles['prize-card']}>
            <div className={styles['prize-card__image-wrapper']}>
                <img
                    src={appendQueryParams(image, { key: 'dw', value: 264 })}
                    srcSet={`${appendQueryParams(image, { key: 'dw', value: 528 })} 2x`}
                    alt={title}
                    className={styles['prize-card__image']}
                />
            </div>
            <div className={styles['prize-card__title']}>{title}</div>
            <div className={styles['prize-card__description']} dangerouslySetInnerHTML={{ __html: description }} />
        </a>
    );
}

export default PrizeCard;
