import { useGetFeaturedSoundPackages, useInfiniteSearchSound, useTags, useTrending } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, DEFAULT_BROWSE_TRENDING_LIMIT, getMutatedSounds, SoundsResults, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { TrendingByPeriodQuery } from '@bpm-web-app/api-client';
import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import classNames from 'classnames';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import styles from './midi.module.css';
import { TagsView } from '../../tags-view/tags-view';
import { Divider } from '../../divider/divider';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import { FiltersContext } from '../../filters/filters.context';
import { AppLink } from '../../shared/app-link/app-link';
import Trending, { ITrendingItem } from '../../trending/trending';
import { GhostComponent } from '../../shared';
import SeeMore from '../../shared/see-more-button/see-more-btn';

export function Midi() {
    const router = useRouter();
    const { tags, setTags } = useContext(FiltersContext);
    const { pageTagFilter } = router.query;
    const actualPageTagFilter = useMemo(() => pageTagFilter === undefined ? [] : Array.isArray(pageTagFilter) ? pageTagFilter : [pageTagFilter], [pageTagFilter]);
    const query = useCreateFilterParams({ type: 'midi', limit: 10 });

    const { data: soundsData, mutate, isLoadingInitialData: isLoadingInitialSoundsData } = useInfiniteSearchSound(query);
    const { tags: possibleTags, isLoading: isLoadingTags } = useTags({ ...query, limit: undefined });
    const { data: featuredSoundsData, isLoading: isLoadingFeaturedSounds } = useGetFeaturedSoundPackages({ limit: 20, type: 'midi', tags: actualPageTagFilter });
    const { data: trendingData, isLoading } = useTrending('create', { limit: DEFAULT_BROWSE_TRENDING_LIMIT, skip: 0, type: 'midi', tags: actualPageTagFilter } as TrendingByPeriodQuery);

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoadingInitialSoundsData &&
            !isLoadingTags &&
            !isLoadingFeaturedSounds &&
            !isLoading
        ) return true;
        return false;
    }, [isLoading, isLoadingFeaturedSounds, isLoadingInitialSoundsData, isLoadingTags]);

    const soundPackages = useMemo(() => (trendingData?.data || []) as SoundPackage[], [trendingData?.data]);

    const trending = useMemo(
        () =>
            soundPackages.map(
                (pack): ITrendingItem => ({
                    artist: pack.artist,
                    cover_url: pack.artwork_url,
                    trending_status: 'trending_status' in pack ? (pack as any).trending_status : 0,
                    id: pack.id,
                    title: pack.name,
                    slug: pack.slug,
                    genre: pack?.Genre,
                    tracking_id: pack.id
                })
            ),
        [soundPackages]
    );

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData as SoundsResults[], id, progress));
        },
        [mutate, soundsData]
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const midiSounds = useMemo(() => soundsData?.flatMap((data) => data!.data), [soundsData]);

    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div>
            {isEverythingLoaded ? (
                <div className="spacing__window--horizontal">
                    <div className={classNames(styles['midi__header'], 'spacing__window--top')}>
                        <PrimaryPageTitle title="MIDI Files" noPadding tooltip="MIDI files give you pre-written arrangements of notes that you can assign to virtual instruments for quick inspiration." />
                    </div>
                    <TagsView
                        className={styles['midi__tag-view']}
                        large
                        tags={['Chords', 'Melody', 'Bass Line', 'Lead', 'Arp', 'Pads', 'Riff', 'Solo']}
                        selected={actualPageTagFilter}
                        onToggleTag={(tag, on) => {
                            if (on) {
                                const currentFilter = actualPageTagFilter;
                                currentFilter.push(tag);
                                router.replace({
                                    query: { ...router.query, pageTagFilter: currentFilter }
                                    // eslint-disable-next-line promise/always-return
                                }).then(() => {
                                    setTags([...tags, tag]);
                                }).catch(() => {

                                });
                            } else {
                                const currentFilter = actualPageTagFilter.filter((t) => t !== tag);
                                router.replace({
                                    query: { ...router.query, pageTagFilter: currentFilter }
                                    // eslint-disable-next-line promise/always-return
                                }).then(() => {
                                    setTags(tags.filter((t) => t !== tag));
                                }).catch(() => {

                                });
                            }
                        }} />
                </div>
            ) : (
                <>
                    <PrimaryPageTitle title="MIDI Files" tooltip="MIDI files give you pre-written arrangements of notes that you can assign to virtual instruments for quick inspiration." />
                    <div className="spacing--top" />
                    <GhostComponent type="tags" largeTags key="tags-view-ghost" />
                    <div className="spacing--top" />
                </>
            )}
            <Divider />
            <CreateCardCarousel
                contentType="pack"
                items={featuredSoundsData?.data || []}
                cardSize="small"
                carouselMorePath={{ pathname: createAppRoutes.featured, query: { fileType: 'midi', tags: actualPageTagFilter } }}
                isLoading={!isEverythingLoaded}
                carouselTitle="Featured Packs with MIDI"
            />
            <div className="spacing__window--horizontal">
                <TrackListCreate
                    mutateSound={mutateSound}
                    isLoading={!isEverythingLoaded}
                    list={midiSounds}
                    title="MIDI Samples"
                    showTagFilter
                    tags={possibleTagsString}
                    selectedTags={tags}
                    onToggleTag={(tag, on) => {
                        if (on) {
                            setTags([...tags, tag]);
                        } else {
                            setTags(tags.filter((t) => t !== tag));
                        }
                    }} />
                <AppLink href={{ pathname: createAppRoutes.midiSeeMore, query: router.query }}>
                    <SeeMore expand={false} variant="text" />
                </AppLink>
            </div>
            <Trending
                trending={trending}
                isLoading={!isEverythingLoaded}
                platform="create"
                className={styles['midi__trending']}
                seeMorePath={{ pathname: createAppRoutes.trending, query: { fileType: 'midi', tags: actualPageTagFilter } }}
            />
        </div>
    );
}

export default Midi;
