import useSWR from 'swr';
import { Meta, Platform } from '@bpm-web-app/api-client';

export type BannerResponse = Awaited<ReturnType<typeof Meta.getBanners>>;

export function useBanner(platform: Platform, bannerLocation = 'browse', library?: string) {
    const { data, error } = useSWR<BannerResponse>(['get-banners', {
        persist: true, platform, bannerLocation, library
    }], () => Meta.getBanners(platform, bannerLocation, library), {
        onError: () => {

        }
    });

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
