import { Analytics, createAppRoutes, fileDownload, getInitialsFromName, showToast, useApiErrorHandler, useDownloadPreview, useViewport } from '@bpm-web-app/utils';
import { Drive } from '@bpm-web-app/create-api-sdk';
import { useCallback, useMemo, useState } from 'react';
import { useDownloadDrive, useDownloadDrivePreview, useGetCredits, useUpdateDriveName } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { CardCreate } from '../card-create';
import PlaylistsForm from '../../../playlists-form/playlists-form';
import { CardDescriptionCreate } from '../card-description-create/card-description-create';

export interface DriveCardCreateProps {
    item?: Drive;
    items?: Drive[];
    isSharedDriveCard?: boolean;
    shouldMutate?: () => void;
    hideMoreOptions?: boolean;
}

export function DriveCardCreate({
    item,
    items,
    isSharedDriveCard,
    shouldMutate,
    hideMoreOptions
}: DriveCardCreateProps) {
    const { isMobile } = useViewport();
    const updateDriveName = useUpdateDriveName();
    const errorHandler = useApiErrorHandler();
    const downloadDrive = useDownloadDrive();
    const downloadDrivePreview = useDownloadDrivePreview();
    const router = useRouter();
    const { mutate: refreshCredits } = useGetCredits();

    const {
        closeDownloadModal,
        setShowDownloadPreviewModal,
    } = useDownloadPreview();

    const handleDownloadUrl = useCallback(
        (url: string) => {
            fileDownload(url);
            refreshCredits();
        },
        [refreshCredits]
    );

    const handleDownloadDrive = useCallback(async () => {
        closeDownloadModal();
        if (item) {
            try {
                const response = await downloadDrive(item.id, router.asPath);
                if (response && response.data && response.data.url) handleDownloadUrl(response.data.url);
                if (item.id) {
                    Analytics.trackClick('download_drive', item?.id || '', { location: 'drive-card' });
                }
            } catch (err) {
                errorHandler({ error: err });
            }
        }
    }, [closeDownloadModal, downloadDrive, errorHandler, handleDownloadUrl, item, router.asPath]);

    const onDownload = useCallback(async () => {
        await showToast({
            promise: handleDownloadDrive(),
            message: 'Download in progress.',
            successText: 'Download successful.',
            preventErrorToast: true
        });
    }, [handleDownloadDrive]);

    const handleDownloadPreview = useCallback(async () => {
        if (item) {
            try {
                const response = await downloadDrivePreview(item.id, router.asPath);
                const creditsRequired = response?.data?.required?.total;
                let subtitle = '';

                if (creditsRequired && creditsRequired > 0) {
                    if (item.sound_count && item.sound_count === 1) subtitle = 'this file';
                    else if (item.sound_count && item.sound_count > 1) subtitle = 'these files';
                    setShowDownloadPreviewModal({
                        subtitle,
                        amount: creditsRequired,
                        onCancel: () => closeDownloadModal,
                        onConfirm: () => onDownload
                    });
                } else {
                    onDownload();
                }
            } catch (err) {
                errorHandler({ error: err });
            }
        }
    }, [item, downloadDrivePreview, router.asPath, setShowDownloadPreviewModal, closeDownloadModal, onDownload, errorHandler]);

    const [showEditDriveForm, setShowEditDriveForm] = useState<boolean>(false);

    const handleEditDriveName = useCallback(
        async (name: string) => {
            try {
                const response = await updateDriveName(item?.id || '', { name });
                showToast({ type: 'success', message: 'Renamed successfully.', buttonText: 'Go To Drive', replaceRouteOnButtonClick: `/my-drive/${response.data.id}` });
            } catch (error) {
                errorHandler({ error, message: 'Failed to rename drive folder' });
            }
            if (shouldMutate) shouldMutate();
        },
        [errorHandler, item?.id, shouldMutate, updateDriveName]
    );

    const EditDriveForm = useMemo(
        () => (
            <PlaylistsForm
                type="EditDriveName"
                text={`Enter a new name for ${item?.name} below.`}
                formAction={handleEditDriveName}
                close={() => setShowEditDriveForm(false)}
            />
        ),
        [item?.name, handleEditDriveName]
    );

    const packDescription = useMemo(() => {
        return item ? (
            <CardDescriptionCreate
                soundsCount={item.sound_count || 0}
                presetsCount={item.preset_count}
                midiCount={item.midi_count}
            />
        ) : null;
    }, [item]);

    if (isSharedDriveCard) {
        return (
            <CardCreate
                contentType="drive"
                title="Shared With Me"
                id="shared-playlist-drive"
                cardSize="user-playlist"
                link={createAppRoutes.myDriveId('shared')}
                imageUrl=""
                description={isMobile ? undefined : packDescription}
                hideMoreOptions
            />
        );
    }

    return (
        <>
            {showEditDriveForm && EditDriveForm}
            <CardCreate
                contentType="drive"
                imageUrl={item?.custom_image_url || item?.image_url || ''}
                title={item?.name || ''}
                description={isMobile ? undefined : packDescription}
                id={item?.id || ''}
                cardSize="small"
                userPlaylistConfig={{
                    initials: getInitialsFromName(item?.name || ''),
                    imageBgColor: item?.image_color || '#FFFFFF',
                }}
                link={createAppRoutes.myDriveId(item?.id || '')}
                onEdit={() => setShowEditDriveForm(true)}
                onDownload={handleDownloadPreview}
                hideMoreOptions={hideMoreOptions}
            />
        </>
    );
}
