import { getDownloadedSounds, useGetDownloadedSoundPackages } from '@bpm-web-app/swr-hooks';
import { DEFAULT_SOUNDS_LIMIT, convertSoundPackageToCarouselProps, createAppRoutes, getLoginRedirectLink, useUserSettings } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { ComponentProps, useEffect, useMemo, useState } from 'react';
import { NoResults } from '../no-results/no-results';
import { DownloadsList } from '../list/list';
import { BreakpointView, HorizontalTabs } from '../../../shared';
import { CreateCardCarousel } from '../../../create-card-carousel/create-card-carousel';
import { PackItemList } from '../../../pack-item-list/pack-item-list';

interface DownloadsProps {
    searchQuery: string;
}

export function DownloadsCreate({ searchQuery }: DownloadsProps) {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(0);

    const { isAnonymous } = useUserSettings();
    const router = useRouter();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getLoginRedirectLink());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const { data: soundPackages, isLoading } = useGetDownloadedSoundPackages(
        {
            limit: DEFAULT_SOUNDS_LIMIT,
        },
        true
    );

    const soundPackCarouselData = useMemo(() => soundPackages?.data.map((sound) => convertSoundPackageToCarouselProps(sound)), [soundPackages]);

    const { data: dataCreate } = getDownloadedSounds(
        {
            limit: pageSize,
            skip: currentPage * pageSize,
            search: searchQuery,
        },
        true
    );

    const onPageChange = (page: number) => setCurrentPage(page);

    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: soundPackages?.data?.length ?? 0,
                activeTabComponent: <PackItemList data={soundPackages?.data} />,
            },
            {
                id: 'Songs',
                title: 'Songs',
                count: dataCreate?.data?.length ?? 0,
                activeTabComponent: <DownloadsList
                    isCreate
                    pageSize={pageSize}
                    onPageChange={onPageChange}
                    data={dataCreate}
                    activePage={currentPage}
                />,
            },
        ],
        [currentPage, dataCreate, soundPackages?.data]
    );

    if (!dataCreate || !dataCreate.data) {
        return <NoResults text={searchQuery ? 'No downloads found' : 'No Downloads yet'} />;
    }

    return (
        <div>

            <BreakpointView
                desktopChildren={
                    <>
                        <CreateCardCarousel
                            contentType="pack"
                            items={soundPackages?.data || []}
                            cardSize="small"
                            carouselMorePath={createAppRoutes.accountDownloadPacks}
                            isLoading={isLoading}
                            carouselTitle="Packs"
                            carouselTitleCounter={`${soundPackCarouselData?.length}`}
                            noPadding
                        />
                        <DownloadsList
                            isCreate
                            pageSize={pageSize}
                            onPageChange={onPageChange}
                            data={dataCreate}
                            activePage={currentPage}
                        />
                    </>
                }
                mobileChildren={<HorizontalTabs tabs={mobileTabs} />}
            />

        </div>
    );
}
