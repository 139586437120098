import styles from './tabs-input.module.css';

interface TabsInputProps {
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    options: { value: string; label: string }[];
    name: string;
    initialValue: string;
}

interface OptionProps {
    label: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    name: string;
    value: string;
    defaultChecked: boolean
}

export function Option({ label, onChange, name, value, defaultChecked }: OptionProps) {
    const id = label.replace(/\s/g, '-');
    return (
        <>
            <input
                id={id}
                className={styles['tabs-input__input']}
                type="radio"
                value={value}
                onChange={onChange}
                name={name}
                defaultChecked={defaultChecked}
            />
            <label htmlFor={id} className={styles['tabs-input__option-label']}>
                {label}
            </label>
        </>
    );
}

export function TabsInput({ label, name, onChange, options, initialValue }: TabsInputProps) {
    return (
        <div className={styles['tabs-input']}>
            <p className={styles['tabs-input__label']}>{label}</p>
            <div className={styles['tabs-input__options-container']}>
                {options.map((option) => (
                    <Option
                        name={name}
                        key={option.value}
                        label={option.label}
                        value={option.value}
                        onChange={onChange}
                        defaultChecked={initialValue === option.value} />
                ))}
            </div>
        </div>
    );
}
