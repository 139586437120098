import { useRouter } from 'next/router';
import { useInfiniteSearchPack } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import { CreateSearchPaginatedQuery } from '@bpm-web-app/api-client';
import { SeeMoreLayout } from '../../see-more-layout/see-more-layout';
import { LoadMore } from '../../shared/load-more/load-more';
import Title from '../../title/title';

type PacksQuery = Pick<CreateSearchPaginatedQuery, 'tag_groups' | 'tags' | 'limit'>;

export function InstrumentsPacksMore() {
    const router = useRouter();
    // eslint-disable-next-line max-len
    const { slug, tag, tags } = router.query as { slug: string; tag?: string;[key: string]: string | string[] };

    const query = useCreateFilterParams({});

    const pageQuery = useMemo<PacksQuery>(() => {
        const queryData: PacksQuery = {
            tag_groups: [slug],
            tags: [],
        };

        if (tags) {
            queryData.tags = tags as string[];
        } else if (tag) {
            queryData.tags.push(tag);
        }

        if (tag) {
            delete queryData.tag_groups;
        }

        return queryData;
    }, [tags, slug, tag]);

    const title = useMemo(() => (tag ? decodeURIComponent(tag) : slug || ''), [slug, tag]);

    const { data = [], isLoadingInitialData, isLoadingMore, setSize, isLastPage } = useInfiniteSearchPack({ ...query, ...pageQuery });

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
    const seeMoreData = useMemo(() => data?.flatMap((item) => item?.data!), [data]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore || !isLastPage || !isLoadingInitialData) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingInitialData, isLoadingMore, setSize]);

    if (!seeMoreData.length) {
        return null;
    }

    return (
        <>
            <Title platform="create" title={title} />
            <SeeMoreLayout contentType="pack" pageTitle={(tag as string) ?? (slug as string)} count={data?.[0]?.pagination?.total ?? 0} itemBasePath={createAppRoutes.packs} data={seeMoreData} />
            <LoadMore onLoadMore={handleLoadMore} />
        </>
    );
}
