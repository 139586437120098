import styles from './search-item.module.css';
import searchStyles from '../predictive-search.module.css';

interface SearchItemProps {
    icon: React.ReactElement;
    text: string;
    onClick: () => void;
    onRemove?: () => void;
}

export function SearchItem({ icon, text, onClick, onRemove }: SearchItemProps) {
    return (
        <div className={styles['search-item']}>
            <div className={styles['search-item__container']}>
                <button className={styles['search-item__left-container']} type="button" onClick={onClick}>
                    {icon}
                    <div className={searchStyles['predictive-search__content-title']}>{text}</div>
                </button>
                <div className={styles['search-item__right-container']}>
                    {onRemove ? <button type="button" className={searchStyles['predictive-search__thin-text']} onClick={() => onRemove()}>Remove</button> : null}
                </div>
            </div>
        </div>
    );
}
