import { getCurrentPlatformLink, showToast } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

export function PaypalRedirect() {
    const router = useRouter();
    const { status, payment } = router.query;
    useEffect(() => {
        if (status === 'success') {
            if (payment) {
                router.push({
                    pathname: getCurrentPlatformLink('/account/overview'),
                    query: router.query,
                });
            } else {
                router.push({
                    pathname: getCurrentPlatformLink('/account/plan'),
                    query: router.query,
                });
            }
        }
        if (status === 'failure') {
            if (payment) {
                router.push(getCurrentPlatformLink('/account/payment'));
            } else {
                router.push(getCurrentPlatformLink('/account/plan'));
            }
            showToast({ type: 'error', message: 'An error has occurred with paypal. Please try again.' });
        }
    }, [status, router, payment]);

    return <div />;
}

export default PaypalRedirect;
