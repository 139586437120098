import { TrendingByPeriodQuery, DownloadAlbum, Media, Platform, SoundPackage, CreateSearchPaginatedQuery } from '@bpm-web-app/api-client';

import useSWR from 'swr';

export type StreamTrendingResponse = Awaited<ReturnType<typeof Media.getTrendingMedia>>;

export type DownloadTrendingResponse = Awaited<ReturnType<typeof DownloadAlbum.getTrendingAlbumPeriod>>;

export type CreateTrendingResponse = Awaited<ReturnType<typeof SoundPackage.getTrendingSoundPackages>>;

export type TrendingResponse = StreamTrendingResponse | DownloadTrendingResponse | CreateTrendingResponse | null;

export function useTrending(platform: Platform, query?: TrendingByPeriodQuery | CreateSearchPaginatedQuery) {
    const queryParams = query || {};

    const { data, error, mutate } = useSWR<TrendingResponse>(
        [
            'search-trending',
            {
                persist: true,
                platform,
                ...queryParams,
            },
        ],
        () => {
            switch (platform) {
                case 'download':
                    return DownloadAlbum.getTrendingAlbumPeriod(queryParams as TrendingByPeriodQuery);
                case 'stream':
                    return Media.getTrendingMedia(queryParams as TrendingByPeriodQuery);
                case 'create':
                    return SoundPackage.getTrendingSoundPackages(queryParams as CreateSearchPaginatedQuery);
                default:
                    return null;
            }
        }
    );

    return {
        data,
        isLoading: !error && !data,
        error,
        mutate,
    };
}
