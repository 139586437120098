import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { Media, SearchPaginatedQuery } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export function useSearchAlbum(query: SearchPaginatedQuery) {
    const { data, error, mutate } = useSWR(
        [
            'stream-search-album',
            {
                persist: false,
                ...query,
            },
        ],
        () => Media.searchAlbum(true, query)
    );

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteSearchAlbum(query: SearchPaginatedQuery, enabled = true) {
    const { isDownload } = useHubSwitch();

    const pageSize = query?.limit || 50;
    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite<Awaited<ReturnType<typeof Media['searchAlbum']>> | null>(
        (index) => `search-album-${JSON.stringify([query, isDownload])}-${index}`,
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return enabled
                ? Media.searchAlbum(isDownload, {
                    ...query,
                    skip: +pageIndex * pageSize,
                })
                : null;
        }
    );

    const isLoadingInitialData = enabled ? !data && !error : false;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}
