import { PackItemList, Title } from '@bpm-web-app/components';
import { useInfiniteSearchPack } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useCreateFilterParams, useHubSwitch } from '@bpm-web-app/utils';
import { useMemo, useCallback, useEffect } from 'react';
import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import { CreateCardCarousel } from '../create-card-carousel/create-card-carousel';
import SeeMoreLayout from '../see-more-layout/see-more-layout';
import { LoadMore } from '../shared';
import { MusicViewProps } from './music-view-props';

export function PacksMusicView(packsMusicProps: MusicViewProps) {
    const { createQuery, title, seeMorePath, variant, onTotalCount, isLoading, setIsLoading } = packsMusicProps;
    const { hub } = useHubSwitch();
    const pageQuery = useCreateFilterParams(createQuery);

    const { data: packsData = [], isLoadingInitialData: isLoadingPacks, isLoadingMore, isLastPage, setSize, } = useInfiniteSearchPack(pageQuery);

    const flatPackData = useMemo(() => packsData?.flatMap((paginated) => paginated?.data) || [], [packsData]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore || !isLastPage || !isLoadingPacks) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingPacks, isLoadingMore, setSize]);

    useEffect(() => {
        if (packsData[0]?.pagination?.total !== undefined && onTotalCount) {
            onTotalCount(packsData[0]?.pagination?.total || 0);
        }
    }, [onTotalCount, packsData]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingPacks);
        }
    }, [isLoadingPacks, setIsLoading]);

    return variant === 'component' ?
        (
            <CreateCardCarousel
                contentType="pack"
                items={flatPackData as SoundPackage[]}
                cardSize="small"
                carouselTitle={title ?? 'Packs'}
                carouselMorePath={seeMorePath}
                noResultsText="No Packs Available"
                isLoading={isLoading || isLoadingPacks}
            />
        ) :
        variant === 'fullscreen' ? (
            <>
                <Title platform={hub} title={flatPackData[0]?.Genre?.name} />
                <SeeMoreLayout
                    contentType="pack"
                    pageTitle={title}
                    count={packsData?.[0]?.pagination?.total ?? 0}
                    itemBasePath={createAppRoutes.packs}
                    data={flatPackData as SoundPackage[]}
                />
                <LoadMore onLoadMore={handleLoadMore} />
            </>
        ) : (
            <PackItemList data={flatPackData as SoundPackage[]} onLoadMore={handleLoadMore} />
        );
}
