import { useInfiniteLabelNewestPacks } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { createAppRoutes, DEFAULT_PACKS_LIMIT } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import { SeeMoreLayout } from '../../see-more-layout/see-more-layout';
import { LoadMore } from '../../shared/load-more/load-more';
import Title from '../../title/title';

export function NewestPacksByLabelPage() {
    const router = useRouter();
    const { slug } = router.query;

    const {
        data: newestPacks,
        isLoadingMore,
        isLastPage,
        setSize,
    } = useInfiniteLabelNewestPacks(slug as string, {
        limit: DEFAULT_PACKS_LIMIT,
    });

    const flatPacks = useMemo(() => newestPacks?.flatMap((data) => data.data), [newestPacks]);

    const pagination = useMemo(() => newestPacks?.[0]?.pagination, [newestPacks]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    return (
        <>
            <Title platform="create" title={`${slug}`} />
            <SeeMoreLayout
                contentType="pack"
                pageTitle={`${slug} - New Packs`}
                count={pagination?.total || 0}
                itemBasePath={createAppRoutes.packs}
                data={flatPacks}
            />
            <LoadMore onLoadMore={handleLoadMore} />
        </>
    );
}
