import { UserPlaylistCollaboration, UserPlaylistCollaborationAccessLevel, UserPlaylistCollaborationStatus, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { useCurrentUser, useInviteUserToDrive, useRemoveInviteToDrive, useUpdateInviteToDrive } from '@bpm-web-app/swr-hooks';
import { showToast } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { UserDriveCollaboration, UserDriveCollaborationAccessLevel, UserDriveCollaborationStatus } from '@bpm-web-app/create-api-sdk';
import { ArtistApplicationInputField } from '../../pages/artist-portal/artist-application/artist-application-inputfield/artist-application-inputfield';
import { CustomIcon } from '../custom-icon/custom-icon';
import { ShareURL } from '../share-url/share-url';
import { SquaredButton } from '../squared-button/squared-button';
import { useUserPlaylists } from '../three-dots-sheet/useUserPlaylists';
import { CollaborationUserItem } from './collaboration-user-item';
import styles from './collaboration.module.css';
import SeeMore from '../see-more-button/see-more-btn';

interface CollaborationModalProps {
    // Playlists
    playlistId?: string;
    playlistOwner?: UserPlaylistWithAlbumOwner;
    collaborators?: UserPlaylistCollaboration[];
    setCollaborators?: (collaborators: UserPlaylistCollaboration[]) => void;
    invitedCollaborators?: UserPlaylistCollaboration[];
    setInvitedCollaborators?: (collaborators: UserPlaylistCollaboration[]) => void;
    // Drives
    driveId?: string;
    driveOwner?: UserPlaylistWithAlbumOwner;
    driveCollaborators?: UserDriveCollaboration[];
    setDriveCollaborators?: (collaborators: UserDriveCollaboration[]) => void;
    invitedDriveCollaborators?: UserDriveCollaboration[];
    setDriveInvitedCollaborators?: (collaborators: UserDriveCollaboration[]) => void;
    // Shared on Supreme & Create
    onClose?: () => void;
    isMyPlaylist?: boolean;
    invitationLink?: string;
    isCreate?: boolean;
}

export function CollaborationModal({
    playlistId,
    playlistOwner,
    onClose,
    invitedCollaborators,
    setInvitedCollaborators,
    collaborators,
    setCollaborators,
    driveId,
    driveOwner,
    driveCollaborators,
    setDriveCollaborators,
    invitedDriveCollaborators,
    setDriveInvitedCollaborators,
    isMyPlaylist = false,
    invitationLink,
    isCreate = false,
}: CollaborationModalProps) {
    const [showOnlyCollaborators, setShowOnlyCollaborators] = useState(false);
    const [showOnlyPending, setShowOnlyPending] = useState(false);

    const handleShowFullModal = () => {
        setShowOnlyCollaborators(false);
        setShowOnlyPending(false);
    };

    const [searchValue, setSearchValue] = useState('');
    const [searchError, setSearchError] = useState('');
    const { data: user } = useCurrentUser();
    const { inviteUserToUserPlaylist, removeUserFromPlaylist, editUserInviteToPlaylist } = useUserPlaylists(Number(playlistId));

    // Create
    const inviteUserToDrive = useInviteUserToDrive();
    const updateUserInviteToDrive = useUpdateInviteToDrive();
    const removeUserInviteFromDrive = useRemoveInviteToDrive();

    const handleSetInvitedPlaylistCollaborators = useCallback((allInvites: UserPlaylistCollaboration[]) => {
        const nonMemberInvites = allInvites.filter((invite) => invite.status === UserPlaylistCollaborationStatus.Invited);
        if (setInvitedCollaborators) setInvitedCollaborators(nonMemberInvites);
    }, [setInvitedCollaborators]);

    const handleSetInvitedDriveCollaborators = useCallback((allInvites: UserDriveCollaboration[]) => {
        const nonMemberInvites = allInvites.filter((invite) => invite.status === UserDriveCollaborationStatus.Invited);
        if (setDriveInvitedCollaborators) setDriveInvitedCollaborators(nonMemberInvites);
    }, [setDriveInvitedCollaborators]);

    const handleInvite = async (email: string) => {
        if (email.includes('@')) {
            if (isCreate && driveId) {
                try {
                    const allInvites = await inviteUserToDrive(driveId, { email, access_level: UserDriveCollaborationAccessLevel.Edit });
                    showToast({ type: 'success', title: `Invite sent to ${email}` });
                    if (allInvites.data.length > 0) handleSetInvitedDriveCollaborators(allInvites.data);
                    setSearchValue('');
                    // eslint-disable-next-line no-empty
                } catch { }
            } else if (playlistId) {
                const allInvites = await inviteUserToUserPlaylist(playlistId, { email, access_level: UserPlaylistCollaborationAccessLevel.Edit }).then((res) => {
                    if (res) {
                        showToast({ type: 'success', title: `Invite sent to ${email}` });
                        return res;
                    }
                    return [];
                }).catch(() => {
                    return [];
                });
                if (allInvites.length > 0) handleSetInvitedPlaylistCollaborators(allInvites);
                setSearchValue('');
            }
        } else {
            setSearchError('Invalid email entered. Please try again.');
        }
    };

    const handleUpdatePlaylistInviteAccessLevel = useCallback(async (inviteId: string, accessLevel: UserPlaylistCollaborationAccessLevel) => {
        await editUserInviteToPlaylist(inviteId, { access_level: accessLevel }).then((res) => {
            if (res) {
                // Check if collaborator is pending.
                if (res.status === UserPlaylistCollaborationStatus.Accepted) {
                    // Update collaborator
                    const tempCollaborators = collaborators;
                    if (tempCollaborators && tempCollaborators?.length > 0) {
                        tempCollaborators?.forEach((collaborator, index) => {
                            if (collaborator.id === res.id) {
                                tempCollaborators[index].access_level = accessLevel;
                            }
                        });
                        if (setCollaborators) setCollaborators(tempCollaborators.slice());
                    }
                } else {
                    // Update the pending invite
                    const tempInvitedCollaborators = invitedCollaborators;
                    if (tempInvitedCollaborators && tempInvitedCollaborators?.length > 0) {
                        tempInvitedCollaborators?.forEach((invitedCollaborator, index) => {
                            if (invitedCollaborator.id === res.id) {
                                tempInvitedCollaborators[index].access_level = accessLevel;
                            }
                        });
                        if (setInvitedCollaborators) setInvitedCollaborators(tempInvitedCollaborators.slice());
                    }
                }

                showToast({ type: 'success', title: "Updated collaborator's access level." });
                return res;
            }
            return [];
        }).catch(() => {
            return [];
        });
    }, [collaborators, invitedCollaborators, editUserInviteToPlaylist, setCollaborators, setInvitedCollaborators]);

    const handleUpdateDriveInviteAccessLevel = useCallback(async (inviteId: string, accessLevel: UserDriveCollaborationAccessLevel) => {
        await updateUserInviteToDrive(inviteId, { access_level: accessLevel }).then((res) => {
            if (res && res.data) {
                const resData = res.data;
                // Check if collaborator is pending.
                if (resData.status === UserDriveCollaborationStatus.Accepted) {
                    // Update collaborator
                    const tempCollaborators = driveCollaborators;
                    if (tempCollaborators && tempCollaborators?.length > 0) {
                        tempCollaborators?.forEach((driveCollaborator, index) => {
                            if (driveCollaborator.id === resData.id) {
                                tempCollaborators[index].access_level = accessLevel;
                            }
                        });
                        if (setDriveCollaborators) setDriveCollaborators(tempCollaborators.slice());
                    }
                } else {
                    // Update the pending invite
                    const tempInvitedCollaborators = invitedDriveCollaborators;
                    if (tempInvitedCollaborators && tempInvitedCollaborators?.length > 0) {
                        tempInvitedCollaborators?.forEach((invitedDriveCollaborator, index) => {
                            if (invitedDriveCollaborator.id === resData.id) {
                                tempInvitedCollaborators[index].access_level = accessLevel;
                            }
                        });
                        if (setDriveInvitedCollaborators) setDriveInvitedCollaborators(tempInvitedCollaborators.slice());
                    }
                }

                showToast({ type: 'success', title: "Updated collaborator's access level." });
                return res;
            }
            return [];
        }).catch(() => {
            return [];
        });
    }, [updateUserInviteToDrive, driveCollaborators, setDriveCollaborators, invitedDriveCollaborators, setDriveInvitedCollaborators]);

    const handleUpdateInviteAccessLevel = useCallback(async (inviteId: string, accessLevel: UserPlaylistCollaborationAccessLevel | UserDriveCollaborationAccessLevel) => {
        if (isCreate) {
            handleUpdateDriveInviteAccessLevel(inviteId, accessLevel as UserDriveCollaborationAccessLevel);
        } else {
            handleUpdatePlaylistInviteAccessLevel(inviteId, accessLevel as UserPlaylistCollaborationAccessLevel);
        }
    }, [isCreate, handleUpdateDriveInviteAccessLevel, handleUpdatePlaylistInviteAccessLevel]);

    const handleDeletePlaylistInvite = useCallback(async (inviteId: string, isPendingInvite = false) => {
        await removeUserFromPlaylist(inviteId).then((res) => {
            if (res) {
                showToast({ type: 'success', title: 'Invite successfully revoked.' });
                if (isPendingInvite) handleSetInvitedPlaylistCollaborators(res);
                else if (setCollaborators) setCollaborators(res);
                return res;
            }
            return [];
        }).catch(() => {
            return [];
        });
    }, [removeUserFromPlaylist, handleSetInvitedPlaylistCollaborators, setCollaborators]);

    const handleDeleteDriveInvite = useCallback(async (inviteId: string, isPendingInvite = false) => {
        await removeUserInviteFromDrive(inviteId).then((res) => {
            if (res) {
                showToast({ type: 'success', title: 'Invite successfully revoked.' });
                if (res.data && isPendingInvite) handleSetInvitedDriveCollaborators(res.data);
                else if (res.data && !isPendingInvite && setDriveCollaborators) setDriveCollaborators(res.data);
                return res;
            }
            return [];
        }).catch(() => {
            return [];
        });
    }, [removeUserInviteFromDrive, handleSetInvitedDriveCollaborators, setDriveCollaborators]);

    const handleDeleteInvite = useCallback(async (inviteId: string, isPendingInvite = false) => {
        if (isCreate) {
            handleDeleteDriveInvite(inviteId, isPendingInvite);
        } else {
            handleDeletePlaylistInvite(inviteId, isPendingInvite);
        }
    }, [isCreate, handleDeleteDriveInvite, handleDeletePlaylistInvite]);

    const renderPendingCollaborators = useMemo(() => {
        const pendingCollaborators = isCreate ? invitedDriveCollaborators : invitedCollaborators;
        if (pendingCollaborators && pendingCollaborators.length > 0) {
            return (
                <>
                    <div className={classNames(styles['collaboration-modal__section-title'])}>Invites Sent
                    </div>
                    <div className={classNames(styles['collaboration-modal__collaborators-list'])}>
                        {showOnlyPending ? (
                            pendingCollaborators.map((collaborator) => (
                                <CollaborationUserItem
                                    key={collaborator.id}
                                    updateAccessLevel={handleUpdateInviteAccessLevel}
                                    collaborator={collaborator}
                                    onRemovePressed={(id) => handleDeleteInvite(id, true)}
                                    isMyPlaylist={isMyPlaylist} />
                            ))
                        ) : (
                            pendingCollaborators.slice(0, 3).map((collaborator) => (
                                <CollaborationUserItem
                                    key={collaborator.id}
                                    updateAccessLevel={handleUpdateInviteAccessLevel}
                                    collaborator={collaborator}
                                    onRemovePressed={(id) => handleDeleteInvite(id, true)}
                                    isMyPlaylist={isMyPlaylist} />
                            ))
                        )}
                    </div>
                    {!showOnlyPending && pendingCollaborators.length >= 4 ? <div className={styles['collaboration-modal__see-more-btn']}><SeeMore expand={false} variant="text" onClick={() => setShowOnlyPending(!showOnlyPending)} /></div> : null}
                </>
            );
        }
        return null;
    }, [handleDeleteInvite, handleUpdateInviteAccessLevel, invitedCollaborators, invitedDriveCollaborators, isCreate, isMyPlaylist, showOnlyPending]);

    const renderUserAsOwner = useMemo(() => {
        const owner = isCreate ? driveOwner : playlistOwner;
        if (owner === undefined && user) {
            return (
                <CollaborationUserItem
                    collaborator={{
                        full_name: user.data.user.full_name,
                        email: (user.data.user.email || ''),
                        id: '',
                        created_at: '',
                        updated_at: '',
                        user_playlist_id: driveId || playlistId || '',
                        access_level: undefined,
                        status: UserPlaylistCollaborationStatus.Accepted,
                    }}
                    imageUrl={user.data.user.profile_image_url}
                    isDisplayingOwner
                    isMyPlaylist={isMyPlaylist}
                />
            );
        }
        return (
            <CollaborationUserItem
                collaborator={{
                    full_name: owner?.full_name,
                    email: owner?.email || '',
                    id: '',
                    created_at: '',
                    updated_at: '',
                    user_playlist_id: driveId || playlistId || '',
                    access_level: undefined,
                    status: UserPlaylistCollaborationStatus.Accepted,
                }}
                imageUrl={owner?.profile_image_thumbnail_url}
                isDisplayingOwner
                isMyPlaylist={isMyPlaylist}
            />
        );
    }, [driveId, driveOwner, isCreate, isMyPlaylist, playlistId, playlistOwner, user]);

    const renderCollaborators = useMemo(() => {
        const members = isCreate ? driveCollaborators : collaborators;
        if (members && members.length > 0) {
            return (
                <>
                    <div className={classNames(styles['collaboration-modal__section-title'], {
                        [styles['collaboration-modal__remove-padding']]: !isMyPlaylist
                    })}>People with access
                    </div>
                    <div className={classNames(styles['collaboration-modal__collaborators-list'])}>
                        {renderUserAsOwner}

                        {showOnlyCollaborators ? (
                            members.map((collaborator) => (
                                <CollaborationUserItem
                                    key={collaborator.id}
                                    currentUser={user?.data.user}
                                    updateAccessLevel={handleUpdateInviteAccessLevel}
                                    collaborator={collaborator}
                                    onRemovePressed={handleDeleteInvite}
                                    isMyPlaylist={isMyPlaylist} />
                            ))
                        ) : (
                            members.slice(0, 3).map((collaborator) => (
                                <CollaborationUserItem
                                    key={collaborator.id}
                                    currentUser={user?.data.user}
                                    updateAccessLevel={handleUpdateInviteAccessLevel}
                                    collaborator={collaborator}
                                    onRemovePressed={handleDeleteInvite}
                                    isMyPlaylist={isMyPlaylist} />
                            ))
                        )}
                    </div>
                    {!showOnlyCollaborators && members.length >= 4 ? <div className={styles['collaboration-modal__see-more-btn']}><SeeMore expand={false} variant="text" onClick={() => setShowOnlyCollaborators(!showOnlyCollaborators)} /></div> : null}
                </>

            );
        }
        return (
            <>
                <div className={classNames(styles['collaboration-modal__section-title'], {
                    [styles['collaboration-modal__remove-padding']]: !isMyPlaylist
                })}>People with access
                </div>
                <div className={classNames(styles['collaboration-modal__collaborators-list'])}>
                    {renderUserAsOwner}
                </div>
            </>
        );
    }, [collaborators, driveCollaborators, handleDeleteInvite, handleUpdateInviteAccessLevel, isCreate, isMyPlaylist, renderUserAsOwner, showOnlyCollaborators]);

    return (
        <div className={classNames(styles['collaboration-modal'])}>
            {isMyPlaylist ?
                <ArtistApplicationInputField
                    placeholder="Enter an email address to add"
                    text={searchValue}
                    setText={(text) => {
                        setSearchError('');
                        setSearchValue(text.toLowerCase());
                    }}
                    rightIcon={<CustomIcon type={searchValue !== '' ? 'arrow-right' : 'email-icon'} hasIconHover onClick={() => handleInvite(searchValue)} />}
                    onEnterPress={() => handleInvite(searchValue)}
                    errorMessage={searchError}
                /> : null}

            {!showOnlyPending ? renderCollaborators : null}
            {!showOnlyCollaborators && isMyPlaylist ? renderPendingCollaborators : null}

            <div className={classNames(styles['collaboration-modal__bottom-container'])}>
                {isMyPlaylist && (showOnlyCollaborators === false && showOnlyPending === false) ? <ShareURL variant="squared" currentUrl={invitationLink === undefined} label="Copy Link" iconSide="right" url={invitationLink} /> : null}
                {(showOnlyCollaborators === true || showOnlyPending === true) ? <SquaredButton type="outline" label="Go Back" onPress={handleShowFullModal} leftIcon={<CustomIcon type="arrow-right" flip="horizontal" hasIconHover />} /> : <div />}
                {isMyPlaylist === false && (showOnlyCollaborators === false && showOnlyPending === false) ? <div /> : null}
                <SquaredButton type="filled" label={isMyPlaylist ? 'Done' : 'Close'} onPress={onClose} fullWidth={!isMyPlaylist && !(showOnlyCollaborators === true || showOnlyPending === true)} />
            </div>

        </div>
    );
}
