import { CreateSearchPaginatedQuery } from '@bpm-web-app/api-client';
import { Sound } from '@bpm-web-app/create-api-sdk';
import { useInfiniteSearchPack, useInfiniteSearchSound } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, getMutatedSounds, useHubSwitch, useViewport, useCreateFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { ComponentProps, useCallback, useMemo } from 'react';
import classNames from 'classnames';
import { BreakpointView } from '../../shared/ui';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import Filters from '../../filters/filters';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';
import { PackItemList } from '../../pack-item-list/pack-item-list';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import styles from './instruments-detail.module.css';
import Title from '../../title/title';

type PacksQuery = Pick<CreateSearchPaginatedQuery, 'tag_groups' | 'tags' | 'limit'>;

export function InstrumentsDetail() {
    const router = useRouter();
    const { tag, slug } = router.query as { tag?: string; slug: string };
    const { hub } = useHubSwitch();
    const { isMobile } = useViewport();
    const { tags } = router.query;

    const query = useCreateFilterParams({});

    const pageQuery = useMemo<PacksQuery>(() => {
        const queryData: PacksQuery = {
            tag_groups: [slug],
            tags: [],
        };

        if (tags) {
            queryData.tags = Array.isArray(tags) ? [...tags] : [tags];
        }

        if (tag) {
            delete queryData.tag_groups;
            queryData.tags.push(tag);
        }

        return queryData;
    }, [slug, tag, tags]);

    const apiQuery = useMemo(() => ({ ...query, ...pageQuery }), [pageQuery, query]);

    const { data: packsData = [], isLoadingInitialData: isLoadingPacks, isLoadingMore: isLoadingMorePacks, isLastPage: isPacksLastPage, setSize: setPacksSize } = useInfiniteSearchPack(apiQuery);

    const flatPackData = useMemo(() => packsData?.flatMap((paginated) => paginated.data) || [], [packsData]);

    const totalCarouselPacks = useMemo(() => packsData?.[0]?.pagination?.total ?? 0, [packsData]);

    const packsSeeMorePath = useMemo(
        () =>
            tag
                ? createAppRoutes.instrumentsSlugPacksWithTag(slug, tag, { ...query, tags: pageQuery?.tags ? pageQuery.tags : undefined })
                : createAppRoutes.instrumentsSlugPacks(slug, { ...query, tags: pageQuery?.tags ? pageQuery.tags : undefined }),
        [pageQuery.tags, query, slug, tag]
    );

    const title = useMemo(() => (tag ? decodeURIComponent(tag) : slug || ''), [slug, tag]);

    const { data: soundsData, isLoadingInitialData, isLoadingMore: isLoadingMoreSounds, setSize, isLastPage, mutate } = useInfiniteSearchSound(apiQuery);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    const flatSounds = useMemo(() => soundsData?.flatMap<Sound>(({ data: paginatedResponse }) => paginatedResponse) || [], [soundsData]);

    const handleLoadMoreSongs = useCallback(() => {
        if (!isLoadingInitialData && !isLoadingMoreSounds && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingInitialData, isLoadingMoreSounds, setSize]);

    const handleLoadMorePacks = useCallback(() => {
        if (!isLoadingPacks && !isLoadingMorePacks && !isPacksLastPage) {
            setPacksSize((prevSize) => prevSize + 1);
        }
    }, [isLoadingMorePacks, isLoadingPacks, isPacksLastPage, setPacksSize]);

    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: packsData?.[0]?.pagination?.total || 0,
                activeTabComponent: <PackItemList data={flatPackData} onLoadMore={handleLoadMorePacks} />,
            },
            {
                id: 'sounds',
                title: 'Sounds',
                count: soundsData?.[0]?.pagination?.total || 0,
                activeTabComponent: (
                    <div className={styles['instruments-detail__mobile-track-list']}>
                        <TrackListCreate mutateSound={mutateSound} hideHeading isLoadingMore={isLoadingMoreSounds} list={flatSounds} onLoadMore={handleLoadMoreSongs} />
                    </div>
                ),
            },
        ],
        [flatPackData, flatSounds, handleLoadMorePacks, handleLoadMoreSongs, isLoadingMoreSounds, packsData, soundsData, mutateSound]
    );

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoadingInitialData &&
            !isLoadingPacks
        ) return true;
        return false;
    }, [isLoadingInitialData, isLoadingPacks]);

    return (
        <>
            <Title platform={hub} title={title || 'Loading instrument...'} />
            <div className={classNames(styles['instruments-detail'], 'spacing--page-padding-mobile')}>
                <div className={classNames(styles['instruments-detail__header'], isMobile ? '' : 'spacing--page-padding', 'spacing--top')}>
                    <PrimaryPageTitle title={title || 'Loading...'} noPadding />
                    {isEverythingLoaded ? <Filters platform="create" showOnMobile hideCreateTags hideFileType={!tag} /> : null}
                </div>
                <BreakpointView
                    mobileChildren={
                        <div className={styles['instruments-detail__horizontal-tabs-container']}>
                            <HorizontalTabs tabs={mobileTabs} />
                        </div>
                    }
                    desktopChildren={
                        <>
                            <CreateCardCarousel
                                contentType="pack"
                                items={flatPackData}
                                cardSize="small"
                                carouselTitle="Packs"
                                carouselTitleCounter={`${totalCarouselPacks}`}
                                carouselMorePath={packsSeeMorePath}
                                noResultsText="No Packs Available"
                                isLoading={!isEverythingLoaded}
                            />
                            <SecondaryPageTitle title="Sounds" counter={soundsData?.[0]?.pagination?.total} />
                            <div className="spacing--top" />
                            <div className="spacing__window--horizontal">
                                <TrackListCreate
                                    mutateSound={mutateSound}
                                    list={flatSounds}
                                    hideTagColumn
                                    onLoadMore={handleLoadMoreSongs}
                                    isLoading={!isEverythingLoaded}
                                    isLoadingMore={isLoadingMoreSounds}
                                />
                            </div>
                        </>
                    }
                />
            </div>
        </>
    );
}

export default InstrumentsDetail;
