import { CreateCardCarousel } from '@bpm-web-app/components';
import { GhostComponent, PrimaryPageTitle } from '@bpm-web-app/components/shared';
import { Section, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { useGetNewestSoundPackagesByGenres, useGetSections } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, useUniqueArray } from '@bpm-web-app/utils';
import { Fragment, useMemo } from 'react';
import styles from './new-packs.module.css';

type PacksByGenreCarouselData = {
    [key: string]: SoundPackage[];
};

export const NewPacks = () => {
    const { data: sectionsData, isLoading: isLoadingSectionsData } = useGetSections('new');
    const { data: packsByGenreData, isLoading: isLoadingByGenreData } = useGetNewestSoundPackagesByGenres();

    const packsByGenre = useMemo<PacksByGenreCarouselData>(
        () =>
            packsByGenreData?.data?.reduce((prev, current) => {
                const currentPack = current;
                if (prev[`${current.Genre.slug}`]) {
                    prev[`${current.Genre.slug}`].push(currentPack);
                } else {
                    // eslint-disable-next-line no-param-reassign
                    prev[`${current.Genre.slug}`] = [currentPack];
                }
                return prev;
            }, {} as PacksByGenreCarouselData),
        [packsByGenreData?.data]
    );

    const PacksByGenre = useMemo(
        () =>
            packsByGenre
                ? Object.entries(packsByGenre).map(([key, packs]) => (
                    <CreateCardCarousel
                        contentType="pack"
                        key={key}
                        items={packs}
                        cardSize="small"
                        carouselMorePath={packs[0].Genre?.name ? createAppRoutes.genresSlug(packs[0].Genre.name) : undefined}
                        isLoading={false}
                        carouselTitle={packs[0].Genre?.name}
                    />
                ))
                : null,
        [packsByGenre]
    );

    const isEverythingLoaded = useMemo(() => {
        if (
            !isLoadingByGenreData &&
            !isLoadingSectionsData
        ) return true;
        return false;
    }, [isLoadingByGenreData, isLoadingSectionsData]);

    const ghostElementsKeys = useUniqueArray(5);

    return (
        <>
            <div className={styles['new-packs__header']}>
                <PrimaryPageTitle title="New Packs" />
            </div>
            {isEverythingLoaded ? (
                sectionsData?.data?.map((section) => {
                    if (section.type === Section.TypeEnum.Virtual) {
                        if (section.name === 'Packs By Genre') {
                            return <Fragment key={section.id}>{PacksByGenre}</Fragment>;
                        }
                    }

                    if (section.name === 'Featured Packs') {
                        return (
                            <CreateCardCarousel
                                contentType="pack"
                                key={section.id}
                                items={section.items}
                                cardSize="small"
                                carouselMorePath={createAppRoutes.newFeatured}
                                isLoading={false}
                                carouselTitle="Featured"
                            />
                        );
                    }
                    return null;
                })
            ) : (
                <>
                    <GhostComponent type="cards" title="Featured" isCreate cardSize="small" key="featured-packs-ghost" elementsCount={10} />
                    {ghostElementsKeys.map((keyId) => {
                        return (
                            <>
                                <GhostComponent type="secondary-title" key={`secondary-title-pack-${keyId}`} />
                                <GhostComponent type="cards" key={`ghost-pack-${keyId}`} elementsCount={10} cardSize="small" />
                            </>
                        );
                    })}

                </>
            )}
        </>
    );
};
