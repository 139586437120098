import { useGetFavoritedCreateCuratedSets } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import SeeMoreLayout from '../../see-more-layout/see-more-layout';

export function FavoriteCurated() {
    const { data } = useGetFavoritedCreateCuratedSets();

    return (
        <SeeMoreLayout
            contentType="curated-set"
            pageTitle="Curated Set"
            count={data?.data?.length || 0}
            itemBasePath={createAppRoutes.curated}
            data={data?.data}
        />
    );
}
export default FavoriteCurated;
