import classNames from 'classnames';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { useViewport } from '@bpm-web-app/utils';
import styles from './expandable-text.module.css';

interface ExpandableTextProps {
    /* line height of text. There is a default setting, but if the parent component
     * needs text with different line height, this needs to be passed here
     * in order for the "Read more/less" button's logic to work properly. */
    textLineHeight?: number;
    collapsedLinesCount?: number;
    children: ReactNode;
}
export function ExpandableText({ collapsedLinesCount = 3, textLineHeight, children }: ExpandableTextProps) {
    const [isTextCollapsed, setIsTextCollapsed] = useState(true);
    const textContainerRef = useRef<HTMLDivElement>(null);

    const { isMobile } = useViewport();
    const defaultTextLineHeight = useMemo(() => (isMobile ? 20 : 16), [isMobile]);

    return (
        <>
            <div
                ref={textContainerRef}
                className={classNames(styles['expandable-text__description'], {
                    [styles['expandable-text__description--collapsed']]: isTextCollapsed,
                })}
                style={{ WebkitLineClamp: collapsedLinesCount }}
            >
                {children}
            </div>
            {(textContainerRef.current?.scrollHeight || 0) > collapsedLinesCount * (textLineHeight ?? defaultTextLineHeight) && (
                <button type="button" className="button button--plain-link" onClick={() => setIsTextCollapsed(!isTextCollapsed)}>
                    {isTextCollapsed ? 'Read More' : 'Read Less'}
                </button>
            )}
        </>
    );
}
