import { MouseEvent } from 'react';
import { CustomIcon } from '../custom-icon/custom-icon';

interface AddCollaboratorsButtonProps {
    onClick: (e: MouseEvent) => void;
}

export function AddCollaboratorsButton({ onClick }: AddCollaboratorsButtonProps) {
    return (
        <CustomIcon type="add-person" hasIconHover hasBackgroundHover container="round" tooltip="Add Collaborators" onClick={onClick} containerSize="regular" />
    );
}
