import { Sound } from '@bpm-web-app/create-api-sdk';
import { useInfiniteSearchSound, useTags } from '@bpm-web-app/swr-hooks';
import { useCreateFilterParams, getMutatedSounds } from '@bpm-web-app/utils';
import { useMemo, useCallback, useContext, useEffect } from 'react';
import { FiltersContext } from '../filters/filters.context';
import { SecondaryPageTitle, TrackListCreate } from '../shared';
import { MusicViewProps } from './music-view-props';

export function SoundsMusicView(soundsMusicProps: MusicViewProps) {
    const { createQuery, title, variant, onTotalCount, isLoading, setIsLoading } = soundsMusicProps;
    const { tags, setTags } = useContext(FiltersContext);

    const query = useCreateFilterParams(createQuery);

    const { data: soundsData = [], isLoadingInitialData, isLoadingMore: isLoadingMoreSounds, setSize, isLastPage, mutate, size } = useInfiniteSearchSound(query);
    const { tags: possibleTags } = useTags({ ...query, limit: undefined });
    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData, id, progress));
        },
        [mutate, soundsData]
    );

    const flatSounds = useMemo(() => soundsData?.flatMap<Sound>(({ data: paginatedResponse }) => paginatedResponse) || [], [soundsData]);

    const handleLoadMoreSongs = useCallback(() => {
        if (!isLoadingInitialData && !isLoadingMoreSounds && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingInitialData, isLoadingMoreSounds, setSize]);

    useEffect(() => {
        if (soundsData[0]?.pagination?.total !== undefined && onTotalCount) {
            onTotalCount(soundsData[0]?.pagination?.total || 0);
        }
    }, [onTotalCount, soundsData]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingInitialData);
        }
    }, [isLoadingInitialData, setIsLoading]);

    return variant === 'component' ? (
        <>
            <SecondaryPageTitle title={title || 'Sounds'} />
            <div className="spacing__window--horizontal">
                <TrackListCreate
                    mutateSound={mutateSound}
                    list={flatSounds}
                    isLoading={isLoading || isLoadingInitialData}
                    isLoadingMore={isLoadingMoreSounds}
                    showTagFilter
                    tags={possibleTagsString}
                    selectedTags={tags}
                    onToggleTag={(tag, on) => {
                        if (on) {
                            setTags([...tags, tag]);
                        } else {
                            setTags(tags.filter((t) => t !== tag));
                        }
                    }}
                    onSeeMorePressed={!isLastPage ? handleLoadMoreSongs : undefined}
                />
            </div>
        </>
    ) :
        (
            <TrackListCreate
                mutateSound={mutateSound}
                hideHeading
                isLoadingMore={isLoadingMoreSounds}
                list={flatSounds}
                onLoadMore={handleLoadMoreSongs} />
        );
}
