import { useDownloadPreview } from '@bpm-web-app/utils';
import { ActionModal } from '../action-modal/action-modal';

export function DownloadPreviewModal({ platform }: { platform: 'supreme' | 'create' }) {
    const {
        isOpen,
        isLoading,
        creditAmount,
        modalSubtitle,
        onConfirmPressed,
        onCancelPressed,
    } = useDownloadPreview();

    return (
        <ActionModal
            headerTitle="Heads Up"
            title="You are about to use a few credits."
            subtitle={`Do you want to download ${modalSubtitle} for ${creditAmount} Credits?`}
            confirmButtonText="Continue"
            onConfirm={onConfirmPressed}
            onClose={onCancelPressed}
            actionLoading={isLoading}
            variant="light"
            isOpen={isOpen}
        />
    );
}
