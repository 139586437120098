import { useSearchAlbum, useSearchMedia } from '@bpm-web-app/swr-hooks';
import { useHubSwitch, useSupremeFilterParams } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { AppLink, GhostComponent, NoResultsBlock, SecondaryPageTitle } from '../shared';
import { usePagination } from '../shared/paging/paging';
import SeeMore from '../shared/see-more-button/see-more-btn';
import { TrackListLoadingSupreme } from '../shared/track-list/ghost-loading/track-list-loading-supreme';
import { TrackListSupreme } from '../shared/track-list/supreme/track-list-supreme';
import { TrackListPresetSupreme } from '../shared/track-list/supreme/track-list-supreme-helpers';
import { TrackListFilters } from '../shared/track-list/track-list-filters/track-list-filters';
import { APISortingKeys, apiSortOptions, useApiSort } from '../sort-options-sheet/sort-options-sheet';
import { MusicViewProps } from './music-view-props';

export function TracksMusicView(trackMusicProps: MusicViewProps) {
    const { title, query, variant = 'component', removePadding, seeMorePath, isLoading, setIsLoading } = trackMusicProps;
    const { isDownload } = useHubSwitch();
    const router = useRouter();
    const { sortBy } = router.query;
    const apiSortFunction = useApiSort();
    const { limit, page, bottomComponent, setPagination } = usePagination();
    const tracksQuery = useSupremeFilterParams(false, { limit, skip: (page - 1) * limit, ...query });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { data, mutate, isLoading: isLoadingData } = isDownload ? useSearchAlbum(tracksQuery) : useSearchMedia(tracksQuery);

    const songs = data?.data;
    const flatMediaList = songs || [];

    useEffect(() => {
        setPagination(data?.pagination);
    }, [data, setPagination]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingData);
        }
    }, [isLoadingData, setIsLoading]);

    const isLoadingAnything = useMemo(() => {
        return isLoading;
    }, [isLoading]);

    return (
        <div className={removePadding ? '' : 'spacing__window--horizontal'}>
            <SecondaryPageTitle title={title || 'Loading Tracks...'} noPadding />
            {!isLoadingAnything ? (
                <div className="spacing--top">
                    <TrackListFilters dynamicActiveTabColor showActiveFilters defaultFilters={query} />
                </div>) : <GhostComponent type="custom" height={60} noPadding />}

            {!isLoadingAnything && !isLoadingData ?
                <>
                    <TrackListSupreme
                        indexOffset={(page - 1) * limit}
                        hasColumnSorting
                        preset={isDownload ? TrackListPresetSupreme.Download : TrackListPresetSupreme.Stream}
                        list={flatMediaList}
                        isSortable
                        sortOptions={apiSortOptions}
                        onSort={apiSortFunction}
                        selectedSortType={(sortBy as APISortingKeys) || 'trending'}
                        onDownloadRevalidate={(downloadMedia) => {
                            /* when the page is using infinite scroll, we normally don't want to revalidate
                                         * all the already loaded pages because of performance concerns.
                                         * In order to keep the logic simple and not get any false positives,
                                         * we'll just perform an optimistic FE-only update, so a user
                                         * can see the change straight away without having to make any api calls. */
                            if (downloadMedia) {
                                // eslint-disable-next-line no-param-reassign
                                downloadMedia.download_count += 1;
                                /* for some reason, mutate of useSWRInfinite handles opts differently
                                 * than a normal useSWR. See a reported bug for more info:
                                 * https://github.com/vercel/swr/issues/1899 */
                                mutate(data as any, false);
                            }
                        }}
                        onFilterApplied={(filters) => {
                            if (filters.bpm) {
                                router.replace({
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        bpm: [filters.bpm, filters.bpm]
                                    },
                                });
                            }
                            if (filters.genre) {
                                router.replace({
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        genre: [filters.genre]
                                    },
                                });
                            }
                            if (filters.key) {
                                router.replace({
                                    pathname: router.pathname,
                                    query: {
                                        ...router.query,
                                        key: [filters.key]
                                    },
                                });
                            }
                        }}
                    />

                    {data && data.pagination && data.pagination?.total > 25 ?
                        (
                            variant === 'component' ? (
                                <AppLink
                                    href={seeMorePath || {
                                        pathname: router.pathname,
                                        query: {
                                            ...router.query,
                                            type: 'tracks',
                                        },
                                    }}
                                    overrideAppLinkTransform
                                >
                                    <SeeMore expand={false} variant="text" />
                                </AppLink>
                            ) : bottomComponent
                        ) : null
                    }

                </>
                : null}
            {isLoadingAnything || isLoadingData ? (<TrackListLoadingSupreme amount={20} preset={TrackListPresetSupreme.Download} />) : null}
            {!isLoadingAnything && flatMediaList.length === 0 ? <NoResultsBlock>{title ? `No ${title} Available` : 'Not Available'}</NoResultsBlock> : null}
        </div>
    );
}
