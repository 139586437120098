import { createContext, ReactNode, useMemo, useState, useContext, useCallback } from 'react';

export interface DownloadPreviewActionModalProps {
    subtitle: string;
    amount: number;
    onCancel: () => void;
    onConfirm: () => void;
}

export interface DownloadPreviewContextProperties {
    isLoading: boolean;
    isOpen: boolean;
    setIsLoading: (loading: boolean) => void;
    closeDownloadModal: () => void;
    setShowDownloadPreviewModal: (options: DownloadPreviewActionModalProps) => void;
    creditAmount: number;
    modalSubtitle: string;
    onCancelPressed: () => void;
    onConfirmPressed: () => void;
}

export const DownloadPreviewContext = createContext<DownloadPreviewContextProperties>({} as DownloadPreviewContextProperties);

export interface DownloadPreviewProviderProps {
    children: ReactNode;
}

export function DownloadPreviewProvider({ children }: DownloadPreviewProviderProps) {
    const [isLoading, setIsLoading] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [creditAmount, setCreditAmount] = useState(0);
    const [modalSubtitle, setModalSubtitle] = useState('');
    // eslint-disable-next-line no-console
    const [onCancelPressed, setOnCancelPressed] = useState(() => () => { });
    // eslint-disable-next-line no-console
    const [onConfirmPressed, setOnConfirmPressed] = useState(() => () => { });

    const close = useCallback(() => {
        setIsOpen(false);
        setIsLoading(false);
        setCreditAmount(0);
        setModalSubtitle('');
    }, []);

    const onSetIsLoading = useCallback((loading: boolean) => {
        setIsLoading(loading);
    }, []);

    const value = useMemo<DownloadPreviewContextProperties>(
        () => ({
            isLoading,
            isOpen,
            setIsLoading: onSetIsLoading,
            closeDownloadModal: close,
            setShowDownloadPreviewModal: ({
                subtitle,
                amount,
                onCancel,
                onConfirm
            }: DownloadPreviewActionModalProps) => {
                setIsOpen(true);
                setModalSubtitle(subtitle);
                setCreditAmount(amount);
                setOnCancelPressed(() => onCancel());
                setOnConfirmPressed(() => onConfirm());
            },
            creditAmount,
            modalSubtitle,
            onCancelPressed,
            onConfirmPressed,
        }),
        [close, creditAmount, isLoading, isOpen, modalSubtitle, onCancelPressed, onConfirmPressed, onSetIsLoading]
    );

    return <DownloadPreviewContext.Provider value={value}>{children}</DownloadPreviewContext.Provider>;
}

export function useDownloadPreview() {
    const context = useContext(DownloadPreviewContext);
    return context;
}
