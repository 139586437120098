import { useCallback } from 'react';
import { BlogArticle } from '@bpm-web-app/supreme-api-sdk';
import dayjs from 'dayjs';
import Card from '../card';
import CardGhostLoading from '../card-ghost-loading';
import { CardCreate } from '../card-create';
import styles from './news-card.module.css';

interface NewsCardProps {
    newsList?: BlogArticle[];
    news?: BlogArticle;
    isCreate?: boolean;
    isGhost?: boolean;
}

export function NewsCard({ news, newsList, isCreate, isGhost }: NewsCardProps) {
    const getSubtitleMarkup = useCallback((card: any) => {
        const lastTag = card.tags[card.tags.length - 1];
        return <div className={styles['news-card__subtitle']}>{`${lastTag.title} - ${dayjs(lastTag.created_at).format('MMMM D, YYYY')}`.toUpperCase()}</div>;
    }, []);

    if (isGhost) {
        return (
            <CardGhostLoading contentType="news" />
        );
    }

    return (
        isCreate ? news ? (
            <CardCreate
                contentType="news"
                cardSize="large"
                imageUrl={news.image_url || ''}
                link={news.link}
                openInNewTab
                id={news.id}
                title={news.title}
                description={getSubtitleMarkup(news)}
                isDescriptionSingleLine
            />
        ) : null : news ? (
            <Card
                cardSize="large"
                imageUrl={news.image_url || ''}
                link={news.link}
                openInNewTab
                id={news.id}
                title={news.title}
                description={getSubtitleMarkup(news)}
                isDescriptionSingleLine
                isPlayable={false}
            />
        ) : null
    );
}
