import useSWR from 'swr';
import { Artist } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

type SearchArtistResponse = Awaited<ReturnType<typeof Artist['searchArtist']>>;

export function useSearchArtist(query: any) {
    const { isDownload } = useHubSwitch();

    const { data, error } = useSWR<SearchArtistResponse>(
        [
            'stream-search-artists',
            {
                persist: false,
                isDownload,
                ...query,
            },
        ],
        () => Artist.searchArtist(isDownload, query)
    );

    return {
        data: data?.data,
        isLoading: !error && !data,
        error,
    };
}
