import { useCreateDrive, useGetDrives, useGetMeSubscription, useGetSharedDrives } from '@bpm-web-app/swr-hooks';
import { useMemo, useState } from 'react';
import classNames from 'classnames';
import { Drive } from '@bpm-web-app/create-api-sdk';
import styles from './my-drive.module.css';
import { LocalSearchSort } from '../../nav-my-playlist-categories/utils';
import PlaylistsForm from '../../playlists-form/playlists-form';
import { PageHeader } from '../../shared/page-header/page-header';
import { EmptyState, GhostComponent } from '../../shared';
import { DriveCardCreate } from '../../shared/card/drive-card-create/drive-card-create';

/* eslint-disable-next-line @typescript-eslint/no-empty-interface */
export interface MyDriveProps { }

export function MyDrive(props: MyDriveProps) {
    const { data: driveData, isLoading, mutate } = useGetDrives();
    const { data: sharedDrivesData } = useGetSharedDrives(true);

    const { data: createSubscriptionData } = useGetMeSubscription('create', false);
    const [searchTerm, setSearchTerm] = useState('');
    const [sortType, setSortType] = useState<LocalSearchSort>(undefined);
    const [showCreateForm, setShowCreateForm] = useState<boolean>(false);

    const createDriveFolder = useCreateDrive();

    const CreateDriveForm = useMemo(
        () => (
            <PlaylistsForm
                type="CreateNewDrive"
                text="Create a personal collection of sounds to help organize your projects."
                formAction={(name) => createDriveFolder({ name })}
                close={() => setShowCreateForm(false)}
            />
        ),
        [createDriveFolder]
    );

    const drivesList = useMemo(() => {
        let tempArray: Drive[] = [];
        switch (sortType) {
            case 'most_recent':
                tempArray = driveData?.slice().sort((a, b) => (a.created_at < b.created_at ? 1 : -1)) || [];
                break;
            case 'title_asc':
                tempArray = driveData?.slice().sort((a, b) => (a.name > b.name ? 1 : -1)) || [];
                break;
            case 'title_desc':
                tempArray = driveData?.slice().sort((a, b) => (a.name < b.name ? 1 : -1)) || [];
                break;
            default:
                tempArray = driveData?.slice() || [];
                break;
        }

        if (searchTerm.trim()) {
            tempArray = tempArray.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase()));
        }

        return tempArray;
    }, [driveData, searchTerm, sortType]);

    const hasDrives = useMemo(() => drivesList?.length !== 0, [drivesList?.length]);

    const isSubscribedToAPlan = useMemo(() => {
        if (createSubscriptionData?.data?.membership?.has_membership) {
            return true;
        }
        return false;
    }, [createSubscriptionData?.data?.membership?.has_membership]);

    const renderEmptyState = useMemo(() => {
        if (isSubscribedToAPlan) {
            return (
                <EmptyState title="Looks like you haven't created any drives yet." subtitle="Start building your own personalized drives by adding your favorite sounds. Your drives will appear here once you've created them." actionLabel="Create Drive" onPress={() => setShowCreateForm(true)} noPadding verticalMargins={16} />
            );
        }
        return (
            <EmptyState title="Subscribe today to start building your library." subtitle="Start building your own personalized drives by adding your favorite sounds. Your drives will appear here once you've created them." actionLabel="Get Started" href="account/plan" icon="heart-outline-icon" actionIcon="chevron-right-icon" noPadding verticalMargins={16} />
        );
    }, [isSubscribedToAPlan]);

    return (
        <div className={classNames(styles['my-drive'], 'spacing__window')}>
            {hasDrives ?
                (<PageHeader
                    title={drivesList ? `My Drives (${drivesList.length})` : 'My Drives'}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    searchPlaceholder="Search Drives"
                    handleAdd={() => setShowCreateForm(true)}
                    onSort={setSortType}
                    selectedSortType={sortType}
                />
                ) : (
                    <PageHeader
                        title={drivesList ? `My Drives (${drivesList.length})` : 'My Drives'}
                    />
                )}
            {showCreateForm && CreateDriveForm}
            {!hasDrives && renderEmptyState}
            {isLoading ? (
                <GhostComponent type="cards" elementsCount={16} cardSize="user-playlist" linesWidthArray={[90, 70]} grid noPadding />
            ) : (
                <div className={styles['my-drive__grid']}>
                    {sharedDrivesData && sharedDrivesData.data && sharedDrivesData?.data?.length > 0 ? (
                        <DriveCardCreate isSharedDriveCard items={sharedDrivesData?.data} />
                    ) : null}
                    {drivesList.map((item) => (
                        <DriveCardCreate item={item as Drive} items={drivesList as Drive[]} shouldMutate={() => mutate()} />
                    ))}
                </div>)}
        </div>
    );
}

export default MyDrive;
