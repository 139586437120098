import styles from './list.module.css';

export function DateHeader({ date }: { date: string }) {
    return (
        <div className={styles['table__date-group-header']}>
            <p>
                {date}
            </p>
        </div>
    );
}
