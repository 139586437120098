import { SortByQueryOptions } from '@bpm-web-app/stream-api-sdk';
import { useSearchArtist } from '@bpm-web-app/swr-hooks';
import { useEffect, useMemo } from 'react';
import { ArtistCardImage, CardCarousel, GhostComponent, NoResultsBlock, SecondaryPageTitle } from '../shared';
import { MusicViewProps } from './music-view-props';

export function ArtistsMusicView(artistsMusicProps: MusicViewProps) {
    const { query, title, seeMorePath, isLoading, setIsLoading } = artistsMusicProps;
    const artistsQuery = {
        ...query,
        sort_by: SortByQueryOptions.DateDesc,
        library: query?.library,
    };
    const { data, isLoading: isLoadingData } = useSearchArtist(artistsQuery);
    const searchArtist = data;

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingData);
        }
    }, [isLoadingData, setIsLoading]);

    const isLoadingAnything = useMemo(() => {
        return isLoading || isLoadingData;
    }, [isLoading, isLoadingData]);

    if (isLoadingAnything) {
        return (<GhostComponent type="artist-cards" elementsCount={10} title={title} />);
    }
    return searchArtist && searchArtist.length > 0 ? (
        <CardCarousel title={title} titleCounter={`${searchArtist?.length}`} seeMorePath={seeMorePath}>
            {searchArtist.map((artist) => (
                <ArtistCardImage
                    key={artist.id}
                    artist={artist}
                />
            ))}
        </CardCarousel>
    ) : (
        <>
            <SecondaryPageTitle title={title as string} />
            <NoResultsBlock hasMargin>No Artists Available</NoResultsBlock>
        </>
    );
}
