import { useRouter } from 'next/router';
import Image from 'next/image';
import { getPlatformLinkUsingRouter, getSignupRedirectLink, useUserSettings, useViewport } from '@bpm-web-app/utils';
import { useGetMeSubscriptionUnCached } from '@bpm-web-app/swr-hooks';
import { useMemo } from 'react';
import classNames from 'classnames';
import { SquaredButton } from '../squared-button/squared-button';
import styles from './anonymous-user-banner.module.css';
import bannerImage from '../../../assets/images/isAnonymousBanner-desktop.png';
import bannerImageMobile from '../../../assets/images/isAnonymousBanner-mobile.png';
import { BreakpointView } from '../ui';
import BPMIcons from '../bpm-icons/bpm-icons';

interface AnonymousUserBannerProps {
    isCreate?: boolean;
    noHorizontalPadding?: boolean;
    addTopPadding?: boolean;
    addBottomPadding?: boolean;
    centerAlignOnMobile?: boolean;
}

export function AnonymousUserBanner({ isCreate, noHorizontalPadding = false, addTopPadding = false, addBottomPadding = false, centerAlignOnMobile = false }: AnonymousUserBannerProps) {
    const router = useRouter();
    const { isMobile } = useViewport();
    const { data: subscriptionData, isLoading } = useGetMeSubscriptionUnCached(isCreate ? 'create' : 'supreme');
    const hasMembership = useMemo(() => subscriptionData?.data.membership.has_membership, [subscriptionData?.data.membership.has_membership]);
    const { isAnonymous } = useUserSettings();

    const handleOnPress = () => {
        if (!isAnonymous) router.push(getPlatformLinkUsingRouter('/account/plan'));
        else router.replace(getSignupRedirectLink());
    };

    if (isLoading) return null;
    if (!isAnonymous && !!hasMembership) return null;

    return (
        <div className={classNames(addTopPadding ? 'spacing__window--top' : '', addBottomPadding ? 'spacing__window--bottom' : '')}>
            <div
                tabIndex={0}
                role="button"
                onKeyDown={(e) => null}
                onClick={() => {
                    if (centerAlignOnMobile && isMobile) handleOnPress();
                }}
                className={classNames(styles['anonymous-user-banner'], noHorizontalPadding ? '' : 'spacing__window--horizontal', { [styles['anonymous-user-banner--mobile-variant']]: centerAlignOnMobile && isMobile })}
            >
                {centerAlignOnMobile && isMobile ? (
                    <Image quality={100} src={bannerImage} height={70} width={70} objectFit="cover" style={{ borderRadius: 4 }} />
                ) : null}
                <div className={classNames(centerAlignOnMobile && isMobile ? styles['anonymous-user-banner__text-container-mobile-variant'] : styles['anonymous-user-banner__text-container'])}>
                    {isCreate ? (
                        <span>
                            Get access to nearly one million royalty-free sounds
                        </span>
                    ) : (
                        <span>
                            Start downloading DJ-ready music
                        </span>
                    )}
                    {centerAlignOnMobile && isMobile ? (
                        <BPMIcons.ArrowRight />
                    ) : (
                        <SquaredButton type="filled" label="Get Started" fullWidth={false} onPress={handleOnPress} />)}
                </div>
                {centerAlignOnMobile && isMobile ? null : (
                    <BreakpointView
                        desktopChildren={<Image quality={100} src={bannerImage} height={246} width={540} objectFit="cover" />}
                        mobileChildren={<Image quality={100} src={bannerImageMobile} objectFit="cover" />}
                    />
                )}
            </div>
        </div>
    );
}
