type StorageLocations = 'search-history' | 'search-history';

export function localStorageAdd(location: StorageLocations, item: string) {
    const currentData = JSON.parse(localStorage.getItem(location) || '[]');
    try {
        currentData.push(item);
        localStorage.setItem(location, JSON.stringify(Array.from(currentData)));
        return currentData;
    } catch (error) {
        return [];
    }
}

export function localStorageRemove(location: StorageLocations, item: string) {
    const currentData = JSON.parse(localStorage.getItem(location) || '[]');
    const tempData = currentData.filter((e: string) => e.toLowerCase() !== item.toLowerCase());
    localStorage.setItem(location, JSON.stringify(Array.from(tempData)));
    return tempData;
}

export function localStorageClear(location: StorageLocations) {
    localStorage.setItem(location, JSON.stringify(Array.from([])));
}

export function localStorageGet(location: StorageLocations) {
    return JSON.parse(localStorage.getItem(location) || '[]').reverse();
}
