import { CreateSearchPaginatedQuery } from '@bpm-web-app/api-client';
import { SortByQueryOptions } from '@bpm-web-app/create-api-sdk';
import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import { useCreatePlayer } from './create-player.context';
import { useDeepCompareEffect } from './useDeepCompareEffect';

export function useCreateFilterParams(overrides?: Partial<CreateSearchPaginatedQuery>) {
    const router = useRouter();

    const [actualOverrides, setActualOverrids] = useState<Partial<CreateSearchPaginatedQuery> | undefined>();

    useDeepCompareEffect(() => {
        setActualOverrids(overrides);
    }, [overrides]);

    const { bpm, key, keyType, tagGroups, subgenre, genre, tags, hidePrevPlayed, hidePrevDownloaded, searchTerm, label, fileType, synth } = router.query;
    const { trackListSortBy } = useCreatePlayer();
    const query = useMemo<CreateSearchPaginatedQuery>(() => {
        const queryParams: CreateSearchPaginatedQuery = {
            limit: 20
        };

        if (bpm) {
            queryParams.bpm_min = Number(bpm[0]);
            queryParams.bpm_max = Number(bpm[1]);
        }
        if (key) queryParams.key = key;
        if (keyType) queryParams.keyType = `${keyType}`;
        if (genre) queryParams.genre = genre as string[];
        if (subgenre) queryParams.subgenre = subgenre as string[];
        if (tagGroups) queryParams.tag_groups = tagGroups as string[];
        if (hidePrevPlayed === '1') queryParams.hide_prev_played = true;
        if (hidePrevDownloaded === '1') queryParams.hide_prev_downloaded = true;
        if (tags) queryParams.tags = tags as string[];
        if (label) queryParams.label = label as string;
        if (trackListSortBy) queryParams.sort_by = trackListSortBy as unknown as SortByQueryOptions;
        if (searchTerm) queryParams.search = `${searchTerm}`;
        if (fileType) queryParams.type = fileType as string;
        if (synth) queryParams.synth = synth as string;

        return { ...queryParams, ...actualOverrides };
    }, [bpm, key, keyType, genre, subgenre, tagGroups, fileType, synth, hidePrevPlayed, hidePrevDownloaded, tags, trackListSortBy, searchTerm, actualOverrides, label]);
    return query;
}
