import { useInfiniteSearchPresets, useTags } from '@bpm-web-app/swr-hooks';
import { useCreateFilterParams, getMutatedPresets, PresetResults } from '@bpm-web-app/utils';
import { useMemo, useCallback, useContext, useEffect } from 'react';
import { FiltersContext } from '../filters/filters.context';
import TrackListCreatePresets from '../shared/track-list/create-presets/track-list-create-presets';
import { MusicViewProps } from './music-view-props';

export function PresetMusicView(presetMusicProps: MusicViewProps) {
    const { createQuery, title, onTotalCount, variant, isLoading, setIsLoading } = presetMusicProps;
    const { tags, setTags } = useContext(FiltersContext);

    const query = useCreateFilterParams(createQuery);
    const { data: presetData = [], mutate, isLoadingInitialData, setSize, isLoadingMore, isLastPage } = useInfiniteSearchPresets(query);
    const { tags: possibleTags } = useTags({ ...query, limit: undefined });

    const mutatePreset = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedPresets(presetData as PresetResults[], id, progress));
        },
        [mutate, presetData]
    );

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const presets = useMemo(() => presetData?.flatMap((data) => data!.data), [presetData]);

    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);

    const handleLoadMoreSongs = useCallback(() => {
        if (!isLoadingInitialData && !isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingInitialData, isLoadingMore, setSize]);

    useEffect(() => {
        if (presetData[0]?.pagination?.total !== undefined && onTotalCount) {
            onTotalCount(presetData[0]?.pagination?.total || 0);
        }
    }, [onTotalCount, presetData]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingInitialData);
        }
    }, [isLoadingInitialData, setIsLoading]);

    return variant === 'component' ?
        (
            <div className="spacing__window--horizontal">
                <TrackListCreatePresets
                    mutateSound={mutatePreset}
                    list={presets}
                    isLoading={isLoading || isLoadingInitialData}
                    title={title}
                    showTagFilter
                    tags={possibleTagsString}
                    selectedTags={tags}
                    onToggleTag={(tag, on) => {
                        if (on) {
                            setTags([...tags, tag]);
                        } else {
                            setTags(tags.filter((t) => t !== tag));
                        }
                    }}
                    onSeeMorePressed={!isLastPage ? handleLoadMoreSongs : undefined}

                />
            </div>
        ) :
        (
            <TrackListCreatePresets
                mutateSound={mutatePreset}
                list={presets}
                hideHeading
                automatedLoadMore
                isLoading={isLoading || isLoadingInitialData}
                onLoadMore={handleLoadMoreSongs}
                isLoadingMore={isLoading || isLoadingMore}
            />
        );
}
