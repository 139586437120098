import { useRouter } from 'next/router';
import { MusicView } from '../../music-view/music-view';

export function GenrePacksMore() {
    const router = useRouter();

    const {
        subgenre,
        slug
    } = router.query as { [key: string]: string | string[]; subgenre: string; slug: string };

    return (
        <MusicView title="Newest Packs" type="packs" createQuery={{ genre: subgenre ? undefined : slug }} variant="fullscreen" />
    );
}
