import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import { appendQueryParams, createAppRoutes } from '@bpm-web-app/utils';

import CreateListGridItem from '../shared/list-grid-item/create-list-grid-item';
import ListGrid from '../shared/ui/list-grid/list-grid';
import { LoadMore } from '../shared/load-more/load-more';

type Props = {
    data: SoundPackage[];
    onLoadMore?: () => void;
};

export const PackItemList = ({ data, onLoadMore }: Props) => {
    if (!data?.length) return null;

    return (
        <ListGrid>
            {data?.map((item) => (
                <CreateListGridItem
                    key={item.id}
                    id={item.id}
                    title={item.name}
                    imageUrl={appendQueryParams(item.artwork_url, { key: 'dw', value: 56 })}
                    imageUrl2x={appendQueryParams(item.artwork_url, { key: 'dw', value: 112 })}
                    subtitle={item.Genre.name}
                    link={createAppRoutes.packsSlug(item.slug)}
                    contentType="pack"
                    secondPlayableDemo={item.demo_file_url_2}
                />
            ))}
            {onLoadMore && <LoadMore onLoadMore={onLoadMore} />}
        </ListGrid>
    );
};
