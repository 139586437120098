import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { showToast } from '@bpm-web-app/utils';
import { toast } from 'react-toastify';
import { joiResolver } from '@hookform/resolvers/joi';
import { SubmitHandler, useForm } from 'react-hook-form';
import Joi from 'joi';
import { CreateCuratedSet, CuratedCategory, Drive } from '@bpm-web-app/create-api-sdk';
import { Admin, apiKey } from '@bpm-web-app/api-client';
import { ApplicationSectionTitle } from '../../pages/artist-portal/artist-application/artist-application-text/artist-application-text';
import { ArtistApplicationInputField } from '../../pages/artist-portal/artist-application/artist-application-inputfield/artist-application-inputfield';
import { SquaredButton } from '../squared-button/squared-button';
import { FileUploader } from '../file-upload/file-uploader';
import styles from './quality-control.module.css';
import { ActionModal } from '../action-modal/action-modal';
import { TagsView } from '../../tags-view/tags-view';

interface QualityControlPublishFormProps {
    curatedSetData?: CreateCuratedSet;
    driveData?: Drive;
    qualityControlPublishFormRef?: MutableRefObject<HTMLFormElement>;
    setHasCompletedQualityControlPublishForm: (data: CreateCuratedSet) => void;
}

export function QualityControlPublishForm({
    curatedSetData,
    driveData,
    qualityControlPublishFormRef,
    setHasCompletedQualityControlPublishForm,
}: QualityControlPublishFormProps) {
    const { getCuratedCategories, createCuratedSet } = Admin;
    const [showCategories, setShowCategories] = useState(false);
    const [categories, setCategories] = useState<CuratedCategory[]>([]);
    const [currentCategory, setCurrentCategory] = useState<CuratedCategory>();

    const getAllCategories = useCallback(() => {
        getCuratedCategories().then((res) => {
            if (res.data && res.data.data) setCategories(res.data.data);

            return res.data;
        }).catch((e) => {
            showToast({ type: 'error', title: 'Something went wrong fetching tags.' });
            return [];
        });
    }, [getCuratedCategories]);

    const schemaDJVerification = Joi.object({
        slug: Joi.string().required().messages({ 'string.empty': 'Slug is required.' }),
        name: Joi.string().required().messages({ 'string.empty': 'Name is required.' }),
        description: Joi.string().required().messages({ 'string.empty': 'Description is required.' }),
        artwork_url: Joi.string().required().messages({ 'string.empty': 'A Curated Set Artwork is required.' }),
        demo_file_url: Joi.object().optional().custom((value, helper) => {
            if (value && value.mp3) {
                return value;
            }
            return helper.error('invalid.message');
        }).messages({ 'invalid.message': 'Audio demo upload is required.', 'string.empty': 'artwork_url is required.' }),
        video_url: Joi.string().optional().allow(null, ''),
        drive_id: Joi.string().required().messages({ 'string.empty': 'drive_id is required.' }),
        category_id: Joi.string().optional().messages({ 'string.empty': 'Category selection is required.' }),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
        getValues,
        setValue
    } = useForm<CreateCuratedSet>({
        defaultValues: useMemo(
            () => ({
                slug: curatedSetData && curatedSetData.slug ? curatedSetData.slug : '',
                name: curatedSetData && curatedSetData.name ? curatedSetData.name : driveData ? driveData.name : '',
                description: curatedSetData && curatedSetData.description ? curatedSetData.description : '',
                artwork_url: driveData && driveData.custom_image_url ? driveData.custom_image_url : driveData && driveData.image_url ? driveData.image_url : curatedSetData && curatedSetData.artwork_url ? curatedSetData.artwork_url : '',
                demo_file_url: curatedSetData && curatedSetData.demo_file_url ? curatedSetData.demo_file_url : undefined,
                video_url: curatedSetData && curatedSetData.video_url ? curatedSetData.video_url : '',
                drive_id: curatedSetData && curatedSetData.drive_id ? curatedSetData.drive_id : driveData ? driveData.id : '',
                category_id: curatedSetData && curatedSetData.category_id ? curatedSetData.category_id : '',
            }),
            [curatedSetData, driveData]
        ),
        resolver: joiResolver(schemaDJVerification),
    });

    useEffect(() => {
        Object.keys(errors).forEach((err) => {
            showToast({ type: 'error', message: (errors as any)[err].message || 'An error has occurred. Please try again.' });
        });
        if (Object.keys(errors).length === 0) {
            toast.dismiss();
        }
    }, [errors]);

    const handlerQualityControlForm: SubmitHandler<CreateCuratedSet> = useCallback((data) => {
        createCuratedSet(data);
        setHasCompletedQualityControlPublishForm({
            slug: data.slug,
            name: data.name,
            description: data.description,
            artwork_url: data.artwork_url,
            demo_file_url: data.demo_file_url,
            video_url: data.video_url,
            drive_id: data.drive_id,
            category_id: data.category_id
        });
        showToast({ type: 'success', title: 'Successfully published curated set.' });
    }, [createCuratedSet, setHasCompletedQualityControlPublishForm]);

    const uploadArtwork = useCallback(async (uploadFiles: File) => {
        const formData = new FormData();
        formData.append('image', uploadFiles, uploadFiles.name);

        const key = apiKey();
        const headers = {} as { [key: string]: string };
        if (key) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            headers['Authorization'] = key;
        }
        // eslint-disable-next-line @typescript-eslint/dot-notation
        fetch(`${process.env['NEXT_PUBLIC_CREATE_API_BASE_PATH']}/admin/curated/image/temp`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers
        }).then(async (res) => {
            if (res.ok === true) {
                if (res.json) {
                    const jsonResponse = await res.json();
                    if (jsonResponse && jsonResponse.data && jsonResponse.data.url) {
                        setValue('artwork_url', jsonResponse.data.url);
                        showToast({ type: 'success', title: 'Uploaded new Curated Set cover image.' });
                    }
                }
            } else if (res.json) {
                const jsonError = await res.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return [];
        }).catch(async (e) => {
            if (e.message) showToast({ type: 'error', title: e.message });

            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return [];
        });
    }, [setValue]);

    const uploadAudio = useCallback(async (uploadFiles: File) => {
        const formData = new FormData();
        formData.append('file', uploadFiles, uploadFiles.name);

        const key = apiKey();
        const headers = {} as { [key: string]: string };
        if (key) {
            // eslint-disable-next-line @typescript-eslint/dot-notation
            headers['Authorization'] = key;
        }
        // eslint-disable-next-line @typescript-eslint/dot-notation
        fetch(`${process.env['NEXT_PUBLIC_CREATE_API_BASE_PATH']}/admin/curated/mp3/temp`, {
            method: 'POST',
            credentials: 'include',
            body: formData,
            headers
        }).then(async (res) => {
            if (res.ok === true) {
                if (res.json) {
                    const jsonResponse = await res.json();

                    if (jsonResponse && jsonResponse.data && jsonResponse.data.url) {
                        setValue('demo_file_url', { mp3: `${jsonResponse.data.url}`, hls: '' });
                        showToast({ type: 'success', title: 'Uploaded new Curated Set audio demo.' });
                    }
                }
            } else if (res.json) {
                const jsonError = await res.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return [];
        }).catch(async (e) => {
            if (e.message) showToast({ type: 'error', title: e.message });

            if (e.json) {
                const jsonError = await e.json();
                if (jsonError && jsonError.message) showToast({ type: 'error', title: jsonError.message });
            }
            return [];
        });
    }, [setValue]);

    const QualityControlForm = useMemo(() => {
        return (
            <form ref={qualityControlPublishFormRef} onSubmit={handleSubmit(handlerQualityControlForm)} className={styles['quality-control-form']}>
                <ApplicationSectionTitle title="Curated Set slug" noMargin />

                <ArtistApplicationInputField
                    placeholder="Enter a Curated Set URL slug"
                    inputProps={{ ...register('slug', { required: true }) }}
                    errorMessage={errors && errors.slug ? errors.slug.message : undefined}
                />

                <ApplicationSectionTitle title="Curated Set name" />

                <ArtistApplicationInputField
                    placeholder="Enter a Curated Set name"
                    inputProps={{ ...register('name', { required: true }) }}
                    errorMessage={errors && errors.name ? errors.name.message : undefined}
                />

                <ApplicationSectionTitle title="Curated Set description" />

                <ArtistApplicationInputField
                    multiline
                    placeholder="Enter a Curated Set description"
                    inputProps={{ ...register('description', { required: true }) }}
                    errorMessage={errors && errors.description ? errors.description.message : undefined}
                />

                <ApplicationSectionTitle title="Curated Set artwork" />

                <FileUploader
                    key="artwork"
                    hint="JPG / PNG file formats. 5MB File Limit."
                    accept="image"
                    filename={getValues('artwork_url')}
                    setPreviewFile={uploadArtwork}
                    errorMessage={errors && errors.artwork_url ? errors.artwork_url.message : undefined}
                />

                {getValues('artwork_url') ? (
                    <ArtistApplicationInputField
                        placeholder="artwork_url"
                        inputProps={{ ...register('artwork_url', { required: true }) }}
                    />
                ) : null}

                <ApplicationSectionTitle title="Curated Set audio demo" />

                <FileUploader
                    key="artwork"
                    hint="WAV/MP3 file format. 5MB File Limit."
                    accept="audio"
                    filename={getValues('demo_file_url.mp3')}
                    setPreviewFile={uploadAudio}
                    errorMessage={errors && errors.demo_file_url ? 'The audio demo upload is required.' : undefined}

                />

                {getValues('demo_file_url') ? (
                    <ArtistApplicationInputField
                        placeholder="demo_file_url"
                        inputProps={{ ...register('demo_file_url.mp3', { required: true }) }}
                    />

                ) : null}
                <div className="spacing--bottom-half" />
                <ApplicationSectionTitle title="Video URL" noMargin />

                <ArtistApplicationInputField
                    placeholder="Optional video URL"
                    inputProps={{ ...register('video_url', { required: false }) }}
                    errorMessage={errors && errors.video_url ? errors.video_url.message : undefined}
                />
                <div className="spacing--bottom-half" />

                <ApplicationSectionTitle title="Curated Set Category" noMargin />

                <SquaredButton
                    label={currentCategory?.name || 'Select Category'}
                    type="outline"
                    fullWidth
                    onPress={() => {
                        setShowCategories(true);
                        getAllCategories();
                    }} />

                <div className="spacing--bottom" />
                <div className="spacing--bottom" />

                <SquaredButton label="Submit" type="filled" buttonType="submit" fullWidth />

            </form>
        );
    }, [qualityControlPublishFormRef, handleSubmit, handlerQualityControlForm, register, errors, getValues, uploadArtwork, uploadAudio, currentCategory?.name, getAllCategories]);

    return (
        <>
            {QualityControlForm}
            <ActionModal
                headerTitle="Select Category ID"
                confirmButtonText="Continue"
                hideCancel
                onClose={() => {
                    setShowCategories(false);
                }}
                variant="dark"
                isOpen={showCategories}
            >
                <div>
                    <TagsView
                        className={styles['quality-control-input--tags-container']}
                        tags={categories.map((e) => e.name)}
                        selected={[]}
                        onToggleTag={(tag: string, toggleOn: boolean) => {
                            const categoryToAdd = categories.filter((e) => e.name === tag);

                            if (categoryToAdd.length > 0) {
                                setValue('category_id', categoryToAdd[0].id);
                                setCurrentCategory(categoryToAdd[0]);
                                setShowCategories(false);
                            }
                        }}
                    />
                </div>
            </ActionModal>
        </>);
}
