import { CreateSearchPaginatedQuery, CuratedSet } from '@bpm-web-app/api-client';
import useSWRInfinite from 'swr/infinite';

export function useInfiniteSearchCuratedSet(query: CreateSearchPaginatedQuery, enabled = true) {
    const pageSize = query?.limit || 50;

    const { data, error, size, setSize, isValidating } = useSWRInfinite(
        (index) => [`create-search-curated-set-${JSON.stringify(query)}-${index}`],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return enabled
                ? CuratedSet.searchCuratedSets({
                    ...query,
                    skip: +pageIndex * pageSize,
                })
                : null;
        }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
    };
}
