import { useGetSectionByName } from '@bpm-web-app/swr-hooks';
import { createAppRoutes } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { SeeMoreLayout } from '../../see-more-layout/see-more-layout';

export type SectionNamePageParams = {
    sectionName: string;
    location: string
    type: Parameters<typeof createAppRoutes.section>[1];
};

export function SectionNamePacks() {
    const router = useRouter();
    const { sectionName, type, location } = router.query as SectionNamePageParams;
    const { data: sectionData } = useGetSectionByName(sectionName as string, location);

    return (
        <SeeMoreLayout
            contentType={type === 'curated' ? 'curated-set' : 'pack'}
            pageTitle={`${sectionName}`}
            count={sectionData?.items?.length}
            itemBasePath={type === 'curated' ? createAppRoutes.curated : createAppRoutes.packs}
            data={sectionData?.items}
        />
    );
}
