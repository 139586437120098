import { ReactElement, useEffect, useState } from 'react';
import classNames from 'classnames';
import styles from './custom-text-field.module.css';

interface CustomTextFieldProps {
    placeholder?: string;
    text?: string;
    setText?: (txt: string) => void;
    hasError?: boolean;
    rightIcon?: ReactElement;
    onEnterPress?: () => void;
}

export function CustomTextField({
    placeholder,
    text,
    setText,
    hasError,
    rightIcon,
    onEnterPress
}: CustomTextFieldProps) {
    const [hasErrors, setHasErrors] = useState(false);

    useEffect(() => {
        setHasErrors(!!hasError);
    }, [hasError]);

    return (
        <div
            className={classNames(styles['custom-text-field'], { [styles['custom-text-field--has-errors']]: hasErrors })}
            id={placeholder}
            key={placeholder}
        >
            <input
                placeholder={placeholder}
                className={classNames(styles['custom-text-field--text'], { [styles['custom-text-field--error-text']]: hasError })}
                value={text}
                onChange={(e) => setText ? setText(e.target.value) : null}
                onKeyDown={(event) => {
                    if (event.key === 'Enter' && onEnterPress) {
                        onEnterPress();
                    }
                }}
            />
            {rightIcon ?
                <div className={styles['custom-text-field--right-icon']}>
                    {rightIcon}
                </div>
                : null
            }
        </div>
    );
}
