import { FC, useState } from 'react';

import { useDebounce } from '@bpm-web-app/utils';
import { Account, AccountPageProps } from '../account';
import { PageHeader } from '../components/page-header/page-header';
import { Downloads } from './downloads/downloads';
import styles from './downloads-history.module.css';
import { SearchInput } from './search-input/search-input';
import { DownloadsCreate } from './downloads/downloads-create';

export const AccountDownloadsHistory: FC<AccountPageProps & { isCreate?: boolean }> = ({ title, isCreate }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const debouncedSearchQuery = useDebounce(searchQuery, 300);

    return (
        <Account title={title} wide>
            <PageHeader title={title}>
                <div className={styles['downloads-history__search-desktop']}>
                    <SearchInput
                        placeholder={isCreate ? 'Search Sounds' : 'Search Tracks'}
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                </div>
            </PageHeader>

            <div className={styles['downloads-history__container']}>
                <div className={styles['downloads-history__search-mobile']}>
                    <SearchInput
                        placeholder={isCreate ? 'Search Sounds' : 'Search Tracks'}
                        value={searchQuery}
                        onChange={(e) => {
                            setSearchQuery(e.target.value);
                        }}
                    />
                </div>

                {isCreate ? <DownloadsCreate searchQuery={debouncedSearchQuery} /> : <Downloads searchQuery={debouncedSearchQuery} />}
            </div>
        </Account>
    );
};
