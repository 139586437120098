import { MouseEvent } from 'react';
import BPMIcons from '../bpm-icons/bpm-icons';
import { LoadingIcon } from '../loading-icon/loading-icon';
import { SquaredButton } from '../squared-button/squared-button';

interface UploadButtonProps {
    type: 'dynamic' | 'outline';
    isLoading?: boolean;
    onPress: (e: MouseEvent<HTMLElement>) => void;
    fullWidth?: boolean
}

export function UploadButton({ type, isLoading, onPress, fullWidth }: UploadButtonProps) {
    return !isLoading ?
        (
            <SquaredButton leftIcon={<BPMIcons.ShareArrowIcon />} type={type} label="Publish" onPress={onPress} fullWidth={fullWidth} />
        )
        :
        (
            <SquaredButton leftIcon={<LoadingIcon isDark={type === 'dynamic'} />} type={type} label="Publishing" onPress={onPress} fullWidth={fullWidth} />
        );
}
