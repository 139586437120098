import { useGetDownloadedSoundPackages } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, DEFAULT_SOUNDS_LIMIT } from '@bpm-web-app/utils';
import { SeeMoreLayout } from '../../see-more-layout/see-more-layout';

export const AccountDownloadsPacks = () => {
    const { data: soundPackages } = useGetDownloadedSoundPackages(
        {
            limit: DEFAULT_SOUNDS_LIMIT,
        },
        true
    );
    return (
        <SeeMoreLayout
            contentType="pack"
            pageTitle="Packs"
            count={soundPackages?.pagination?.total || 0}
            itemBasePath={createAppRoutes.packs}
            data={soundPackages?.data}
        />
    );
};
