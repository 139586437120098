import { UserPlaylistCollaboration, UserPlaylistWithAlbumOwner } from '@bpm-web-app/download-api-sdk';
import { UserDriveCollaboration } from '@bpm-web-app/create-api-sdk';
import classNames from 'classnames';
import { CollaborationUserImage, CollaborationUserImageSize } from './collaboration-user-image';
import styles from './collaboration.module.css';

interface CollaborationUsersProps {
    imageSize?: CollaborationUserImageSize;
    owner?: UserPlaylistWithAlbumOwner;
    collaborators: UserPlaylistCollaboration[] | UserDriveCollaboration[];
    onClick?: () => void;

}

export function CollaborationUsers({ imageSize, owner, collaborators, onClick }: CollaborationUsersProps) {
    const renderImages = () => {
        return (
            <div className={styles['collaboration-users__images']}>
                <div className={styles['collaboration-users__image-0']}>
                    <CollaborationUserImage imageUrl={owner?.profile_image_thumbnail_url} size={imageSize} />
                </div>
                {collaborators.slice(0, 3).map((collaborator, index) => {
                    return (
                        <div key={collaborator.id} className={styles[`collaboration-users__image-${index + 1}`]}>
                            <CollaborationUserImage imageUrl={collaborator.profile_image_thumbnail_url} size={imageSize} />
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderText = () => {
        return (
            <div className={classNames(styles['collaboration-modal__row-title'])}>
                {collaborators.length === 0 ? `${owner?.full_name}` : `${owner?.full_name} + ${collaborators.length} Others`}
            </div>
        );
    };
    return (
        <button
            type="button"
            onClick={onClick}
            className={styles['collaboration-users']}>
            {renderImages()}
            {renderText()}
        </button>
    );
}
