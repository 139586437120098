import useSWR from 'swr';
import { Media, SearchPaginatedQuery } from '@bpm-web-app/api-client';
import useSWRInfinite from 'swr/infinite';

export function useSearchMedia(query: SearchPaginatedQuery) {
    const opts = {
        persist: false,
        ...query,
    };

    const { data, error, mutate } = useSWR(['stream-search-media', opts], () => Media.searchMedia(query));

    return {
        data,
        mutate,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteSearchMedia(query: SearchPaginatedQuery, enabled = true) {
    const pageSize = query?.limit || 50;

    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
        (index) => [`stream-search-media-${JSON.stringify(query)}-${index}`],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            return enabled
                ? Media.searchMedia({
                    ...query,
                    skip: +pageIndex * pageSize,
                })
                : null;
        }
    );

    const isLoadingInitialData = enabled ? !data && !error : false;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}
