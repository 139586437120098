import { CustomIcon } from '../../shared/custom-icon/custom-icon';
import styles from './header-volume-button.module.css';

interface HeaderVolumeButtonProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    volume?: number;
    isOpen?: boolean;
}

export function HeaderVolumeButton({ onClick, volume, isOpen }: HeaderVolumeButtonProps) {
    return (
        <CustomIcon
            type={volume ? 'volume-icon' : 'volume-mute-icon'}
            isIconHoverActive={isOpen}
            hasIconHover
            hasBackgroundHover
            size="regular"
            container="round"
            containerSize="regular"
            tooltip="Volume"
            hasActiveState
            onClick={onClick}
            className={styles['header-volume-button']}
            tooltipBottomOffset={-4}
        />
    );
}
