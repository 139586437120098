import { Giveaway } from '@bpm-web-app/api-client';
import { useInfiniteGiveawayComments } from '@bpm-web-app/swr-hooks';
import { useApiErrorHandler, showToast } from '@bpm-web-app/utils';

export const useGiveaway = (giveawayId: string, disabled: boolean) => {
    const { data: comments, mutate: mutateComments } = useInfiniteGiveawayComments({ id: giveawayId, limit: 10 }, disabled);
    const errorHandler = useApiErrorHandler();

    const postGiveawayComent = async (commentText: string, setComment: (value: string) => void) => {
        try {
            const comment = await Giveaway.postGiveawayComent(giveawayId, { comment: commentText });
            showToast({ type: 'success', title: 'Success' });

            setComment('');

            const newComments = comments.map((commentPage, index) => {
                if (index === 0) {
                    const page = { ...commentPage };
                    page.data = [comment.data, ...commentPage.data];
                    return page;
                }
                return commentPage;
            });

            mutateComments(newComments);
        } catch (error) {
            errorHandler({ error });
        }
    };

    const editGiveawayComent = async (commentId: string, commentText: string) => {
        try {
            const comment = await Giveaway.editGiveawayComent(commentId, { comment: commentText });

            const newComments = comments.map((commentPage) => {
                const page = { ...commentPage };
                page.data.map((commentObj) => (commentObj.id === comment.data.id ? comment.data : commentObj));
                return page;
            });

            mutateComments(newComments);
            showToast({ type: 'success', title: 'Success' });
        } catch (error) {
            errorHandler({ error });
        }
    };

    const enterGiveaway = async (zip: string, ref?: string) => {
        try {
            const entry = await Giveaway.enterGiveaway(giveawayId, { zip, referral: ref });
            showToast({ type: 'success', title: 'Success' });
            return entry;
        } catch (error) {
            errorHandler({ error });
            throw error;
        }
    };

    return {
        postGiveawayComent,
        editGiveawayComent,
        enterGiveaway,
    };
};

export default useGiveaway;
