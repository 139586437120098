import useSWR from 'swr';
import { Playlist, SearchPlaylistsSetsQuery } from '@bpm-web-app/api-client';
import { useHubSwitch } from '@bpm-web-app/utils';

export function useSearchPlaylistSets(query: SearchPlaylistsSetsQuery) {
    const { isDownload } = useHubSwitch();
    const { data, error } = useSWR(['search-playlists-sets', query, isDownload], () => Playlist.searchPlaylistSets(isDownload, query));

    return {
        data,
        isLoading: !error && !data,
        error,
    };
}
