import { Section, SoundPackage } from '@bpm-web-app/create-api-sdk';
import { useInfiniteSearchPack } from '@bpm-web-app/swr-hooks';
import { convertSoundPackageToCarouselProps, createAppRoutes, DEFAULT_BROWSE_CARD_LIMIT, soundPackageToCreatePlayable } from '@bpm-web-app/utils';
import { memo, useMemo } from 'react';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';

type Props = {
    slug: string;
    sectionId: Section['id'];
    sectionName: Section['name'];
    isLoading?: boolean;
};

export const GenreSectionComponent = memo(({ slug, sectionId, sectionName, isLoading }: Props) => {
    const { data } = useInfiniteSearchPack({
        genre: slug,
        limit: DEFAULT_BROWSE_CARD_LIMIT,
        skip: 0,
    });

    const flatPackData = useMemo(() => data?.flatMap((paginated) => paginated?.data) || [], [data]);

    const { playDemo } = useCreatePlayer();

    const carouselData = useMemo(() => flatPackData?.map((pack) => convertSoundPackageToCarouselProps(pack)), [flatPackData]);

    if (!carouselData) return null;

    return (
        <CreateCardCarousel
            contentType="pack"
            key={sectionId}
            items={flatPackData as SoundPackage[]}
            cardSize="small"
            carouselTitle={sectionName}
            carouselMorePath={createAppRoutes.genresSlug(slug)}
            itemBasePath={createAppRoutes.packs}
            onCardPlay={(id) => playDemo(soundPackageToCreatePlayable(flatPackData?.find((item) => item.id === id)))}
            isLoading={isLoading}
        />
    );
});
