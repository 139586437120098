import 'intersection-observer';

import 'focus-visible/dist/focus-visible.min';
import 'react-toastify/dist/ReactToastify.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/grid';
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import '@bpm-web-app/components/src/styles/index.css';
import '../styles/index.css';

import {
    AppNotifications,
    AppToast,
    CreateHeader,
    CreatePlayer,
    CreatePlayerProvider,
    FiltersContext,
    FiltersProvider,
    NavContext,
    NavCreate,
    NavProvider,
} from '@bpm-web-app/components';
import { SWRConfigProvider } from '@bpm-web-app/swr-hooks';
import { HistoryProvider, HubSwitchProvider, useWindowResizing, ViewportProvider, registerServiceWorker, startBugsnag, useViewport, Analytics, setGiveawayCookie, UserSettingsProvider, setCouponStorage, DownloadPreviewProvider, showToast } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { AppProps } from 'next/app';
import Head from 'next/head';
import ReactTooltip from 'react-tooltip';

import { FC, useEffect } from 'react';
import Modal from 'react-modal';
import { CreateThreeDotsSheet, CreateThreeDotsSheetProvider, CreateThreeDotsSheetContext, SessionProvider, ActionModalProvider, ContextMenuProvider, ContextMenuContext, ContextMenu, QualityControlProvider, DeviceVerification, SignUpModal, SubscriptionInfoModal, Loader, DownloadPreviewModal } from '@bpm-web-app/components/shared';
import { ErrorMessage } from '@bpm-web-app/components/src/lib/pages';
import Script from 'next/script';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { DraggableComponent } from '@bpm-web-app/components/src/lib/shared/draggable-component/draggable-component';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import TiktokPixel from 'tiktok-pixel';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/dot-notation
const ErrorBoundary = startBugsnag(process.env['NEXT_PUBLIC_BUGSNAG_CREATE_API_KEY']!);

// eslint-disable-next-line react/jsx-no-useless-fragment
const AppContext: FC<{ isAccount: boolean }> = ({ children, isAccount }) => isAccount ? <>{children}</> : (
    <ContextMenuProvider>
        <QualityControlProvider>
            <CreateThreeDotsSheetProvider>
                <ViewportProvider>
                    <DndProvider backend={HTML5Backend}>
                        <DraggableComponent />
                        {children}
                        <NavCreate />
                    </DndProvider>
                </ViewportProvider>
            </CreateThreeDotsSheetProvider>
        </QualityControlProvider>
    </ContextMenuProvider>
);

function App({ Component, pageProps }: AppProps) {
    useWindowResizing(
        () => {
            document.body.classList.add('isResizing');
        },
        () => {
            document.body.classList.remove('isResizing');
        },
        []
    );

    useEffect(() => {
        Analytics.initialize();
        TiktokPixel.init('CMNE1ARC77UF4ULPFOTG', {}, { debug: false });
        registerServiceWorker();
        setGiveawayCookie();
        const code = setCouponStorage();
        if (code) {
            showToast({
                type: 'success',
                message: `Promo code ${code} has been applied! Your discount will be reflected at checkout.` as any
            });
        }
    }, []);

    useEffect(() => {
        document.body.classList.add('is-create');
    }, []);

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        Modal.setAppElement(document.getElementById('__next')!);
    }, []);

    const { isMobile } = useViewport();

    const { isReady, pathname } = useRouter();
    const isAccount = pathname.startsWith('/account');

    if (!isReady) {
        return <Loader />;
    }

    return (
        <ErrorBoundary FallbackComponent={ErrorMessage}>
            <Head>
                <title>BPM Create</title>
                <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
                <link rel="manifest" href="/site.webmanifest" />
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#1af085" />
                <meta name="msapplication-TileColor" content="#1af085" />
                <meta name="theme-color" content="#121212" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
                <script type="text/javascript" async src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=Ynf4t6" />
            </Head>
            <HistoryProvider>
                <ActionModalProvider>
                    <UserSettingsProvider>
                        <DownloadPreviewProvider>
                            <SWRConfigProvider isCreate>
                                <SessionProvider>
                                    <HubSwitchProvider isCreate>
                                        <CreatePlayerProvider>
                                            <NavProvider>
                                                <AppContext isAccount={isAccount}>
                                                    <FiltersProvider>
                                                        <DeviceVerification platform="create" />
                                                        <SubscriptionInfoModal isCreate />
                                                        <SignUpModal platform="create" />
                                                        <DownloadPreviewModal platform="create" />
                                                        <CreateHeader />
                                                        <ContextMenuContext.Consumer>
                                                            {(value) => {
                                                                return (value.renderLocation === 'app' || value.renderLocation === 'action-modal' || value.renderLocation === 'sidebar' ? <ContextMenu /> : null);
                                                            }}
                                                        </ContextMenuContext.Consumer>
                                                        <CreateThreeDotsSheetContext.Consumer>
                                                            {(value) => (value.actionTypeId !== -1 && value.renderLocation === 'app' ? <CreateThreeDotsSheet /> : null)}
                                                        </CreateThreeDotsSheetContext.Consumer>
                                                        <FiltersContext.Consumer>
                                                            {(filterCtx) => (
                                                                <NavContext.Consumer>
                                                                    {(navCtx) => (
                                                                        <main
                                                                            id="main"
                                                                            className={classNames('main', {
                                                                                'main--nav-open': navCtx.isNavOpen,
                                                                                'main--nav-hidden': navCtx.isNavHidden,
                                                                                'main--filters-open': filterCtx.isFiltersOpen,
                                                                            })}
                                                                        >
                                                                            <Script
                                                                                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GTM_ID}`}
                                                                                strategy="afterInteractive"
                                                                            />
                                                                            <Script strategy="afterInteractive">
                                                                                {`window.dataLayer = window.dataLayer || [];
                                                                                    function gtag(){dataLayer.push(arguments);}
                                                                                    gtag('js', new Date());
                                                                                    gtag('config', '${process.env.NEXT_PUBLIC_GTM_ID}');
                                                                                `.trim()}
                                                                            </Script>
                                                                            <Script id="facebook-analytics" strategy="afterInteractive">
                                                                                {`
                                                                                    !function (f, b, e, v, n, t, s) {
                                                                                        if (f.fbq)
                                                                                        return;
                                                                                        n = f.fbq = function () {
                                                                                        n.callMethod ?
                                                                                            n.callMethod.apply(n, arguments) : n.queue.push(arguments)
                                                                                        };
                                                                                        if (!f._fbq)
                                                                                        f._fbq = n;
                                                                                        n.push = n;
                                                                                        n.loaded = !0;
                                                                                        n.version = '2.0';
                                                                                        n.queue = [];
                                                                                        t = b.createElement(e);
                                                                                        t.async = !0;
                                                                                        t.src = v;
                                                                                        s = b.getElementsByTagName(e)[0];
                                                                                        s.parentNode.insertBefore(t, s)
                                                                                    }(window,
                                                                                        document, 'script', ' https://connect.facebook.net/en_US/fbevents.js');

                                                                                    fbq('init', '${process.env.NEXT_PUBLIC_FB_ID}');
                                                                                    fbq('track', "PageView");
                                                                                `.trim()}
                                                                            </Script>
                                                                            {!isAccount && <CreatePlayer />}
                                                                            <Component {...pageProps} />
                                                                        </main>
                                                                    )}
                                                                </NavContext.Consumer>
                                                            )}
                                                        </FiltersContext.Consumer>
                                                        {!isMobile ? <ReactTooltip
                                                            place="top"
                                                            effect="solid"
                                                            delayShow={400}
                                                        /> : null}
                                                        <AppNotifications />
                                                        <AppToast />
                                                    </FiltersProvider>
                                                </AppContext>
                                            </NavProvider>
                                        </CreatePlayerProvider>
                                    </HubSwitchProvider>
                                </SessionProvider>
                            </SWRConfigProvider>
                        </DownloadPreviewProvider>
                    </UserSettingsProvider>
                </ActionModalProvider>
            </HistoryProvider>
        </ErrorBoundary>

    );
}

export default dynamic(() => Promise.resolve(App), { ssr: false });
