export function showExitSurvey(platform: 'create' | 'supreme') {
    document.cookie = 'smcx_0_last_shown_at=; Max-Age=-99999999;';
    (window as any).SMCX = (window as any).SMCX || [];
    if (!document.getElementById('smcx-sdk')) {
        const n = document.getElementsByTagName('script');
        const c = n[n.length - 1];
        const l = document.createElement('script');
        l.type = 'text/javascript';
        l.async = !0;
        l.id = 'smcx-sdk';
        l.src = platform === 'create' ? 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd7lckyG_2BBvMGcmtmfwbTc9QXFFmXNOXb5VR8W4BqEWvJ.js' : 'https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgdyiYOX9AbktWTqwz_2FoVG09LosB_2FwPOfahqTthoYu6I2q.js';
        if (c.parentNode) c.parentNode.insertBefore(l, c);
    }
}
