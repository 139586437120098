import { MediaWithAlbum } from '@bpm-web-app/stream-api-sdk';
import { convertToPluralIfNeeded, downloadAlbumWithMediaToQueueItem, State, streamMediaWithAlbumToQueueItem, useHubSwitch, usePlayerState } from '@bpm-web-app/utils';
import { Album as DownloadAlbum } from '@bpm-web-app/download-api-sdk';
import { useCallback } from 'react';
import { usePlayer } from '../player-context';
import styles from './predictive-search.module.css';
import { RowComponent } from '../row-component/row-component';

export interface MediaProps {
    media?: MediaWithAlbum[] | DownloadAlbum[];
    setTerm: (term: string) => void;
    count?: number
}

export default function Media({ media, setTerm, count }: MediaProps) {
    const { setQueue, currentTrack, onPause } = usePlayer();
    const { isDownload } = useHubSwitch();
    const playerState = usePlayerState();

    const handleClick = (item: MediaWithAlbum | DownloadAlbum) => {
        if (isDownload) {
            setTerm(item.title);
        } else {
            setTerm(item.title);
        }
    };

    const isPlaying = useCallback((id: number) => {
        if (playerState !== State.Playing) {
            return false;
        }
        return currentTrack?.id === id;
    }, [playerState, currentTrack]);

    const handlePlaySong = useCallback((indexToPlay: number) => {
        const queueItems = isDownload
            ? (media as DownloadAlbum[]).map((album) => downloadAlbumWithMediaToQueueItem(album))
            : (media as MediaWithAlbum[]).map((song) => streamMediaWithAlbumToQueueItem(song));

        setQueue(queueItems, indexToPlay || 0);
    }, [isDownload, setQueue, media]);

    return (
        <>
            <div className={styles['predictive-search__row-container']}>
                <h4 className={styles['predictive-search__title']}>{convertToPluralIfNeeded(count || 0, 'Tracks', 'Tracks', true)}</h4>
            </div>
            <div>
                {media?.length === 0 ? (
                    <h5 className={styles['predictive-search__content-title']}>No items match your search</h5>
                ) : (
                    media?.map((item, index) => {
                        if (isDownload) {
                            const album = item as DownloadAlbum;
                            return <RowComponent
                                type="track"
                                id={album.id}
                                onClick={() => (handleClick(item))}
                                onCoverClick={() => isPlaying(album.id) ? onPause() : (handlePlaySong(index ?? 0))}
                                cover_url={album.cover_url}
                                isPlaying={isPlaying(album.id)}
                                title={album.title}
                                artist={album.artist}
                                artists={album.artists}
                                bpm={album.bpm}
                                display_key={album.display_key}
                                genre={album.genre && album.genre.name ? album.genre.name : undefined}
                                hasHover
                            />;
                        }
                        const song = item as MediaWithAlbum;
                        return (
                            <RowComponent
                                type="track"
                                id={song.id}
                                onClick={() => (handleClick(item))}
                                onCoverClick={() => isPlaying(song.id) ? onPause() : (handlePlaySong(index ?? 0))}
                                cover_url={song.album ? song.album.cover_url : undefined}
                                isPlaying={isPlaying(song.id)}
                                title={song.title}
                                artist={song.artist}
                                artists={song.artists}
                                bpm={song.bpm}
                                display_key={song.display_key}
                                genre={song.album ? song.album.genre.name : undefined}
                                hasHover
                            />
                        );
                    })
                )}
            </div>
        </>
    );
}
