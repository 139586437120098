import { useGetGiveaway } from '@bpm-web-app/swr-hooks';
import { showToast } from '@bpm-web-app/utils';
import { useCallback, useEffect, useState } from 'react';
import { supremeBasePath } from 'libs/api-client/src/lib/config';
import { GiveawayEntry } from '@bpm-web-app/supreme-api-sdk';
import styles from './enter-giveaway.module.css';
import { SquaredButton } from '../shared/squared-button/squared-button';

export interface EnterGiveawayProps {
    giveawayId: string;
    giveawayEntry?: GiveawayEntry;
}

export function EnterGiveaway({ giveawayId, giveawayEntry }: EnterGiveawayProps) {
    const { data, isLoading, error } = useGetGiveaway(giveawayId);
    const [disabledButtons, setDisabledButtons] = useState<boolean[]>([]);

    useEffect(() => {
        if (data?.GiveawayShareLinks) {
            const temp: boolean[] = [];
            data?.GiveawayShareLinks.forEach((currentItem) => {
                const linkDisabled = giveawayEntry?.share_links_used?.find((link) => link.toLocaleLowerCase() === currentItem?.slug.toLowerCase());
                if (linkDisabled) {
                    temp.push(true);
                } else {
                    temp.push(false);
                }
            });
            setDisabledButtons(temp);
        }
    }, [data?.GiveawayShareLinks, giveawayEntry?.share_links_used]);

    const copyUrl = useCallback((url: string) => {
        navigator.clipboard?.writeText(url);
        showToast({ type: 'success', message: 'Copied to clipboard.' });
    }, []);

    /* TODO: implement (ghost?) loading */
    if (isLoading) return null;

    if (!isLoading && error) return null;

    return (
        <ul>
            <li className={styles['enter-giveaway__detail']}>Want more chances to win? Complete the actions below to gain additional entries and increase your odds at winning!</li>
            {data?.GiveawayShareLinks.map((link, index) => (
                <li className={styles['enter-giveaway__entry']} key={link.id}>
                    <div className={styles['enter-giveaway__entry-text']}>{link.title}</div>
                    <div className={styles['enter-giveaway__entry-button']}>
                        <SquaredButton
                            type={disabledButtons[index] ? 'outline' : 'filled'}
                            label={link.score === 1 ? '+1 Entry' : `+${link.score} Entries`}
                            onPress={() => {
                                if (disabledButtons[index] === false) {
                                    window.open(`${supremeBasePath}/giveaway/${giveawayId}/${giveawayEntry?.share_token}/entry?type=${link.slug}`, '__blank');
                                } else {
                                    showToast({ type: 'info', title: 'You already claimed this entry.' });
                                }
                            }}
                            fullWidth
                        />
                    </div>
                </li>
            ))}
            <li className={styles['enter-giveaway__entry']}>
                <div className={styles['enter-giveaway__entry-text']}>Refer a friend</div>
                <div className={styles['enter-giveaway__entry-button']}>
                    <SquaredButton
                        type="filled"
                        label="+1 Entry"
                        onPress={() => {
                            // eslint-disable-next-line @typescript-eslint/dot-notation
                            copyUrl(`${process.env['NEXT_PUBLIC_SUPREME_PLATFORM_URL']}d/g/${giveawayEntry?.share_token}`);
                        }}
                        fullWidth
                    />
                </div>
            </li>
        </ul>
    );
}
