import { HLSPlayer } from '@bpm-web-app/utils';
import { useDrag } from '@use-gesture/react';
import React, { KeyboardEvent, MouseEvent, useCallback, useEffect, useState } from 'react';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';
import styles from './player-wave-create.module.css';

export function CreatePlayerWave() {
    const { elapsed, currentTrack, onSeek, togglePlayPause } = useCreatePlayer();

    const duration = HLSPlayer.getDuration();
    const [containerWidth, setContainerWidth] = useState(0);
    const elapsedPercentage = (elapsed / duration) * 100;

    const handleUserKeyPress = useCallback(
        (event) => {
            if (document.activeElement.className.includes('input')) {
                return;
            }

            const { key } = event;

            if (key === ' ') {
                event.preventDefault();
                togglePlayPause();
                return;
            }

            if (key === 'ArrowRight') {
                onSeek(Math.min(elapsed + 1, duration));
                return;
            }
            if (key === 'ArrowLeft') {
                onSeek(Math.max(elapsed - 1, 0));
            }
        },
        [duration, elapsed, onSeek, togglePlayPause]
    );

    useEffect(() => {
        window.addEventListener('keydown', handleUserKeyPress);

        return () => {
            window.removeEventListener('keydown', handleUserKeyPress);
        };
    }, [handleUserKeyPress]);

    const onClick = useCallback(
        (e: MouseEvent) => {
            e.stopPropagation?.();
            const { width, left } = e.currentTarget.getBoundingClientRect();
            const offset = e.clientX - left;
            let percentage = (offset / width) * 100;
            if (percentage < 0) percentage = 0;
            if (percentage > 100) percentage = 100;
            onSeek((duration / 100) * percentage);
        },
        [duration, onSeek]
    );

    const onKeyDown = useCallback(
        (e: KeyboardEvent) => {
            e.stopPropagation?.();
            if (e.key === 'ArrowRight') {
                onSeek(Math.min(elapsed + 1, duration));
                return;
            }
            if (e.key === 'ArrowLeft') {
                onSeek(Math.max(elapsed - 1, 0));
            }
        },
        [elapsed, duration, onSeek]
    );

    const dragEvents = useDrag(({ event, down }) => {
        event.stopPropagation?.();
        if (!down) return;
        onClick({
            clientX: (event as any).clientX,
            currentTarget: event.currentTarget,
        } as React.MouseEvent);
    });

    if (!currentTrack.demo_wave) return null;

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <div
            className={styles['player-wave']}
            ref={(ref) => {
                if (ref) {
                    setContainerWidth(ref.clientWidth);
                }
            }}
        >
            <div
                {...(dragEvents as any)()}
                role="progressbar"
                aria-valuenow={elapsedPercentage}
                tabIndex={0}
                aria-valuemin={0}
                aria-live="assertive"
                aria-valuemax={100}
                className={styles['player-wave__wrapper']}
                onClick={onClick}
                onKeyDown={onKeyDown}
            >
                <img className={styles['player-wave__duration']} src={currentTrack.demo_wave.gray} alt="" draggable={false} />

                <div
                    className={styles['player-wave__elapsed-wrapper']}
                    style={{
                        width: `${elapsedPercentage}%`,
                    }}
                >
                    <div
                        className={styles['player-wave__elapsed']}
                        style={{
                            width: `${containerWidth}px`,
                            backgroundImage: `url(${currentTrack.demo_wave.green})`,
                        }}
                    />
                </div>
            </div>
        </div>
    );
}
