import { useGetFreeCuratedSets, useGetFreeSoundPackages, useInfiniteSearchSound } from '@bpm-web-app/swr-hooks';
import {
    appendQueryParams,
    convertCuratedToCarouselProps,
    convertSoundPackageToCarouselProps,
    createAppRoutes,
    DEFAULT_BROWSE_CARD_LIMIT,
    soundPackageToCreatePlayable,
    getMutatedSounds,
    useViewport,
    useCreateFilterParams,
} from '@bpm-web-app/utils';
import { ComponentProps, Fragment, useCallback, useMemo } from 'react';
import CreateListGridItem from '@bpm-web-app/components/src/lib/shared/list-grid-item/create-list-grid-item';
import Link from 'next/link';
import classNames from 'classnames';
import { CreateCardCarousel } from '../../create-card-carousel/create-card-carousel';
import Filters from '../../filters/filters';
import { HorizontalTabs } from '../../shared/ui/horizontal-tabs/horizontal-tabs';
import { BreakpointView } from '../../shared/ui';
import { PackItemList } from '../../pack-item-list/pack-item-list';
import PrimaryPageTitle from '../../shared/primary-page-title/primary-page-title';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import { TrackListCreate } from '../../shared/track-list/create/track-list-create';
import styles from './free-sounds.module.css';
import { useCreatePlayer } from '../../../../../utils/src/lib/create-player.context';
import { ClickableGenres } from '../../clickable-genres/clickable-genres';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import SeeMore from '../../shared/see-more-button/see-more-btn';

export const FreeSounds = () => {
    const { isMobile } = useViewport();

    const { playDemo } = useCreatePlayer();

    const query = useCreateFilterParams({ only_free: true });

    const { data: freeSoundPacksData, isLoading: isPacksLoading } = useGetFreeSoundPackages({
        ...query,
        limit: DEFAULT_BROWSE_CARD_LIMIT,
    });

    const showSeeMorePacks = useMemo(() => (freeSoundPacksData?.pagination?.total || 0) > (freeSoundPacksData?.data?.length || 0), [freeSoundPacksData]);

    const { data: freeCuratedSetsData, isLoading: isCuratedPacksLoading } = useGetFreeCuratedSets({ ...query, limit: DEFAULT_BROWSE_CARD_LIMIT });

    const showSeeMoreCuratedPacks = useMemo(() => (freeCuratedSetsData?.pagination?.total || 0) > (freeCuratedSetsData?.data?.length || 0), [freeCuratedSetsData]);

    const {
        data: freeSoundsData,
        isLoadingInitialData,
        isLoadingMore,
        setSize,
        isLastPage,
        mutate,
    } = useInfiniteSearchSound({
        ...query,
    });

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(freeSoundsData, id, progress));
        },
        [mutate, freeSoundsData]
    );

    const freeSounds = useMemo(() => freeSoundsData?.flatMap((data) => data.data), [freeSoundsData]);

    const totalFreeSounds = useMemo(() => freeSoundsData?.[0]?.pagination?.total || 0, [freeSoundsData]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    const packsCarouselItems = useMemo(
        () => freeSoundPacksData?.data.map((data) => convertSoundPackageToCarouselProps(data, <ClickableGenres genres={data.Genre} />)) || [],
        [freeSoundPacksData?.data]
    );

    const curatedPacksCarouselItems = useMemo(() => freeCuratedSetsData?.data.map((data) => convertCuratedToCarouselProps(data)) || [], [freeCuratedSetsData?.data]);

    const mobileTabs = useMemo<ComponentProps<typeof HorizontalTabs>['tabs']>(
        () => [
            {
                id: 'packs',
                title: 'Packs',
                count: freeSoundPacksData?.pagination?.total || 0,
                activeTabComponent: (
                    <>
                        <PackItemList data={freeSoundPacksData?.data} />
                        {showSeeMorePacks ? (
                            <Link href={createAppRoutes.freeSoundsAllPacks}>
                                <SeeMore expand={false} variant="text" />
                            </Link>
                        ) : null}
                    </>
                ),
            },
            {
                id: 'curated-packs',
                title: 'Curated Packs',
                count: freeCuratedSetsData?.pagination?.total || 0,
                activeTabComponent: (
                    <>
                        <ListGrid>
                            {freeCuratedSetsData?.data?.map((curatedSet) => (
                                <Fragment key={curatedSet.id}>
                                    <CreateListGridItem
                                        id={curatedSet.id}
                                        title={curatedSet.name}
                                        subtitle={undefined}
                                        imageUrl={appendQueryParams(curatedSet.artwork_url, { key: 'dw', value: 160 })}
                                        imageUrl2x={appendQueryParams(curatedSet.artwork_url, { key: 'dw', value: 320 })}
                                        link={createAppRoutes.curatedSet(curatedSet.slug)}
                                        contentType="curated-set"
                                    />
                                </Fragment>
                            ))}
                        </ListGrid>
                        {showSeeMoreCuratedPacks ? (
                            <Link href={createAppRoutes.freeSoundsAllCuratedPacks}>
                                <SeeMore expand={false} variant="text" />
                            </Link>
                        ) : null}
                    </>
                ),
            },
            {
                id: 'sounds',
                title: 'Sounds',
                count: totalFreeSounds || 0,
                activeTabComponent: (
                    <div className={styles['free-sounds__mobile-track-list']}>
                        <TrackListCreate
                            mutateSound={mutateSound}
                            list={freeSounds}
                            hideHeading
                            isLoading={isLoadingInitialData}
                            onLoadMore={handleLoadMore}
                            isLoadingMore={isLoadingMore} />
                    </div>
                ),
            },
        ],
        [freeSoundPacksData, freeCuratedSetsData, isLoadingMore, freeSounds, isLoadingInitialData, handleLoadMore, totalFreeSounds, showSeeMorePacks, showSeeMoreCuratedPacks, mutateSound]
    );

    return (
        <div className={classNames(styles['free-sounds'], 'spacing--page-padding-mobile')}>
            <div className={styles['free-sounds__header']}>
                <PrimaryPageTitle title="Free Packs & Sounds" noPadding={isMobile} />
                {/* TODO(diogo-menezes) What's the filter role and influence in this page?? */}
                <Filters platform="create" showOnMobile />
            </div>
            <BreakpointView
                mobileChildren={
                    <div className={styles['free-sounds__horizontal-tabs-container']}>
                        <HorizontalTabs tabs={mobileTabs} />
                    </div>
                }
                desktopChildren={
                    <>
                        <CreateCardCarousel
                            contentType="pack"
                            items={packsCarouselItems}
                            cardSize="small"
                            carouselMorePath={showSeeMorePacks ? createAppRoutes.freeSoundsAllPacks : undefined}
                            isLoading={isPacksLoading}
                            carouselTitle="Packs"
                            onCardPlay={(id) => playDemo(soundPackageToCreatePlayable(freeSoundPacksData?.data.find((item) => item.id === id)))}
                            itemBasePath={createAppRoutes.packs}
                        />
                        <CreateCardCarousel
                            contentType="curated-set"
                            items={curatedPacksCarouselItems}
                            cardSize="small"
                            carouselMorePath={showSeeMoreCuratedPacks ? createAppRoutes.freeSoundsAllCuratedPacks : undefined}
                            isLoading={isCuratedPacksLoading}
                            carouselTitle="Curated Packs"
                            itemBasePath={createAppRoutes.curated}
                        />
                        <SecondaryPageTitle title="Sounds" counter={totalFreeSounds} />
                        <div className={classNames(styles['free-sounds__track-list'], 'spacing--horizontal', 'spacing--top')}>
                            <TrackListCreate mutateSound={mutateSound} list={freeSounds} isLoading={isLoadingInitialData} onLoadMore={handleLoadMore} />
                        </div>
                    </>
                }
            />
        </div>
    );
};
