import { useInfiniteSimilarSoundPackages } from '@bpm-web-app/swr-hooks';
import { createAppRoutes, DEFAULT_PACKS_LIMIT } from '@bpm-web-app/utils';
import { useCallback, useMemo } from 'react';
import { SeeMoreLayout } from '../../see-more-layout/see-more-layout';
import { LoadMore } from '../../shared/load-more/load-more';
import { PageNotFound } from '../page-not-found/page-not-found';
import Title from '../../title/title';

export interface SimilarPacksDetailProps {
    packIdOrSlug: string;
}

export function SimilarPacksDetail({ packIdOrSlug }: SimilarPacksDetailProps) {
    const {
        data: similarPacks,
        isLoadingMore,
        isLoadingInitialData,
        isLastPage,
        setSize,
        error,
    } = useInfiniteSimilarSoundPackages(packIdOrSlug, {
        limit: DEFAULT_PACKS_LIMIT,
    });

    /* TODO: fix typing when refactoring this to match create api v1.1 */
    const flatPacks = useMemo(() => similarPacks?.flatMap((data) => data.data.packs), [similarPacks]);

    const currentPack = useMemo(() => similarPacks?.[0]?.data.current, [similarPacks]);

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    if (packIdOrSlug === undefined || error) return <PageNotFound />;

    /* TODO: consider adding "no results" view */
    return (
        <>
            <Title platform="create" title="Similar Packs" />
            <SeeMoreLayout
                contentType="pack"
                pageTitle={currentPack ? `Similar Packs to ${currentPack?.name}` : undefined}
                itemBasePath={createAppRoutes.packs}
                data={flatPacks}
                loading={isLoadingInitialData || !similarPacks}
            />
            <LoadMore onLoadMore={handleLoadMore} />
        </>
    );
}

export default SimilarPacksDetail;
