import { useUserDownloadHistory } from '@bpm-web-app/swr-hooks';
import { getLoginRedirectLink, useUserSettings } from '@bpm-web-app/utils';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { NoResults } from '../no-results/no-results';
import { DownloadsList } from '../list/list';

interface DownloadsProps {
    searchQuery: string;
}

export function Downloads({ searchQuery }: DownloadsProps) {
    const pageSize = 10;
    const [currentPage, setCurrentPage] = useState(0);

    const { isAnonymous } = useUserSettings();
    const router = useRouter();

    useEffect(() => {
        if (isAnonymous) {
            router.replace(getLoginRedirectLink());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAnonymous]);

    const { data, mutate: mutateSupreme, isLoading } = useUserDownloadHistory(
        {
            limit: pageSize,
            skip: currentPage * pageSize,
            term: searchQuery,
        },
        true
    );

    const onPageChange = (page: number) => setCurrentPage(page);

    if (!data || !data.data.length) {
        return <NoResults text={searchQuery ? 'No downloads found' : 'No Downloads yet'} />;
    }

    return (
        <DownloadsList
            isCreate={false}
            pageSize={pageSize}
            onPageChange={onPageChange}
            data={data}
            activePage={currentPage}
            mutateSupreme={mutateSupreme}
        />
    );
}
