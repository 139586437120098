import { useEffect, useState } from 'react';

export const useCountdown = ({ minutes }: { minutes: number }) => {
    const [time, setTime] = useState(minutes * 60);

    useEffect(() => {
        const interval = setInterval(() => {
            setTime((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(interval);
    }, [setTime]);

    return time;
};
