import classNames from 'classnames';
import { useMemo, useState } from 'react';
import { Navigation, Swiper as SwiperConfig } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NoResultsBlock from '../no-results-block/no-results-block';

import styles from './horizontal-tabs.module.css';

type Tab = {
    id: string | number;
    title: string;
    count?: number;
    /** When null will render the no results component  */
    activeTabComponent: React.ReactNode | null;
    hideNoResults?: boolean;
};

interface HorizontalTabsProps {
    tabs: Tab[];
}
/** This is intended to be used in mobile view */
export function HorizontalTabs({ tabs }: HorizontalTabsProps) {
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
    const [, setSwiper] = useState<SwiperConfig>();

    const NoResults = useMemo(() => <NoResultsBlock verticalMargins={16}>{`No ${tabs?.[activeTabIndex].title} Available`}</NoResultsBlock>, [activeTabIndex, tabs]);

    return (
        <div className={styles['horizontal-tabs']}>
            <Swiper modules={[Navigation]} slidesPerView="auto" onSwiper={setSwiper} className={styles['horizontal-tabs__tabs-container']}>
                <div className={styles['horizontal-tabs__tabs-container--divider']} />
                <div className={styles['horizontal-tabs__tabs-container']}>
                    {tabs.map(({ id, title, count }, index) => (
                        <SwiperSlide className={styles['horizontal-tabs__tab-wrapper']} key={id}>
                            <button
                                type="button"
                                className={classNames(styles['horizontal-tabs__tab'], { [styles['horizontal-tabs__tab--active']]: activeTabIndex === index })}
                                onClick={() => setActiveTabIndex(index)}
                            >
                                {title}
                                <span>{` (${count})`}</span>
                            </button>
                        </SwiperSlide>
                    ))}
                </div>
            </Swiper>
            {tabs?.[activeTabIndex].count || tabs?.[activeTabIndex].hideNoResults === true ? tabs[activeTabIndex].activeTabComponent : NoResults}
        </div>
    );
}
