import { useContext } from 'react';
import { UserPlaylistCollaboration, UserPlaylistCollaborationAccessLevel, UserPlaylistCollaborationStatus } from '@bpm-web-app/download-api-sdk';
import { UserDriveCollaboration, UserDriveCollaborationStatus } from '@bpm-web-app/create-api-sdk';
import { User } from '@bpm-web-app/supreme-api-sdk';
import { CustomIcon } from '../custom-icon/custom-icon';
import { ContextMenuItemProps } from '../context-menu/types';
import { ContextMenuContext } from '../context-menu/context-menu.context';
import { CollaborationUserImage } from './collaboration-user-image';
import styles from './collaboration.module.css';
import { ContextMenuClickIgnoreClass } from '../context-menu/context-menu';

interface CollaborationUserItemProps {
    imageUrl?: string;
    currentUser?: User
    collaborator?: UserPlaylistCollaboration | UserDriveCollaboration;
    onRemovePressed?: (id: string) => void;
    isMyPlaylist?: boolean;
    isDisplayingOwner?: boolean;
    updateAccessLevel?: (inviteId: string, accessLevel: UserPlaylistCollaborationAccessLevel | UserPlaylistCollaborationAccessLevel) => void;
    isCreate?: boolean;
}

export function CollaborationUserItem({ imageUrl, currentUser,
    collaborator, onRemovePressed, isMyPlaylist = false,
    isDisplayingOwner = false, updateAccessLevel, isCreate }: CollaborationUserItemProps) {
    const { openContextMenu, closeOptions } = useContext(ContextMenuContext);

    const contextMenuOptions: ContextMenuItemProps[] = [
        {
            label: 'Editor',
            leftIcon: collaborator?.access_level === UserPlaylistCollaborationAccessLevel.Edit ? <CustomIcon type="checkmark" hasIconHover /> : <div />,
            onClick: () => {
                if (updateAccessLevel) updateAccessLevel(collaborator?.id || '', UserPlaylistCollaborationAccessLevel.Edit);
                closeOptions();
            }
        },
        {
            label: 'Viewer',
            leftIcon: collaborator?.access_level === UserPlaylistCollaborationAccessLevel.View ? <CustomIcon type="checkmark" hasIconHover /> : <div />,
            onClick: () => {
                if (updateAccessLevel) updateAccessLevel(collaborator?.id || '', UserPlaylistCollaborationAccessLevel.View);
                closeOptions();
            }

        },
        {
            type: 'divider',
        },
        {
            leftIcon: <CustomIcon type="circle-minus" hasIconHover />,
            label: 'Remove',
            onClick: () => {
                if (collaborator && collaborator.id && onRemovePressed) onRemovePressed(collaborator.id);
                closeOptions();
            },
        }
    ];

    const handleOpenThreeDots = (e: React.MouseEvent<HTMLElement>) => {
        const { left, top, width } = e.currentTarget.getBoundingClientRect();
        if (!isMyPlaylist) {
            openContextMenu({ id: collaborator?.id || '', options: contextMenuOptions.filter((c) => c.label === 'Remove'), left, top, renderLocationPosition: 'action-modal', align: 'right', buttonWidth: width });
            return;
        }
        switch (collaborator?.access_level) {
            case UserPlaylistCollaborationAccessLevel.Edit:
            case UserPlaylistCollaborationAccessLevel.View:
            case UserPlaylistCollaborationAccessLevel.Manage:
                openContextMenu({ id: collaborator.id, options: contextMenuOptions, left, top, renderLocationPosition: 'action-modal', align: 'right', buttonWidth: width });
                break;
            default:
                if ((collaborator as unknown as UserPlaylistCollaboration)?.status === UserPlaylistCollaborationStatus.Invited || collaborator?.status === UserDriveCollaborationStatus.Invited) {
                    openContextMenu({ id: collaborator?.id || '', options: contextMenuOptions, left, top, renderLocationPosition: 'action-modal', align: 'right', buttonWidth: width });
                }
                break;
        }
    };

    const collaborationAccessLevelText = () => {
        switch (collaborator?.access_level) {
            case UserPlaylistCollaborationAccessLevel.Edit:
                return 'Editor';
            case UserPlaylistCollaborationAccessLevel.View:
                return 'Viewer';
            case UserPlaylistCollaborationAccessLevel.Manage:
                return 'Manager';
            default:
                return '';
        }
    };

    const collaborationActions = () => {
        if (isDisplayingOwner) {
            return (
                <div className={styles['collaboration-user-item__state-text']}>
                    Owner
                </div>
            );
        }
        switch (collaborator?.access_level) {
            case UserPlaylistCollaborationAccessLevel.Edit:
            case UserPlaylistCollaborationAccessLevel.View:
            case UserPlaylistCollaborationAccessLevel.Manage:
                return (
                    <>
                        <div className={styles['collaboration-user-item__state-text']}>
                            {collaborator?.status === UserPlaylistCollaborationStatus.Invited ? 'Pending' : collaborationAccessLevelText()}
                        </div>
                        {(isMyPlaylist || currentUser?.email.toLowerCase() === collaborator.email.toLowerCase()) ? (
                            <CustomIcon type="three-dots" hasIconHover className={ContextMenuClickIgnoreClass} onClick={(e) => handleOpenThreeDots(e)} />
                        ) : null}
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <div className={styles['collaboration-user-item']}>
            <div className={styles['collaboration-user-item__left']}>
                <CollaborationUserImage size="regular" imageUrl={imageUrl || collaborator?.profile_image_thumbnail_url} />
                <div className={styles['collaboration-user-item__text-container']}>
                    {collaborator?.full_name ? <div className={styles['collaboration-modal__row-title']}>{collaborator?.full_name}</div> : null}
                    <div className={styles['collaboration-modal__row-subtitle']}>{collaborator?.email}</div>
                </div>
            </div>
            <div className={styles['collaboration-user-item__right']}>
                {collaborationActions()}
            </div>
        </div>
    );
}
