import { ComponentProps, forwardRef, useImperativeHandle } from 'react';
import { SetupIntent } from '@stripe/stripe-js';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';

import { showToast } from '@bpm-web-app/utils';
import styles from './account-add-payment.module.css';

type PaymentElementProps = ComponentProps<typeof PaymentElement>;

export type CardRef = { submit: () => Promise<undefined | SetupIntent> };

// eslint-disable-next-line no-spaced-func, react/prop-types
export default forwardRef<CardRef, PaymentElementProps>(({ onChange, onReady }, ref) => {
    const stripe = useStripe();
    const elements = useElements();

    function handleError(error: { message?: string }) {
        showToast({
            type: 'error',
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            message: error.message || '' as any
        });
    }

    useImperativeHandle(ref, () => {
        return {
            submit: async () => {
                if (!stripe || !elements) {
                    // Stripe.js hasn't yet loaded.
                    // Make sure to disable form submission until Stripe.js has loaded.
                    return undefined;
                }

                // Trigger form validation and wallet collection
                const { error: submitError } = await elements.submit();
                if (submitError) {
                    handleError(submitError);
                    return undefined;
                }

                // Use the clientSecret and Elements instance to confirm the setup
                const { error, setupIntent } = await stripe.confirmSetup({
                    elements,
                    confirmParams: {
                        return_url: window.location.href.split('?')[0]
                    },
                    redirect: 'if_required'
                });

                if (error) {
                    handleError(error);
                } else if (setupIntent?.status === 'succeeded') {
                    return setupIntent;
                } else {
                    handleError({ message: 'Error confirming payment method' });
                }
                return undefined;
            }
        };
    }, [elements, stripe]);

    return (
        <>
            <PaymentElement
                onChange={onChange}
                onReady={onReady}
                options={{
                    terms: {
                        card: 'never',
                    },
                    fields: {
                        billingDetails: {
                            address: {
                                country: 'auto',
                                postalCode: 'auto'
                            }
                        }
                    }
                }}
            />

            <p className={styles['terms-description']}>
                By providing your card information, you allow us to charge your card for future payments in accordance with our <a href="https://bpmmusic.io/terms" target="_blank" rel="noreferrer">terms</a>.
            </p>
        </>
    );
});
