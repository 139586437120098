import classNames from 'classnames';
import { AppLink } from '../app-link/app-link';
import BPMIcons from '../bpm-icons/bpm-icons';
import styles from './genre-card.module.css';

export type GenreCardType = 'default' | 'has-icon';

export interface GenreCardProps {
    name: string;
    id: string | number;
    href: string;
    icon?: string;
    variant: GenreCardType;
}

export function GenreCard({ name, id, href, icon, variant = 'default' }: GenreCardProps) {
    return (
        <AppLink href={href} key={id} isFilterLink>
            <a className={classNames(styles['genre-card'], styles[`genre-card__${variant}`])}>
                {variant === 'has-icon'
                    ? (
                        <div className={styles['genre-card__icon']}>
                            {
                                icon // eslint-disable-next-line react/no-danger
                                    ? <div className={styles['genre-card__icon--main']} dangerouslySetInnerHTML={{ __html: icon }} />
                                    : <div className={styles['genre-card__icon--main']} />
                            }
                            <div className={styles['genre-card__icon--arrow']}>
                                <BPMIcons.ArrowRight />
                            </div>
                        </div>
                    )
                    : null
                }
                <div className={styles['genre-card__element']}>{name}</div>
                {variant === 'default'
                    ? (<div className={styles['genre-card__arrow-icon']}><BPMIcons.ArrowRight /></div>)
                    : null
                }
            </a>
        </AppLink>
    );
}
