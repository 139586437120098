import { GiveawayPrize } from '@bpm-web-app/supreme-api-sdk';
import { appendQueryParams } from '@bpm-web-app/utils';
import { useMemo } from 'react';
import Card from '../card';

interface PrizeCardProps {
    prize?: GiveawayPrize;
}

export function PrizeCard({ prize }: PrizeCardProps) {
    const getSubtitleMarkup = useMemo(() => {
        if (!prize) {
            return null;
        }
        // eslint-disable-next-line react/no-danger
        return <div dangerouslySetInnerHTML={{ __html: prize?.description }} />;
    }, [prize]);

    return prize ? (
        <Card
            cardSize="large"
            id={prize.id}
            title={prize.title}
            description={getSubtitleMarkup}
            isDescriptionSingleLine
            imageUrl={appendQueryParams(prize.image_url, { key: 'dw', value: 264 })}
            imageUrl2x={appendQueryParams(prize.image_url, { key: 'dw', value: 528 })}
            isPlayable={false}
            link={prize.link}
        />
    ) : null;
}
