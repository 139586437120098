import { useAds } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, useHubSwitch } from '@bpm-web-app/utils';
import { useCallback, useContext, useState } from 'react';
import { useRouter } from 'next/router';
import { Ads } from '@bpm-web-app/api-client';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { PromotionalModal } from '../promotional-modal/promotional-modal';

export function PromotionsMessagingModal() {
    const { libraryProperty } = useContext(LibraryTabsContext);
    const router = useRouter();
    const { isDownload, isCreate } = useHubSwitch();
    const { data: adsData, isLoading, mutate } = useAds(libraryProperty, isCreate ? 'create' : isDownload ? 'supreme' : 'stream');

    const onConfirm = useCallback(() => {
        Ads.reportAdActivated(adsData!.data.id);
        router.push(adsData?.data.cta_link || '');
        mutate(undefined);
    }, [adsData, router, mutate]);

    /* TODO: add the following logic to when the modal should appear:
     * > The modal should appear when the page is loaded or the library is switched or
     * the platform is switched.
     * https://github.com/bpmsupreme/documentation/issues/83
     * */
    const [isPromoModalVisible, setIsPromoModalVisible] = useState(true);

    if (!adsData?.data && !isLoading) return null;

    return (
        !isLoading ? (
            <PromotionalModal
                isOpen={isPromoModalVisible}
                headerTitle={adsData?.data.type.toString() || ''}
                onClose={() => {
                    setIsPromoModalVisible(false);
                    mutate(undefined);
                }}
                onConfirm={() => onConfirm()}
                confirmButtonText={adsData?.data.cta || ''}
                title={adsData?.data.title || ''}
                subtitle={adsData?.data.sub_title || ''}
                image={appendQueryParams(adsData?.data.image_url || '', { key: 'dw', value: 282 * 2 })}
                footerRichTextContent={adsData?.data.extra_info}
            />
        ) : null
    );
}
