import qs from 'query-string';

export function setCouponStorage() {
    // eslint-disable-next-line @typescript-eslint/dot-notation
    const { coupon } = qs.parse(window.location.search);
    if (coupon) {
        try {
            sessionStorage.setItem('bpm_c_coupon', coupon as string);
            return coupon;
            // eslint-disable-next-line no-empty
        } catch (error) {
        }
    }
    return undefined;
}

export function getCouponStorage() {
    try {
        return sessionStorage.getItem('bpm_c_coupon');
        // eslint-disable-next-line no-empty
    } catch (error) {
    }
    return undefined;
}
