import { useInfiniteContestComments, useInfiniteGiveawayComments } from '@bpm-web-app/swr-hooks';
import React, { useCallback, useMemo } from 'react';
import Comment from '../comment/comment';
import CommentsForm from '../comments-form/comments-form';
import styles from './comments.module.css';
import SeeMore from '../shared/see-more-button/see-more-btn';

export interface CommentsProps {
    giveawayId?: string;
    contestId?: string;
}

export function Comments({ giveawayId, contestId }: CommentsProps) {
    const {
        data: giveawayCommentsData,
        isLoadingMore: isLoadingMoreGiveaway,
        isLastPage: isLastPageGiveaway,
        size: sizeGiveaway,
        setSize: setSizeGiveaway,
    } = useInfiniteGiveawayComments({ id: giveawayId, limit: 10 }, !!contestId);

    const {
        data: contestCommentsData,
        isLoadingMore: isLoadingMoreContest,
        isLastPage: isLastPageContest,
        size: sizeContest,
        setSize: setSizeContest,
    } = useInfiniteContestComments({ id: contestId, limit: 10 }, !!giveawayId);

    const commentsData = useMemo(() => {
        if (giveawayId) {
            return {
                data: giveawayCommentsData,
                isLoadingMore: isLoadingMoreGiveaway,
                isLastPage: isLastPageGiveaway,
                size: sizeGiveaway,
                setSize: setSizeGiveaway,
            };
        }

        return {
            data: contestCommentsData,
            isLoadingMore: isLoadingMoreContest,
            isLastPage: isLastPageContest,
            size: sizeContest,
            setSize: setSizeContest,
        };
    }, [
        contestCommentsData,
        giveawayCommentsData,
        giveawayId,
        isLastPageContest,
        isLastPageGiveaway,
        isLoadingMoreContest,
        isLoadingMoreGiveaway,
        setSizeContest,
        setSizeGiveaway,
        sizeContest,
        sizeGiveaway,
    ]);

    const { data, isLoadingMore, isLastPage, size, setSize } = commentsData;

    const comments = data?.map(({ data: paginatedResponse }) => paginatedResponse);
    const flattenComments = useMemo(() => (comments ? [].concat(...comments) : []), [comments]);

    const loadMoreComments = useCallback(() => {
        if (isLoadingMore) return;
        setSize(size + 1);
    }, [isLoadingMore, setSize, size]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className={styles['comments']}>
            <h3 className={styles['comments__title']}>Comments</h3>
            <div className={styles['comments__form']}>
                <CommentsForm giveawayId={giveawayId} contestId={contestId} />
            </div>
            <div className={styles['comments__comments']}>
                {flattenComments?.map((comment) => (
                    <React.Fragment key={comment.id}>
                        <Comment giveawayId={giveawayId} contestId={contestId} id={comment.id} user={comment.user} created_at={comment.created_at} comment={comment.comment} />
                    </React.Fragment>
                ))}
            </div>
            {!isLastPage ? (
                <SeeMore expand={false} variant="text" onClick={loadMoreComments} />
            ) : null}
        </div>
    );
}

export default Comments;
