import { useInfiniteSearchSound, useTags } from '@bpm-web-app/swr-hooks';
import { getMutatedSounds, SoundsResults, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useContext, useMemo } from 'react';
import TrackListCreate from '../../shared/track-list/create/track-list-create';
import { FiltersContext } from '../../filters/filters.context';

export function MidiSeeMore() {
    const { tags, setTags } = useContext(FiltersContext);
    const query = useCreateFilterParams({ type: 'midi' });

    const { data: soundsData, mutate, isLoadingMore, setSize, isLastPage, } = useInfiniteSearchSound(query);
    const { tags: possibleTags } = useTags({ ...query, limit: undefined });

    const mutateSound = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedSounds(soundsData as SoundsResults[], id, progress));
        },
        [mutate, soundsData]
    );

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const midiSounds = useMemo(() => soundsData?.flatMap((data) => data!.data), [soundsData]);

    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);

    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className="spacing__window">
            <TrackListCreate
                mutateSound={mutateSound}
                list={midiSounds}
                onLoadMore={handleLoadMore}
                isLoadingMore={isLoadingMore}
                title="MIDI Samples"
                showTagFilter
                tags={possibleTagsString}
                selectedTags={tags}
                onToggleTag={(tag, on) => {
                    if (on) {
                        setTags([...tags, tag]);
                    } else {
                        setTags(tags.filter((t) => t !== tag));
                    }
                }} />
        </div>
    );
}

export default MidiSeeMore;
