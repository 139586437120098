import { ReactNode } from 'react';
import classNames from 'classnames';
import { useViewport } from '@bpm-web-app/utils';
import styles from './media-detail-banner.module.css';

interface MediaDetailBannerProps {
    className?: string;
    actionsClassName?: string;
    children: {
        image: ReactNode;
        actions?: ReactNode;
        text: ReactNode;
    };
    align?: 'start' | 'end';
    minHeight?: number;
    minHeightMobile?: number;
}

export function MediaDetailBanner({ className, actionsClassName, children, align = 'start', minHeight, minHeightMobile }: MediaDetailBannerProps) {
    const { image, actions, text } = children;
    const { isMobile } = useViewport();

    return (
        <div className={classNames(styles['media-detail-banner'], className)}>
            <div className={styles['media-detail-banner__img-container']} style={{ minHeight: `${!isMobile ? minHeight || 296 : minHeightMobile || 136}px`, maxHeight: 296, maxWidth: 296 }}>{image}</div>
            {actions && <div className={classNames(styles[`media-detail-banner__actions-container--${align}`], actionsClassName)}>{actions}</div>}
            <div className={styles['media-detail-banner__text-container']}>{text}</div>
        </div>
    );
}

export default MediaDetailBanner;
