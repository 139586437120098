import { Genre } from '@bpm-web-app/create-api-sdk';
import { createAppRoutes } from '@bpm-web-app/utils';
import Link from 'next/link';
import { useMemo } from 'react';
import styles from './clickable-genres.module.css';

export interface ClickableGenresProps {
    genres: Genre | Genre[];
}

export const ClickableGenres = ({ genres }: ClickableGenresProps) => {
    const genresList = useMemo(() => {
        if (Array.isArray(genres)) {
            return genres;
        }

        return [genres];
    }, [genres]);

    return (
        <div className={styles['clickable-genres']}>
            {(genresList as Genre[]).map((genre) => (
                <Link key={genre.id} href={createAppRoutes.genresSlug(genre.name)}>
                    <span className={styles['clickable-genres__item']}>{genre.name}</span>
                </Link>
            ))}
        </div>
    );
};
