import { useRouter } from 'next/router';

import { useGetMeSubscription, useGetPaymentInstrument } from '@bpm-web-app/swr-hooks';
import { getCurrentPlatformLink } from '@bpm-web-app/utils';
import { ChangeSubscriptionRequestStripe, SubscriptionDetailsv4 } from '@bpm-web-app/supreme-api-sdk';
import { Subscription } from '@bpm-web-app/api-client';
import { AppLink } from '../../shared';
import { Account } from '../account';
import { Button } from '../components/button/button';
import { PageHeader } from '../components/page-header/page-header';
import { PaymentMethodsForm } from './payment-form/payment-methods-form';
import styles from './payment-methods.module.css';

interface PaymentMethodProps {
    title: string;
    platform: 'supreme' | 'stream' | 'create';
}

export function PaymentMethods({ title, platform }: PaymentMethodProps) {
    const { data: userSubscription, isLoading: isLoadingUserSubscription } = useGetMeSubscription(platform);
    const { mutate } = useGetPaymentInstrument(platform);
    const router = useRouter();

    const hasMembership = userSubscription?.data.membership?.has_membership;
    const isCancelled = userSubscription?.data.membership?.subscription?.status === SubscriptionDetailsv4.StatusEnum.Cancelled;
    const packageId = userSubscription?.data.membership.subscription?.package_id;

    const handleAfterSubmit = async (id: string) => {
        if (userSubscription?.data.membership.subscription?.package_id) {
            // eslint-disable-next-line max-len
            await Subscription.changeSubscription(userSubscription?.data.membership.subscription?.package_id, { payment_data: { payment_method_id: id, method: ChangeSubscriptionRequestStripe.MethodEnum.Stripe } });
            mutate();
        }
        router.back();
    };

    if (isLoadingUserSubscription) {
        return null;
    }

    return (
        <Account title={title}>
            <PageHeader title="Payment Method">
                {(!isCancelled && hasMembership) && (
                    <AppLink href={`/account/plan/cancel/${packageId}?platform=${platform}`} passHref>
                        <Button as="a" variant="secondary" className={styles['payment-methods__cancel-button-desktop']}>Cancel Subscription</Button>
                    </AppLink>
                )}
            </PageHeader>

            <div className={styles['payment-methods__form-wrapper']}>
                {
                    isCancelled ? (
                        <div className={styles['payment-methods__no-subscription']}>
                            <h2 className={styles['payment-methods__no-subscription-text']}>
                                Your subscription has been cancelled.
                            </h2>
                        </div>
                    ) : hasMembership ? (
                        <PaymentMethodsForm afterSubmit={handleAfterSubmit} platform={platform} userSubscription={userSubscription?.data} />

                    ) : (
                        <div className={styles['payment-methods__no-subscription']}>
                            <h2 className={styles['payment-methods__no-subscription-text']}>
                                You don&apos;t have an active subscription
                            </h2>
                            <AppLink href="/account/plan" passHref>
                                <Button as="a" variant="primary">
                                    Choose Plan
                                </Button>
                            </AppLink>
                        </div>
                    )
                }

            </div>

            {(!isCancelled && hasMembership) && (
                <AppLink href={getCurrentPlatformLink(`/account/plan/cancel/${packageId}?platform=${platform}`)} passHref>
                    <Button as="a" variant="secondary" className={styles['payment-methods__cancel-button-mobile']}>Cancel Subscription</Button>
                </AppLink>
            )}
        </Account>
    );
}
