import React from 'react';
import styles from './card-bar.module.css';

interface CardBarProps {
    textLeft: {
        text: string;
        onClick?: () => void;
    };
    textRight: {
        text: string;
        onClick?: () => void;
    };
}

export const CardBar = ({ textLeft, textRight }: CardBarProps) => {
    return (
        <div className={styles['card-bar']}>
            <div className={styles['card-bar__background']}>
                <button
                    type="button"
                    style={!textLeft.onClick ? { cursor: 'default' } : {}}
                    aria-label="logout"
                    className={styles['card-bar__textLeft']}
                    onClick={() => textLeft.onClick && textLeft.onClick()}
                >
                    {textLeft.text}
                </button>
                <button
                    type="button"
                    style={!textRight.onClick ? { cursor: 'default' } : {}}
                    aria-label="logout"
                    className={styles['card-bar__textRight']}
                    onClick={() => textRight.onClick && textRight.onClick()}
                >
                    {textRight.text}
                </button>
            </div>
        </div>
    );
};
