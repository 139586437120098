import { TrendingByPeriodQuery } from '@bpm-web-app/api-client';
import { useCreateGenres, useTrending } from '@bpm-web-app/swr-hooks';
import { useEffect, useMemo, useState } from 'react';
import { DEFAULT_BROWSE_TRENDING_LIMIT } from '@bpm-web-app/utils';
import { SoundPackage } from '@bpm-web-app/create-api-sdk';
import Trending, { ITrendingItem } from './trending';

interface TrendingBrowseCreateProps {
    isDataLoaded?: boolean;
    setIsDataLoaded?: (loaded: boolean) => void;
}

export function TrendingBrowseCreate({ isDataLoaded, setIsDataLoaded }: TrendingBrowseCreateProps) {
    const [activeGenre, setActiveGenre] = useState('');
    const { data: trendingData, isLoading } = useTrending('create', { limit: DEFAULT_BROWSE_TRENDING_LIMIT, skip: 0, genre: activeGenre } as TrendingByPeriodQuery);

    const soundPackages = useMemo(() => (trendingData?.data || []) as SoundPackage[], [trendingData?.data]);

    const { genres } = useCreateGenres();

    const trending = useMemo(
        () =>
            soundPackages.map(
                (pack): ITrendingItem => ({
                    artist: pack.artist,
                    cover_url: pack.artwork_url,
                    trending_status: 'trending_status' in pack ? (pack as any).trending_status : 0,
                    id: pack.id,
                    title: pack.name,
                    slug: pack.slug,
                    genre: pack?.Genre,
                    tracking_id: pack.id
                })
            ),
        [soundPackages]
    );

    useEffect(() => {
        if (setIsDataLoaded) {
            if (isLoading === false) {
                setIsDataLoaded(true);
            } else {
                setIsDataLoaded(false);
            }
        }
    }, [isLoading, setIsDataLoaded]);

    const shouldDisplayGhostLoading = useMemo(() => isDataLoaded === false || isLoading, [isDataLoaded, isLoading]);

    return (
        <Trending
            trending={trending}
            isLoading={shouldDisplayGhostLoading}
            platform="create"
            dropdownData={genres?.map((genre) => ({ label: genre.name, value: genre.name }))}
            onDropdownChange={setActiveGenre}
            activeDropdownValue={activeGenre}
        />
    );
}

export default TrendingBrowseCreate;
