import styles from './card-tooltip.module.css';

interface CardTooltipProps {
    soundsCount?: number;
    midiCount?: number;
    presetsCount?: number;
    position?: { x: number, y: number };
}

export function CardTooltip({ soundsCount, midiCount, presetsCount, position }: CardTooltipProps) {
    return (
        <div className={styles['card-tooltip__tooltip']} style={{ top: position?.y, left: position?.x }}>
            {soundsCount && soundsCount !== 0 ? (
                <div className={styles['card-tooltip__tooltip-line']}>
                    {soundsCount}
                    <div className={styles['card-tooltip__tooltip-highlighted']}>Sounds</div>

                </div>
            ) : null}
            {midiCount && midiCount !== 0 ? (
                <div className={styles['card-tooltip__tooltip-line']}>
                    {midiCount}
                    <div className={styles['card-tooltip__tooltip-highlighted']}>MIDI</div>

                </div>
            ) : null}
            {presetsCount && presetsCount !== 0 ? (
                <div className={styles['card-tooltip__tooltip-line']}>
                    {presetsCount}
                    <div className={styles['card-tooltip__tooltip-highlighted']}>Presets</div>
                </div>
            ) : null}
        </div>
    );
}
