import { useSearchCuratedSets } from '@bpm-web-app/swr-hooks';
import { useRouter } from 'next/router';
import { appendQueryParams, convertToTitleCase, useSupremeFilterParams, useUniqueArray, useViewport } from '@bpm-web-app/utils';
import { Fragment, useCallback, useEffect, useMemo } from 'react';

import { Artist } from '@bpm-web-app/stream-api-sdk';
import classNames from 'classnames';
import styles from './search-curated-sets.module.css';
import CardCarousel from '../../shared/card-carousel/card-carousel';
import SecondaryPageTitle from '../../shared/secondary-page-title/secondary-page-title';
import CardGrid from '../../shared/card-grid/card-grid';
import ListGrid from '../../shared/ui/list-grid/list-grid';
import ListGridItem from '../../shared/list-grid-item/list-grid-item';
import { NoResultsBlock } from '../../shared/ui/no-results-block/no-results-block';
import { CardGhostLoading } from '../../shared/card/card-ghost-loading';
import { CardCarouselGhostLoading } from '../../shared/card-carousel/card-carousel-ghost-loading';
import { GhostElement } from '../../shared/ghost-element/ghost-element';

import { useArtistLinks } from '../../artist-link/artist-link';
import { CuratedSetCard } from '../../shared/card/curated-set-card/curated-set-card';

const CURATED_SETS_LINES_WIDTH = [90, 70];

export interface SearchCuratedSetsProps {
    indexPage?: boolean;
    onCount: (count: number) => void;
    hasNoResults?: boolean;
    isLoadingData?: boolean;
    setIsLoadingData?: (isLoading: boolean) => void;
}

export function SearchCuratedSets({ indexPage, onCount, hasNoResults, isLoadingData, setIsLoadingData }: SearchCuratedSetsProps) {
    const router = useRouter();

    const query = useSupremeFilterParams(false);
    const { searchTerm } = router.query;
    const pageTitle = searchTerm && searchTerm !== undefined && String(searchTerm) ? `Curated Sets Related To ‘${convertToTitleCase(String(searchTerm))}’` : 'Curated Sets';

    const { data, isLoading } = useSearchCuratedSets(query);
    const { isMobile } = useViewport();
    const generateArtistLinks = useArtistLinks();

    const seeMorePath = useMemo(
        // eslint-disable-next-line @typescript-eslint/dot-notation
        () => `/search?searchTerm=${router.query['searchTerm']}&type=curated-sets`,
        [router.query]
    );

    const getDescriptionMarkup = useCallback((artist: string, artists: Artist[]) => <div className={styles['search-curated-sets__artist']}>{generateArtistLinks(artist, artists)}</div>, []);

    const getIntroSection = useCallback(
        () => (
            <div className={styles['search-curated-sets__intro-section']}>
                <div className={indexPage ? '' : 'spacing__window--top'} />
                <SecondaryPageTitle title={pageTitle} counter={`${data?.data?.length || 0}`} />
                <div className="spacing--top" />
                {data?.data.length === 0 && <NoResultsBlock hasPadding>No Curated Sets Available</NoResultsBlock>}
            </div>
        ),
        [data?.data.length, indexPage, pageTitle]
    );

    const ghostLoadingItems = useUniqueArray(10);

    useEffect(() => {
        onCount(data?.pagination.total || 0);
    }, [data?.pagination.total, onCount]);

    useEffect(() => {
        if (setIsLoadingData) setIsLoadingData(isLoading);
    }, [isLoading, setIsLoadingData]);

    if (hasNoResults && !isLoadingData) {
        return null;
    }

    return (
        <div className={classNames(styles['search-curated-sets'])}>
            {(data?.data.length === 0 || (indexPage && !isMobile && data?.data.length > 0)) && getIntroSection()}
            {indexPage && isMobile && (
                <div className="spacing__window--horizontal">
                    <ListGrid>
                        {isLoadingData || isLoading
                            ? ghostLoadingItems.map((uuid) => (
                                <GhostElement
                                    isRow
                                    key={`search-curated-sets-${uuid}`}
                                    linesWidthArray={CURATED_SETS_LINES_WIDTH}
                                    squareWidth={56}
                                    itemClass={styles['search-curated-sets__mobile-loading-item']}
                                />
                            ))
                            : data?.data &&
                            data?.data.map((curatedSet) => (
                                <Fragment key={curatedSet.id}>
                                    <ListGridItem
                                        id={curatedSet.id}
                                        title={curatedSet.title}
                                        subtitle={getDescriptionMarkup(curatedSet.artist, curatedSet.artists)}
                                        imageUrl={appendQueryParams(curatedSet.image_url, { key: 'dw', value: 160 })}
                                        imageUrl2x={appendQueryParams(curatedSet.image_url, { key: 'dw', value: 320 })}
                                        link={`/curated-set/${curatedSet.id}`}
                                        contentType="curatedSet"
                                    />
                                </Fragment>
                            ))}
                    </ListGrid>
                </div>
            )}
            {indexPage && !isMobile && (
                <div className="spacing__window--horizontal">
                    <CardGrid cardType="card-small">
                        {isLoadingData || isLoading ?
                            ghostLoadingItems.map((uuid) => <CardGhostLoading key={`card-ghost-${uuid}`} cardSize="small" linesWidthArray={CURATED_SETS_LINES_WIDTH} lineHeight={10} />) : null}
                        {!isLoadingData &&
                            data?.data &&
                            data?.data.map((curatedSet) => (
                                <CuratedSetCard
                                    key={curatedSet.id}
                                    curatedSet={curatedSet}
                                    curatedSets={data.data} />
                            ))}
                    </CardGrid>
                </div>
            )}
            {!indexPage && (
                <>
                    {isLoadingData && <CardCarouselGhostLoading elementsCount={10} cardSize="small" title={pageTitle} linesWidthArray={CURATED_SETS_LINES_WIDTH} lineHeight={10} />}
                    {!isLoadingData && data?.data && data?.data.length > 0 && (
                        <CardCarousel title={pageTitle} titleCounter={data?.data?.length.toString()} seeMorePath={seeMorePath}>
                            {data.data.map((curatedSet) => (
                                <CuratedSetCard
                                    key={curatedSet.id}
                                    curatedSet={curatedSet}
                                    curatedSets={data.data} />
                            ))}
                        </CardCarousel>
                    )}
                </>
            )}
        </div>
    );
}

export default SearchCuratedSets;
