import { useCallback, useContext, useMemo, useState } from 'react';
import { DownloadSearchSuggestResponse, StreamSearchSuggestResponse, useCreateDrive, useSearchSuggest } from '@bpm-web-app/swr-hooks';
import { Analytics, getCurrentPlatformLink, localStorageClear, localStorageGet, localStorageRemove, useDebounce, useHubSwitch, useUserSettings, useViewport } from '@bpm-web-app/utils';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import styles from './predictive-search.module.css';
import Artists from './artists';
import Media from './media';
import { LibraryTabsContext } from '../../../../utils/src/lib/library-tabs.context';
import { SearchItem } from './search-item/search-item';
import BPMIcons from '../shared/bpm-icons/bpm-icons';
import { Checkboxes, EmptyState } from '../shared';
import Playlist from './playlist';
import CuratedSet from './curated-set';
import { PlaylistsFormProps } from '../playlists-form/playlists-form';
import { useUserPlaylistCategories } from '../shared/three-dots-sheet/useUserPlaylistCategories';
import { SearchButtons } from './search-buttons/search-buttons';

export interface PredictiveSearchProps {
    term: string;
    setTerm: (term: string) => void;
    setOpenForm: (props: PlaylistsFormProps | null) => void;
    handleCancel: () => void;
    isVisible?: boolean;
}

type StreamSearchData = StreamSearchSuggestResponse['data'];
type DownloadSearchData = DownloadSearchSuggestResponse['data'];

export default function PredictiveSearch({ term, setTerm, setOpenForm, handleCancel, isVisible = false }: PredictiveSearchProps) {
    const debouncedSearchTerm = useDebounce(term, 100);
    const router = useRouter();
    const { libraryProperty, library } = useContext(LibraryTabsContext);
    const { isCreate } = useHubSwitch();

    const { isDownload } = useHubSwitch();
    const { isMobile } = useViewport();
    const [searchHistory, setSearchHistory] = useState(localStorageGet('search-history'));
    const options = [
        { label: 'All', value: 'all' },
        { label: 'Artists', value: 'artists' },
        { label: 'Tracks', value: 'tracks' },
        { label: 'Playlists', value: 'playlists' },
        { label: 'Curated Sets', value: 'curated-sets' },
    ];
    const [filterOptions, setFilterOptions] = useState<string[]>(['all']);
    const { data } = useSearchSuggest({
        term: debouncedSearchTerm,
        library: libraryProperty,
    }, isCreate);
    const searchResults = useMemo(() => data?.data || ({} as DownloadSearchData | StreamSearchData), [data?.data]);

    const { createFolder } = useUserPlaylistCategories();
    const createDriveFolder = useCreateDrive();
    const { isAnonymous, setShowSignUpModal } = useUserSettings();

    const addFolder = useCallback(() => {
        if (isAnonymous) {
            setShowSignUpModal({ type: 'full-access' });
            return;
        }
        setOpenForm({
            type: 'CreateNewFolder',
            text: 'Create folders to organize your playlists by genre, event, or more.',
            formAction: createFolder,
            close: () => setOpenForm(null),
        });
    }, [createFolder, isAnonymous, setOpenForm, setShowSignUpModal]);

    const addDrive = useCallback(() => {
        if (isAnonymous) {
            setShowSignUpModal({ type: 'full-access' });
            return;
        }
        setOpenForm({
            type: 'CreateNewDrive',
            text: 'Create a personal collection of sounds to help organize your projects.',
            formAction: (name: string) => createDriveFolder({ name }),
            close: () => setOpenForm(null),
        });
    }, [createDriveFolder, isAnonymous, setOpenForm, setShowSignUpModal]);

    const viewTodayTrending = () => {
        router.push(getCurrentPlatformLink('/trending?period=daily'));
    };

    const viewTopPicks = () => {
        // TODO: Make sure this works
        router.push(getCurrentPlatformLink('/for-you'));
    };

    const viewExclusives = () => {
        router.push(getCurrentPlatformLink('/releases?category=exclusives'));
    };

    const viewCategory = () => {
        if (library !== 'latin') {
            router.push(getCurrentPlatformLink('/genres?category=hip-hop-r-b'));
        } else {
            router.push(getCurrentPlatformLink('genres?category=reggaeton'));
        }
    };

    const viewFavorites = () => {
        if (isAnonymous) {
            setShowSignUpModal({ type: 'full-access' });
            return;
        }
        router.push(getCurrentPlatformLink('/favorites'));
    };

    const viewTopRemixes = () => {
        // TODO: Make sure this works
        router.push(getCurrentPlatformLink('/for-you'));
    };

    const viewBPMOriginals = () => {
        router.push(getCurrentPlatformLink('/releases?category=bpm-originals'));
    };

    const viewNewSoundPacks = () => {
        router.push(getCurrentPlatformLink('/sound-packages/new'));
    };

    const viewMIDIFiles = () => {
        router.push(getCurrentPlatformLink('/midi'));
    };

    const viewPresets = () => {
        router.push(getCurrentPlatformLink('/presets'));
    };

    const viewCuratedPacks = () => {
        router.push(getCurrentPlatformLink('/curated'));
    };

    const COMMON_ACTIONS = isCreate ? [
        { icon: <BPMIcons.PlusIcon />, text: 'Create Drive', action: addDrive },
        { icon: <BPMIcons.NewReleaseIcon />, text: 'View New Soundpacks', action: viewNewSoundPacks },
        { icon: <BPMIcons.TrendingTracksIcon />, text: "View Today's Trending Packs", action: viewTodayTrending },
        { icon: <BPMIcons.GenreIcon />, text: 'View Hip Hop Category', action: viewCategory },
        { icon: <BPMIcons.FavoriteHeartIcon />, text: 'Manage Favorites', action: viewFavorites },
        { icon: <BPMIcons.MIDIIcon />, text: 'View MIDI Files', action: viewMIDIFiles },
        { icon: <BPMIcons.PresetsIcon />, text: 'View Synth Presets', action: viewPresets },
        { icon: <BPMIcons.CuratedSetsIcon />, text: 'View Curated Packs', action: viewCuratedPacks },
    ] : [
        { icon: <BPMIcons.PlusIcon />, text: 'Create Folder', action: addFolder },
        { icon: <BPMIcons.TrendingTracksIcon />, text: "View Today's Trending Tracks", action: viewTodayTrending },
        // { icon: <BPMIcons.PlaylistIcon />, text: 'View Top Picks For You', action: viewTopPicks },
        { icon: <BPMIcons.NewReleaseIcon />, text: 'View New Exclusives', action: viewExclusives },
        { icon: <BPMIcons.GenreIcon />, text: library === 'latin' ? 'View Reggaeton Category' : 'View Hip Hop Category', action: viewCategory },
        { icon: <BPMIcons.FavoriteHeartIcon />, text: 'Manage Favorites', action: viewFavorites },
        // { icon: <BPMIcons.PlaylistIcon />, text: 'View Top Remixes For You', action: viewTopRemixes },
        { icon: <BPMIcons.BPMExclusiveIcon />, text: 'View BPM Originals', action: viewBPMOriginals },
    ];

    const [viewMoreCommon, setViewMoreCommon] = useState(false);
    const [viewMoreHistory, setViewMoreHistory] = useState(false);

    const [viewMoreArtists, setViewMoreArtists] = useState(false);
    const [viewMoreTracks, setViewMoreTracks] = useState(false);
    const [viewMorePlaylists, setViewMorePlaylists] = useState(false);
    const [viewMoreCuratedSets, setViewMoreCuratedSets] = useState(false);

    const checkboxes = () => {
        return (
            <div className={styles['predictive-search__checkboxes-container']}>
                <div className={styles['predictive-search__search-text']}>I&apos;m searching for...</div>
                <Checkboxes
                    options={options}
                    value={filterOptions}
                    onChange={(values) => {
                        if (values.includes('all') && !filterOptions.includes('all')) {
                            setFilterOptions(['all']);
                        } else {
                            setFilterOptions(values.filter((v) => v !== 'all'));
                        }
                    }} />
            </div>
        );
    };

    const shouldDisplay = useCallback((label: string) => {
        if (filterOptions.length === 0 || (filterOptions.includes('all') && filterOptions.length === 1)) {
            return true;
        }
        if (filterOptions.includes(label.toLowerCase())) {
            return true;
        }
        return false;
    }, [filterOptions]);

    const hasTracks = useMemo(() => {
        if (isDownload) {
            if ((searchResults as DownloadSearchData).albums?.length > 0) return true;
        } else if ((searchResults as StreamSearchData).media?.length > 0) return true;
        return false;
    }, [isDownload, searchResults]);

    const shouldDisplayNoResults = useMemo(() => {
        if (searchResults.artist_total === 0 &&
            !hasTracks &&
            searchResults.playlist_total === 0 &&
            searchResults.curated_total === 0) return true;
        return false;
    }, [hasTracks, searchResults.artist_total, searchResults.curated_total, searchResults.playlist_total]);

    const sectionCount = useCallback((label: string, viewMore = false) => {
        const viewMoreCount = 6;
        const largeCount = 6;
        const smallCount = 3;

        if (filterOptions.length === 1 && filterOptions.includes(label.toLowerCase())) {
            return viewMore ? largeCount + viewMoreCount : largeCount;
        }

        return viewMore ? smallCount + viewMoreCount : smallCount;
    }, [filterOptions]);

    const searchItem = useCallback((searchTerm: string) => {
        router.push(getCurrentPlatformLink(`/search?searchTerm=${searchTerm}`));
    }, [router]);

    const removeFromHistory = (searchTerm: string) => {
        setSearchHistory(localStorageRemove('search-history', searchTerm));
    };

    const clearHistory = () => {
        setSearchHistory(localStorageClear('search-history'));
    };

    const emptySearchState = () => {
        return (
            <div className={classNames(styles['predictive-search__container'], {
                [styles['predictive-search__container--open']]: isVisible,
            })}>
                {searchHistory && searchHistory.length > 0 && !viewMoreCommon ?
                    <>
                        {/* Recent Searches */}
                        <div className={styles['predictive-search__row-container']}>
                            <div className={styles['predictive-search__title']}>Recent Searches</div>
                            <button type="button" className={classNames(styles['predictive-search__thin-text'])} onClick={clearHistory}>Clear all</button>
                        </div>
                        {viewMoreHistory ? searchHistory.slice(0, 10).map((item: string, index: number) => {
                            // eslint-disable-next-line react/no-array-index-key
                            return <SearchItem key={index} icon={<BPMIcons.ClockIcon />} text={item} onClick={() => searchItem(item)} onRemove={() => removeFromHistory(item)} />;
                        }) : searchHistory.slice(0, 4).map((item: string, index: number) => {
                            // eslint-disable-next-line react/no-array-index-key
                            return <SearchItem key={index} icon={<BPMIcons.ClockIcon />} text={item} onClick={() => searchItem(item)} onRemove={() => removeFromHistory(item)} />;
                        })}
                        {searchHistory && searchHistory.length > 4 ?
                            (
                                <button
                                    type="button"
                                    className={classNames('dynamic-button', 'spacing--top')}
                                    onClick={() => setViewMoreHistory(!viewMoreHistory)}
                                >{viewMoreHistory ? 'View Less' : 'View More'}
                                </button>
                            )
                            : null}
                    </> : null}
                {!viewMoreCommon && !viewMoreHistory && searchHistory && searchHistory.length > 0 ? <div className={styles['predictive-search__divider']} /> : null}
                {/* Common Actions */}
                {!viewMoreHistory ?
                    <>
                        <div className={classNames(styles['predictive-search__row-container'], 'spacing--top')}>
                            <div className={styles['predictive-search__title']}>Common Actions</div>
                        </div>
                        {COMMON_ACTIONS.slice(0, viewMoreCommon ? undefined : searchHistory && searchHistory.length > 0 ? 3 : 6).map((item, index) => {
                            return <SearchItem
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                icon={item.icon}
                                text={item.text}
                                onClick={() => {
                                    handleCancel();
                                    Analytics.trackClick('common_action', item.text);
                                    item.action();
                                }} />;
                        })}
                        {!isCreate && searchHistory && searchHistory.length === 0 ? null : (
                            <button
                                type="button"
                                className={classNames('dynamic-button', 'spacing--top')}
                                onClick={() => {
                                    setViewMoreHistory(false);
                                    setViewMoreCommon(!viewMoreCommon);
                                }}
                            >{viewMoreCommon ? 'View Less' : 'View More'}
                            </button>
                        )}
                    </> : null}
            </div>
        );
    };

    const renderedArtists = useMemo(() => {
        if (shouldDisplay('artists')) {
            return (
                <>
                    <Artists artists={searchResults.artists?.slice(0, sectionCount('artists', viewMoreArtists))} count={searchResults.artist_total} />
                    {searchResults.artists?.length > 3 ? <button type="button" onClick={() => setViewMoreArtists(!viewMoreArtists)} className={classNames('dynamic-button', 'spacing--top-half')}>{viewMoreArtists ? 'View Less' : 'View More'}</button> : null}
                    {searchResults.artists?.length > 0 ? <div className={styles['predictive-search__divider']} /> : null}
                </>
            );
        }
        return null;
    }, [searchResults.artist_total, searchResults.artists, sectionCount, shouldDisplay, viewMoreArtists]);

    const renderedTracks = useMemo(() => {
        if (shouldDisplay('tracks')) {
            return (
                <>
                    <Media
                        media={isDownload
                            ? (searchResults as DownloadSearchData).albums?.slice(0, sectionCount('tracks', viewMoreTracks))
                            : (searchResults as StreamSearchData).media?.slice(0, sectionCount('tracks', viewMoreTracks))
                        }
                        setTerm={setTerm}
                        count={isDownload ? (searchResults as DownloadSearchData).album_total : (searchResults as StreamSearchData).media_total} />
                    {(isDownload ? (searchResults as DownloadSearchData).album_total !== 0 : (searchResults as StreamSearchData).media_total !== 0) ? <button type="button" onClick={() => setViewMoreTracks(!viewMoreTracks)} className={classNames('dynamic-button', 'spacing--top-half')}>{viewMoreTracks ? 'View Less' : 'View More'}</button> : null}
                    {(isDownload ? (searchResults as DownloadSearchData).album_total !== 0 : (searchResults as StreamSearchData).media_total !== 0) ? <div className={styles['predictive-search__divider']} /> : null}
                </>
            );
        }
        return null;
    }, [isDownload, searchResults, sectionCount, setTerm, shouldDisplay, viewMoreTracks]);

    const renderedPlaylists = useMemo(() => {
        if (shouldDisplay('playlists')) {
            return (
                <>
                    <Playlist playlists={searchResults.playlists?.slice(0, sectionCount('playlists', viewMorePlaylists))} count={searchResults.playlist_total} />
                    {searchResults.playlists?.length > 3 ? <button type="button" onClick={() => setViewMorePlaylists(!viewMorePlaylists)} className={classNames('dynamic-button', 'spacing--top-half')}>{viewMorePlaylists ? 'View Less' : 'View More'}</button> : null}
                    {searchResults.playlists?.length > 0 ? <div className={styles['predictive-search__divider']} /> : null}
                </>
            );
        }
        return null;
    }, [searchResults.playlist_total, searchResults.playlists, sectionCount, shouldDisplay, viewMorePlaylists]);

    const renderedCuratedSets = useMemo(() => {
        if (shouldDisplay('curated-sets')) {
            return (
                <div className="spacing--bottom">
                    <CuratedSet sets={searchResults.curated?.slice(0, sectionCount('curated-sets', viewMoreCuratedSets))} count={searchResults.curated_total} />
                    {searchResults.curated?.length > 3 ? <button type="button" onClick={() => setViewMoreCuratedSets(!viewMoreCuratedSets)} className={classNames('dynamic-button', 'spacing--top-half')}>{viewMoreCuratedSets ? 'View Less' : 'View More'}</button> : null}
                </div>
            );
        }
        return null;
    }, [searchResults.curated, searchResults.curated_total, sectionCount, shouldDisplay, viewMoreCuratedSets]);

    if (term === '' && !isMobile) {
        return emptySearchState();
    }

    // eslint-disable-next-line @typescript-eslint/dot-notation
    const searchText = ((router?.query['searchTerm'] as string) || '');
    return term !== '' && searchText !== term ? (
        <div className={classNames(styles['predictive-search__container'], {
            [styles['predictive-search__container--open']]: isVisible,
        })}>
            {checkboxes()}
            {shouldDisplayNoResults ? (
                <EmptyState title={"Sorry, we couldn't find anything that matches your search."} subtitle="Try checking your spelling, or searching for something else." noPadding icon="search-outline-icon" variant="white" hasBackground={false} />
            ) : (
                <>
                    {renderedArtists}
                    {renderedTracks}
                    {renderedPlaylists}
                    {renderedCuratedSets}
                    <SearchButtons handleCancel={handleCancel} />
                </>)}
        </div>
    ) : null;
}
