import { CuratedSet, InlineResponse20021 } from '@bpm-web-app/create-api-sdk';

import { useInfiniteSearchCuratedSet } from '@bpm-web-app/swr-hooks';
import { appendQueryParams, createAppRoutes, useCreateFilterParams } from '@bpm-web-app/utils';
import { Fragment, useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';

import { MusicViewProps } from './music-view-props';
import { CreateCardCarousel } from '../create-card-carousel/create-card-carousel';
import SearchCreateCuratedSets from '../search-create/search-create-curated-sets/search-create-curated-sets';
import { SecondaryPageTitle, NoResultsBlock, ListGrid, CardGrid } from '../shared';
import { CardCarouselGhostLoading } from '../shared/card-carousel/card-carousel-ghost-loading';
import CreateListGridItem from '../shared/list-grid-item/create-list-grid-item';
import SeeMore from '../shared/see-more-button/see-more-btn';
import { CuratedSetCardCreate } from '../shared/card/curated-set-card-create/curated-set-card-create';

const CURATED_SETS_LINES_WIDTH = [90];

export function CuratedSetsMusicView(curatedMusicView: MusicViewProps) {
    const { createQuery, variant, onTotalCount, seeMorePath, isLoading, setIsLoading } = curatedMusicView;
    const indexPage = variant === 'fullscreen';
    const isMobile = variant === 'mobile';

    const query = useCreateFilterParams(createQuery);
    const { data = [], isLoadingInitialData, isLoadingMore, setSize, isLastPage } = useInfiniteSearchCuratedSet(query);

    /* reset the infinite scroll back to first page
  if navigated between a non-index and index page. */
    useEffect(() => {
        setSize(1);
    }, [indexPage, setSize]);

    const loadMore = useCallback(() => {
        if (isLoadingMore) return;
        setSize((prevSize) => prevSize + 1);
    }, [isLoadingMore, setSize]);

    const songs = (data as InlineResponse20021[]).map(({ data: paginatedResponse }) => paginatedResponse);
    const flatMediaList: CuratedSet[] = useMemo(() => songs ? [].concat(...songs) : [], [songs]);

    const getIntroSection = useCallback(
        () => (
            <div>
                <SecondaryPageTitle title="Curated Packs" counter={data[0]?.pagination?.total?.toString()} />
                {flatMediaList?.length === 0 && <NoResultsBlock hasPadding hasMargin>No Curated Packs Available</NoResultsBlock>}
            </div>
        ),
        [data, flatMediaList?.length]
    );

    useEffect(() => {
        if (data[0]?.pagination?.total !== undefined && onTotalCount) {
            onTotalCount(data[0]?.pagination?.total || 0);
        }
    }, [onTotalCount, data, flatMediaList]);

    useEffect(() => {
        if (setIsLoading) {
            setIsLoading(isLoadingInitialData);
        }
    }, [isLoadingInitialData, setIsLoading]);

    if (isLoading || isLoadingInitialData || !data) {
        return (
            <div className={classNames('ghost-loading__loading-container')}>
                <div>
                    <CardCarouselGhostLoading elementsCount={10} cardSize="small" title="Curated Sets" linesWidthArray={CURATED_SETS_LINES_WIDTH} lineHeight={10} />
                </div>
            </div>
        );
    }

    return (
        /* eslint-disable-next-line @typescript-eslint/dot-notation */
        <>
            {(flatMediaList?.length === 0 || (indexPage && !isMobile && flatMediaList?.length)) && getIntroSection()}
            {flatMediaList?.length > 0 ? (
                <div>
                    {isMobile && (
                        <ListGrid>
                            {flatMediaList.map((curatedSet) => (
                                <Fragment key={curatedSet.id}>
                                    <CreateListGridItem
                                        id={curatedSet.id}
                                        title={curatedSet.name}
                                        subtitle=""
                                        imageUrl={appendQueryParams(curatedSet.artwork_url, { key: 'dw', value: 160 })}
                                        imageUrl2x={appendQueryParams(curatedSet.artwork_url, { key: 'dw', value: 320 })}
                                        link={createAppRoutes.curatedSet(curatedSet.slug)}
                                        contentType="curated-set"
                                    />
                                </Fragment>
                            ))}
                        </ListGrid>
                    )}
                    {indexPage && !isMobile && (
                        <CardGrid cardType="card-small">
                            {flatMediaList.map((item) => (
                                <CuratedSetCardCreate item={item as unknown as CuratedSet} items={flatMediaList as unknown as CuratedSet[]} />
                            ))}
                        </CardGrid>
                    )}

                    {!indexPage && flatMediaList.length > 0 && !isMobile && (
                        <CreateCardCarousel
                            contentType="curated-set"
                            items={flatMediaList}
                            cardSize="small"
                            carouselTitle="Curated Packs"
                            carouselMorePath={seeMorePath}
                        />
                    )}
                </div>
            ) : null}
            {indexPage && !isLastPage && !isLoadingMore ? (
                <SeeMore expand={false} variant="text" onClick={loadMore} />
            ) : null}
        </>
    );
}

export default SearchCreateCuratedSets;
