import { useInfiniteSearchPresets, useTags } from '@bpm-web-app/swr-hooks';
import { getMutatedPresets, PresetResults, useCreateFilterParams } from '@bpm-web-app/utils';
import { useCallback, useContext, useMemo } from 'react';
import { FiltersContext } from '../../filters/filters.context';
import TrackListCreatePresets from '../../shared/track-list/create-presets/track-list-create-presets';

export function PresetSeeMore() {
    const { tags, setTags } = useContext(FiltersContext);
    const query = useCreateFilterParams({ type: 'preset' });

    const { data: presetData, mutate, isLoadingMore, setSize, isLastPage, } = useInfiniteSearchPresets(query);
    const { tags: possibleTags } = useTags({ ...query, limit: undefined });

    const mutatePreset = useCallback(
        (id: string, progress: number) => {
            mutate(getMutatedPresets(presetData as PresetResults[], id, progress));
        },
        [mutate, presetData]
    );

    const handleLoadMore = useCallback(() => {
        if (!isLoadingMore && !isLastPage) {
            setSize((prevSize) => prevSize + 1);
        }
    }, [isLastPage, isLoadingMore, setSize]);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const presets = useMemo(() => presetData?.flatMap((data) => data!.data), [presetData]);

    const possibleTagsString = useMemo(() => possibleTags?.map(({ name }) => name) || [], [possibleTags]);
    return (
        // eslint-disable-next-line @typescript-eslint/dot-notation
        <div className="spacing__window--top">
            <div className="spacing__window--horizontal">
                <TrackListCreatePresets
                    mutateSound={mutatePreset}
                    list={presets}
                    automatedLoadMore
                    onLoadMore={handleLoadMore}
                    isLoadingMore={isLoadingMore}
                    title="Synth Presets"
                    showTagFilter
                    tags={possibleTagsString}
                    selectedTags={tags}
                    onToggleTag={(tag, on) => {
                        if (on) {
                            setTags([...tags, tag]);
                        } else {
                            setTags(tags.filter((t) => t !== tag));
                        }
                    }} />
            </div>
        </div>
    );
}

export default PresetSeeMore;
