import { useUserSettings } from '@bpm-web-app/utils';
import { useState } from 'react';
import { CustomIcon } from '../custom-icon/custom-icon';
import { SquaredButton } from '../squared-button/squared-button';

interface AddToFavoriteButtonProps {
    onClick?: (e: React.MouseEvent<HTMLElement>) => void;
    hasTooltip?: boolean;
    isFavorite?: boolean;
    fullWidth?: boolean;
    squared?: boolean;
    isCreate?: boolean;
}

export function AddToFavoriteButton({ onClick, hasTooltip, isFavorite, fullWidth, squared, isCreate }: AddToFavoriteButtonProps) {
    const { isAnonymous, setShowSignUpModal } = useUserSettings();
    const [isHovering, setIsHovering] = useState(false);

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (isAnonymous) {
            setShowSignUpModal({ type: isCreate ? 'pack' : 'track' });
            return;
        }
        if (onClick) onClick(e);
    };

    if (squared) {
        return <SquaredButton
            leftIcon={<CustomIcon type={isFavorite ? 'heart-filled-icon' : 'heart-outline-icon'} color="gray" isIconHoverActive={isHovering} />}
            type="outline"
            label={isFavorite ? 'Remove from Favorites' : isCreate ? 'Save' : 'Add to Favorites'}
            onPress={handleOnClick}
            fullWidth={fullWidth}
            onHover={() => setIsHovering(true)}
            onHoverEnded={() => setIsHovering(false)} />;
    }

    if (hasTooltip) {
        return (
            <CustomIcon type={isFavorite ? 'heart-filled-icon' : 'heart-outline-icon'} hasIconHover onClick={handleOnClick} tooltip={isFavorite ? 'Remove from Favorites' : isCreate ? 'Save' : 'Add to Favorites'} hasBackgroundHover container="round" containerSize="regular" />
        );
    }

    return (
        <CustomIcon type={isFavorite ? 'heart-filled-icon' : 'heart-outline-icon'} hasIconHover onClick={handleOnClick} />
    );
}
