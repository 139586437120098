import { useGetSharedDrives } from '@bpm-web-app/swr-hooks';
import { Fragment, useMemo } from 'react';
import classNames from 'classnames';
import styles from './my-drive.module.css';
import { PageHeader } from '../../shared/page-header/page-header';
import { DriveCardCreate } from '../../shared/card/drive-card-create/drive-card-create';

export function MySharedDrives() {
    const { data } = useGetSharedDrives(true);
    const sharedDrives = useMemo(() => data?.data, [data?.data]);

    return (
        <div className={classNames(styles['my-drive'], 'spacing__window',)}>
            <PageHeader
                title={sharedDrives ? `Shared With Me (${sharedDrives.length})` : 'Shared With Me'}
            />
            <div className={classNames(styles['my-drive__grid'], 'spacing--top')}>

                {sharedDrives?.map((item) => (
                    <Fragment key={item.id}>
                        <DriveCardCreate item={item} items={sharedDrives} hideMoreOptions />
                    </Fragment>
                ))}
            </div>
        </div>
    );
}
