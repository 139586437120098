import useSWR from 'swr';
import useSWRInfinite from 'swr/infinite';
import { Contest, CreateSearchContestQuery, GenericPaginatedQuery } from '@bpm-web-app/api-client';

interface SearchPaginatedQuery extends GenericPaginatedQuery {
    id: string;
    submissionId?: string;
}

export function useContests(disabled = false) {
    const { data, error } = useSWR(
        [
            'contests',
            {
                persist: true,
                disabled,
            },
        ],
        () => Contest.getContests(),
        { isPaused: () => disabled }
    );

    return {
        contests: data?.data,
        isLoading: !error && !data,
        error,
    };
}

export function useInfiniteContestSubmissions(query: CreateSearchContestQuery, disabled: boolean) {
    const { limit } = query;
    const pageSize = limit || 50;
    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
        (index) => [`contest-submissions-${query.id}-${disabled}-${index}`, { persist: true }],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            const skip = +pageIndex * pageSize;

            // NOTE(paulomartinsbynd): we should only pass submission id on the first page
            return Contest.getContestSubmissions(query.id, pageSize, skip, skip === 0 ? query.submission_id : undefined);
        },
        {
            revalidateFirstPage: true,
            isPaused: () => !!disabled,
        }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}

export function useInfiniteContestComments(query: SearchPaginatedQuery, disabled: boolean) {
    const { limit } = query;
    const pageSize = limit || 50;
    const { data, error, size, setSize, isValidating, mutate } = useSWRInfinite(
        (index) => [`contest-comments-${index}-${disabled}`, { persist: true }],
        (key) => {
            /* extract page index back from key name */
            const pageIndex = key.split('-').pop() || '0';
            const skip = +pageIndex * pageSize;
            return Contest.getContestComents(query.id, pageSize, skip);
        },
        {
            revalidateFirstPage: true,
            isPaused: () => !!disabled,
        }
    );

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
    const isEmpty = data?.[0]?.data.length === 0;
    const lastPageSize = data ? data[data.length - 1]?.data.length || 0 : 0;
    const isLastPage = isEmpty || lastPageSize < pageSize;

    return {
        data,
        isLoadingInitialData,
        isLoadingMore,
        isValidating,
        isLastPage,
        error,
        size,
        setSize,
        mutate,
    };
}

export function useContestReportStreamSubmission() {
    return (id: string) => Contest.contestReportStreamSubmission(id);
}
